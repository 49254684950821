import PropTypes from 'prop-types'
import { BaseListProps, viewPropTypes } from '../ListSearchableView/ListSearchableView.propTypes'

export const listOverviewPropTypes = {
  ...BaseListProps,
  views: PropTypes.arrayOf(PropTypes.shape(viewPropTypes)),
  searchPlaceholder: PropTypes.string,
}

export const listOverviewDefaultProps = {
  searchParam: 'search_term',
  views: [],
  searchPlaceholder: 'Search',
}
