import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { useGetNavigation } from '../../../api/use-navigation';
import { FeatureNotEnabled } from '../../../components';
const Overview = lazy(() => import('../pages/overview'));
const ClientHomePage = lazy(() => import('../pages/client-home-page'));
export const OVERVIEW_PATHS = {
    lender: '/lender-home',
    client: '/client-home',
};
export const OverviewRoutes = () => {
    const { data: navigation, isLoading, isSuccess } = useGetNavigation();
    if (isLoading)
        return [];
    const featureEnabled = isSuccess && !!navigation?.overview;
    return [
        _jsx(Route, { exact: true, path: OVERVIEW_PATHS.lender, children: featureEnabled ? _jsx(Overview, {}) : _jsx(FeatureNotEnabled, { feature: "Lender home page" }) }, OVERVIEW_PATHS.lender),
        _jsx(Route, { exact: true, path: OVERVIEW_PATHS.client, children: featureEnabled ? _jsx(ClientHomePage, {}) : _jsx(FeatureNotEnabled, { feature: "Client home page" }) }, OVERVIEW_PATHS.client),
    ];
};
