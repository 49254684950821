import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

// Components
import { S_ContentColumn } from '../../../_components/layout/ContentColumn'
import { SectionLoader } from '../../../_components/SectionLoader'
import { Button } from '../../../_components/Button'
import { SplitButton, S_SplitButton } from '../../../_components/SplitButton'
import { FormSummary } from '../../../_components/FormSummary/FormSummary'
import { S_Subtitle } from '../../../_components/Typography/Titles.styles'
import { Text } from '../../../_components/Typography/Components/Text/Text'
import colors from '../../../_constants/colors'
import { S_PageHeader, S_PageHeaderRow } from '../../../_components/layout/PageHeader/PageHeader.styles'

// Services
import { showDeal } from '../../../_services/deals.service'
import { changeBusinessToClient } from '../../../_services/lender.service'

// Constants
import { LENDER_ROUTES } from '../../../_constants/routes'

// Utils
import { history } from '../../../_helpers/history'
import { isProductEnabled } from '../../../_helpers/flags'

export const S_ProspectDetailsApplication = styled.div`
  .prospect-status {
    font-weight: 600;
    margin-left: 25px;
    margin-bottom: 16px;
    color: gray;
  }

  ${S_Subtitle} {
    padding-left: 12px;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    button {
      display: block;
    }

    .sub-actions {
      display: flex;
      ${S_SplitButton} {
        margin-right: 20px;
        font-size: 16px;
        .split-button {
          margin-top: 0;
        }
      }
    }
  }

  .edit-btn {
    max-height: 35px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 11px;
    padding-right: 11px;
  }

  .label-btn-container {
    display: inline;
    padding-left: 10px;
  }

  .edit-btn:first-child {
    margin-right: 16px;
  }

  ${S_ContentColumn} {
    padding: 0;
  }

  @media print {
    .no-print {
      display: none;
    }
  }
`

const checkIfClient = (response, businessId, onlyUnderwriteProduct) => {
  if (response.is_client && !onlyUnderwriteProduct) {
    history.push(`${LENDER_ROUTES.clients_overview}/${businessId}`)
  }
}

const ProspectDetailsApplication = ({
  featureFlags,
  match,
  user,
  onLoaded = () => {},
  defaultShowPII = false,
  businessId = undefined,
  printVersion = false,
}) => {
  const [prospect, setProspect] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [values, setValues] = useState({})
  const [showPII, setShowPII] = useState(defaultShowPII)
  const operateEnabled = isProductEnabled('Operate', featureFlags)

  // Check Business Id
  businessId = businessId || match.params.businessId
  if (businessId === undefined) {
    return null
  }

  const objectNullToString = (obj) => {
    var out = {}
    Object.entries(obj).forEach(([key, val]) => {
      if (val === null || val === undefined) out[key] = ''
      else if (val.constructor === Array) out[key] = Object.values(objectNullToString(val))
      else if (typeof val === 'object') out[key] = objectNullToString(val)
      else out[key] = val
    })

    return out
  }

  // Get client data
  useEffect(() => {
    // Skip if we've already loaded (unmount)
    if (!isLoading) return

    showDeal(businessId).then((response) => {
      setProspect(response)
      setValues({ business: { ...response } })
      setIsLoading(false)
    })
  }, [prospect])

  useEffect(() => {
    if (!isLoading) {
      onLoaded()
    }
  }, [isLoading])

  // Switch client from a prospect to a tenant
  const handleChangeBusinessToClient = () => {
    changeBusinessToClient(businessId).then((resp) => {
      checkIfClient(resp, businessId)
    })
  }

  const hasBusinessName = prospect !== null && prospect.business_name

  return (
    <S_ProspectDetailsApplication>
      {/* Loading until we have details */}
      {isLoading && <SectionLoader />}

      {/* Page Header */}
      {!isLoading && (
        <>
          {!printVersion && (
            <S_PageHeader className="no-print">
              <S_PageHeaderRow>
                <S_Subtitle className="no-print" marginBottom={'0'} size={'md'} weight={'700'}>
                  Prospect Application
                </S_Subtitle>
                <div className="actions no-print">
                  <div className="sub-actions">
                    {user.is_admin ? (
                      <SplitButton
                        dropdownButtons={[
                          {
                            label: 'Print With PII',
                            function: async (e) => {
                              setShowPII(true)
                              setTimeout(() => window.print(), 100)
                            },
                          },
                        ]}
                        mainButton={{
                          label: 'Print Application',
                          function: async (e) => {
                            setShowPII(false)
                            setTimeout(() => window.print(), 100)
                          },
                        }}
                      />
                    ) : (
                      <Button className={'edit-btn'} onClick={() => window.print()} text={'Print Application'} />
                    )}

                    <Button
                      className={'edit-btn'}
                      onClick={() => history.push(`${LENDER_ROUTES.edit_business}/prospect/${prospect.id}`)}
                      text={`Edit Application`}
                    />
                  </div>
                  {operateEnabled && (
                    <Button
                      className={'edit-btn'}
                      onClick={handleChangeBusinessToClient}
                      text={`Change ${hasBusinessName ? prospect.business_name : ''} to Client`}
                    />
                  )}
                </div>
              </S_PageHeaderRow>
            </S_PageHeader>
          )}

          <FormSummary
            printVersion={printVersion}
            setShowPii={setShowPII}
            showEdit={false}
            showPii={showPII}
            showTitle={false}
            values={values}
          />

          <Disclaimer />
        </>
      )}
    </S_ProspectDetailsApplication>
  )
}

const Disclaimer = () => {
  return (
    <Text fs={'12px'} textColor={colors.SUBTITLE_GREY}>
      Xen stores and manages sensitive data using Amazon's Key Management Service, a fully compliant service with best
      in class encryption and security.
    </Text>
  )
}

function mapStateToProps(state) {
  const { featureFlags, user } = state
  return {
    featureFlags,
    user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

const connectedProspectDetailsApplication = connect(mapStateToProps, mapDispatchToProps)(ProspectDetailsApplication)
export { connectedProspectDetailsApplication as ProspectDetailsApplication }
