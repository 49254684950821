import { getLenderRedirectLink } from '../_actions'
import { authenticationService } from '../_services'
import { useSelector } from 'react-redux'
import ROUTES from '../_constants/routes'
import { useHistory } from 'react-router-dom'
import { AuthConfig } from '../_configs'
import { persistor } from './store'
import { isFeatureEnabled } from './flags'
import { createInternalLinkUrl } from 'xen/helpers'
import { showDeal } from 'legacy/_services/deals.service'

export const redirectByAuthenticationState = (type) => {
  const history = useHistory()
  const { authentication, user, business, underwriteDeal, tenant, modal, env } = useSelector((state) => ({
    authentication: state.authentication,
    user: state.user,
    business: state.business,
    underwriteDeal: state.underwriteDeal,
    tenant: state.tenant,
    modal: state.modal,
    env: state.env,
  }))

  const operate_enabled = tenant?.operate_enabled
  const needToResetPassword = user && user.reset_password_required

  if (authentication.loggedIn && !needToResetPassword && !(type === `reset_password`)) {
    if (business?.is_client === undefined && user.underwrite_deal_id !== null) {
      authenticationService.validateSession(authentication.sessionToken).then(async () => {
        if (underwriteDeal.applicationURL) {
          persistor.flush()

          // by default, redirect to the underwrite app to complete application
          let redirect = underwriteDeal.applicationURL

          // if feature flag is enabled
          const isBorrower = user.user_type === 'client' || user.user_type === 'prospect'
          if (
            (isBorrower && isFeatureEnabled('xen_prospect_details')) ||
            isFeatureEnabled('xen_prospect_details_all')
          ) {
            const dealId = user.underwrite_deal_id
            // and application is submitted or active
            const deal = dealId ? await showDeal(dealId) : {}
            if (deal.state === 'submitted' || deal.state === 'active') {
              // redirect to new prospect page
              redirect = createInternalLinkUrl({ to: '/prospects/:id', urlParams: [dealId] })
            }
          }

          return window.location.assign(redirect)
        }
      })
    } else if (business.is_client && operate_enabled) {
      persistor.flush()
      history.push(ROUTES.client_home)
    } else if (user.id && !modal.is_open) {
      persistor.flush()
      const redirectPath = AuthConfig[env.tenant].sign_up.redirect || ROUTES.confirm_form || ROUTES.accounting_platforms
      history.push(redirectPath)
    }
    return null
  }

  if (authentication.loggedIn && user.user_type === 'lender') {
    persistor.flush()
    history.push(getLenderRedirectLink(user))
  }
}
