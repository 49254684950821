import styled from 'styled-components'

// Components
import { S_FileViewerInner } from '../../../FileViewer'
import { S_ControlsButton } from '../../../ControlsButton'
import { ButtonComponent } from '../../../Button'

export const S_DropGallery = styled.div`
  overflow-x: scroll;
  display: flex;
  flex-wrap: nowrap;
  max-height: 128px;

  .file-header,
  .left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .file-container {
    display: flex;
    justify-content: flex-start;
    overflow: hidden;

    ${S_FileViewerInner} {
      box-shadow: 0px 0px 6px #0ff;
      max-width: 300px;
    }
  }

  .file-actions {
    display: flex;
    align-items: center;
  }

  ${S_ControlsButton}, ${ButtonComponent} {
    margin: 5px;
    height: 20px; /* TODO: make a convention to match pagination prev/next button sizes */
  }

  ${S_ControlsButton} {
    width: 20px;
  }
  .overall-container {
    margin-bottom: 4px;
    margin-top: 4px;
    margin-right: 8px;
    border-style: solid;
    border-color: #777;
    border-radius: 5px;
    border-width: 1px;
    padding: 3px;
    overflow: hidden;
    background-color: #fff;
  }
  .overall-container:last-child {
    margin-right: 0;
  }
  h6 {
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
  }
  .compact {
    font-size: 10px;
  }
`
