import styled, { css } from 'styled-components'
import { cssBreakpoints } from '../../../_constants/styles'

//TODO: Replace all page-headers by styled components across the application
export const S_PageHeader = styled.header`
  margin-bottom: 15px;
`

export const S_PageHeaderRow = styled.header`
  display: flex;
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'space-between')};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'flex-end')};

  ${(props) =>
    props.className !== 'mobile-row' &&
    css`
      @media screen and (max-width: ${cssBreakpoints.mobileMd}) {
        display: block;
        & > * {
          //Add bottom offset for all first nested tags on mobile view
          margin-bottom: 10px;
        }
      }
    `}
`
