import styled from 'styled-components'
import colors from '../../../_constants/colors'

const S_LenderCreateScheduleModal = styled.div`
  .business-selection {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 30px;
    .title {
      font-size: 26px;
      margin-bottom: 25px;
      width: 100%;
    }
    .business-auto-complete {
      min-width: 200px;
      width: 100%;
      margin: 0px 0px 30px 0px;
    }
    .label {
      color: ${colors.TITLE_GREY};
      width: 100%;
      margin: 7px;
    }
    .err-banner {
      width: 70%;
      padding: 0;
      margin-bottom: 10px;
    }
    .create-schedule-btn {
      font:
        350 15px 'Lato',
        sans-serif;
    }
  }
`
export default S_LenderCreateScheduleModal
