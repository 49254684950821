import { useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { notifications } from 'xen/helpers';
export async function uploadFile(params, url) {
    try {
        const formData = new FormData();
        formData.append('filedata', params.file, params.file.name);
        formData.append('filename', params.file.name);
        const response = await request({
            method: 'POST',
            url: url,
            data: formData,
        });
        return {
            url: response.url,
            file: params.file,
        };
    }
    catch (_error) {
        throw Error('Error uploading favicon');
    }
}
export const useUploadFile = (url) => {
    return useMutation({
        mutationFn: (params) => uploadFile(params, url),
        onError: () => {
            notifications.error('Error uploading file');
        },
        onSuccess: (data) => {
            notifications.success(`${data.file.name} has been uploaded`);
        },
    });
};
