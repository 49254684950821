import React from 'react'
import styled from 'styled-components'

// Components
import { EmptyMessage } from './EmptyMessage'
import { SectionLoader } from './SectionLoader'
import { totalIndicatorStyles } from './SearchableOverview'

export const S_ItemsOverview = styled.div`
  .overview-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -10px 20px;
  }

  .overview-title,
  .total-indicator {
    margin: 5px 10px;
  }

  .total-indicator {
    ${totalIndicatorStyles}
  }
`

export const ItemsOverview = ({
  title = '',
  items = [],
  totalItems = null,
  filterTerm = '',
  emptyMessage = 'No results found.',
  emptyFilterMessage = (filterTerm) => `No match for '${filterTerm}'`,
  isLoading = false,
  filterMethod = (items, filterTerm) => items,
  content,
}) => {
  const parsedFilterTerm = filterTerm.toLocaleLowerCase().trim()
  const filteredItems = filterMethod(items, parsedFilterTerm)

  const overviewContent = isLoading ? (
    <SectionLoader />
  ) : items.length === 0 ? (
    <EmptyMessage message={emptyMessage} />
  ) : filteredItems.length === 0 ? (
    <EmptyMessage message={emptyFilterMessage(filterTerm)} />
  ) : (
    content(filteredItems)
  )

  return (
    <S_ItemsOverview>
      {title !== '' && (
        <header className="overview-header">
          <h2 className="overview-title">{title}</h2>
          {!isLoading && <div className="total-indicator">{totalItems !== null ? totalItems : items.length}</div>}
        </header>
      )}

      {overviewContent}
    </S_ItemsOverview>
  )
}
