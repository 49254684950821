import { UTCDateToSystemString } from './dates'

export const collateralLineDatesToSystemTime = (line) => {
  if (line.created_at) {
    line.created_at = UTCDateToSystemString(line.created_at)
  }
  if (line.updated_at) {
    line.updated_at = UTCDateToSystemString(line.updated_at)
  }
  return line
}

export const collateralLineOperationDatesToSystemTime = (operation) => {
  if (operation.created_at) {
    operation.created_at = UTCDateToSystemString(operation.created_at)
  }
  if (operation.updated_at) {
    operation.updated_at = UTCDateToSystemString(operation.updated_at)
  }
  return operation
}
