import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

// Actions
import { modalActions } from '../../../_actions'
import { ButtonRow } from '../../layout/ButtonRow'
import { H2 } from '../../Typography/Components/Headings/H2'
import colors from '../../../_constants/colors'
import { S_Text } from '../../Typography/Components/Text/Text.styles'
import { LoaderButton } from '../../LoaderButton'

const StyledWarning = styled.div`
  max-width: 650px;
  .body {
    margin-top: 8px;
    color: ${colors.TEXT_LIGHT};
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.76px;
    ${S_Text} {
      color: ${colors.TEXT_LIGHT};
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.76px;
    }
  }
  .warning-btns {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
  }
`
export const WarningModal = ({
  title = '',
  body = '',
  onDecline = () => {},
  onAccept = () => {},
  declineLabel = 'Cancel',
  acceptLabel = 'Accept',
  showAccept = true,
  acceptClassName = '',
  declineClassName = '',
  acceptDisabled = false,
  declineDisabled = false,
  preventCloseModalOnAccept = false,
  isLoading = false,
}) => {
  const dispatch = useDispatch()
  const closeModal = () => {
    dispatch(modalActions.closeModal())
  }
  return (
    <StyledWarning>
      {title && (
        <H2 align="center" data-testid="title" marginBottom="25">
          {title}
        </H2>
      )}
      <div className={`body`} data-testid={`body`}>
        {typeof body === 'function' ? body() : body}
      </div>

      <ButtonRow className="warning-btns">
        <LoaderButton
          className={declineClassName}
          data-testid={`decline-btn`}
          disabled={declineDisabled}
          isLoading={isLoading}
          onClick={() => {
            onDecline()
            closeModal()
          }}
          text={declineLabel}
          type={`button`}
        />
        {showAccept && (
          <LoaderButton
            className={acceptClassName}
            data-testid={`accept-btn`}
            disabled={acceptDisabled}
            isLoading={isLoading}
            onClick={() => {
              if (!preventCloseModalOnAccept) {
                closeModal()
              }
              onAccept()
            }}
            text={acceptLabel}
            type={`button`}
          />
        )}
      </ButtonRow>
    </StyledWarning>
  )
}
