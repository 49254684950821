import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMediaQuery } from 'xen-ui';
import { useAppShellNavBarContext, useConfigurationContext, useUserContext, useXenTheme } from 'xen/providers';
import { CLIENTS_PATHS, PROSPECTS_PATHS } from 'xen/routes';
import { NavItem } from '../../link';
export const ProspectNavBarBody = () => {
    const user = useUserContext();
    const configuration = useConfigurationContext();
    const factoringEnabled = configuration.tenant.factoring.enabled;
    const theme = useXenTheme();
    const isMobileXS = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
    const { toggleNavBarState } = useAppShellNavBarContext();
    const toggleNavForMobile = () => {
        if (isMobileXS) {
            toggleNavBarState();
        }
    };
    return (_jsxs(_Fragment, { children: [user.underwriteDealId && (_jsx(NavItem.Link, { active: location.pathname.startsWith(PROSPECTS_PATHS.root), icon: "ListCheck", label: "Applications", onClick: toggleNavForMobile, to: "/prospects/:id", urlParams: [user.underwriteDealId] })), user.userType === 'client' && factoringEnabled && (_jsx(NavItem.Link, { active: location.pathname.startsWith(CLIENTS_PATHS.root), icon: "UserDollar", label: "Factoring", onClick: toggleNavForMobile, to: "/client-home" }))] }));
};
