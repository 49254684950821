import React, { Component } from 'react'

/**
 * Page.js
 * Component rendering page of PDF
 * Borrowed from https://github.com/seges/react-pdfjs-example/blob/90d3de1f680a03544c8c65ca5c70c681171417ef/app/src/components/PDFViewer/components/Page.js#L8
 **/

class PDFPage extends Component {
  state = {
    status: 'N/A',
    page: null,
    width: 0,
    height: 0,
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.pdf !== nextProps.pdf || this.state.status !== nextState.status
  }

  componentDidUpdate(nextProps) {
    this._update(nextProps.pdf)
  }

  componentDidMount() {
    const { pdf } = this.props
    this._update(pdf)
  }

  setCanvasRef = (canvas) => {
    this.canvas = canvas
  }

  _update = (pdf) => {
    if (pdf) {
      this._loadPage(pdf)
    } else {
      this.setState({ status: 'loading' })
    }
  }

  _loadPage(pdf) {
    if (this.state.status === 'rendering' || this.state.page !== null) return

    pdf.getPage(this.props.index).then((page) => {
      this.setState({ status: 'rendering' })
      this._renderPage(page)
    })
  }

  _renderPage(page) {
    let { scale } = this.props
    let viewport = page.getViewport({ scale })
    let { width, height } = viewport
    let canvas = this.canvas
    let context = canvas.getContext('2d')
    canvas.width = width
    canvas.height = height

    page.render({
      canvasContext: context,
      viewport,
    })

    this.setState({ status: 'rendered', page, width, height })
  }

  render() {
    let { width, height, status, page } = this.state
    return (
      <div className={`pdf-page ${status}`}>
        <canvas
          draggable={this.props.draggable}
          onDragStart={(e) => {
            let pageName = ''
            if (this.props.document_name && this.props.index) {
              pageName = `${this.props.document_name} - Page ${this.props.index}`
            } else {
              pageName = 'PDF Page'
            }
            let base64Image = e.target.toDataURL()
            e.dataTransfer.effectAllowed = 'all'
            e.dataTransfer.setData('base64', base64Image)
            e.dataTransfer.setData('imageName', pageName + '.png')
          }}
          ref={this.setCanvasRef}
          style={{ maxWidth: '100%' }}
        />
      </div>
    )
  }
}

export { PDFPage }
