import { jsx as _jsx } from "react/jsx-runtime";
import LegacyInvoiceSchedules from 'legacy/_views/LenderViews/NewCollateralPage';
import { Page } from 'xen/components';
const InvoiceSchedules = () => {
    /*
     * Should eventually be replaced by new Xen 1.0 Invoice Schedules table page
     * we pass location to get around redux location store until we can remove the legacy page
     */
    return (_jsx(Page, { pt: "0", children: _jsx(LegacyInvoiceSchedules, { location: { pathname: '' } }) }));
};
export default InvoiceSchedules;
