import React, { useState } from 'react'

import { PieChart } from '../../../components/Chart/PieChart/PieChart'

// Components
import { ChartDataPanel } from '../../../components/Chart/ChartDataPanel/ChartDataPanel'

// Hooks
import { useConcentrationChart } from '../../../_entityHooks/concentrationChart.hook'
import { useAblConcentrationChart } from '../../../_entityHooks/chartHooks/AblConcentrationChartHook'
import { Box, Button, Group, Stack, Text } from 'xen-ui'
import colors from 'legacy/_constants/colors'

export const ConcentrationChart = ({ service = 'factoring' }) => {
  const [pieActiveIndex, setPieActiveIndex] = useState(-1)
  const [animationDisabled, setAnimationDisabled] = useState(false)

  const hook = service == 'factoring' ? useConcentrationChart : useAblConcentrationChart

  const { currentSegmentData, setCurrentSegment, currentSegment, segmentsOptions, loading } = hook()

  const dataPanelItems = [
    {
      key: 'name',
      label: 'Name',
      size: 'md',
    },
    {
      key: 'balance',
      label: 'Total Amount',
      type: 'currency',
    },
    {
      key: 'concentration',
      label: '% of Total',
      type: 'percent',
    },
  ]

  const getCurretDataPanelSource = () => {
    if (pieActiveIndex === -1 || pieActiveIndex == null) {
      return currentSegmentData[0]
    }
    return currentSegmentData[pieActiveIndex]
  }

  return (
    <Stack gap={0} h="100%" justify="space-between" p="md" style={{ border: `1px solid ${colors.BORDER_GREY}` }}>
      <Stack gap={0}>
        <Text size="lg">Concentration</Text>
        <Group align="center" grow h="100%" wrap="nowrap">
          <Stack align="flex-start" h="100%" justify="center">
            {segmentsOptions.map((segment) => {
              const isCurrent = segment.value === currentSegment
              return (
                <Button
                  fullWidth
                  key={segment.value}
                  onClick={() => {
                    if (!isCurrent) {
                      setAnimationDisabled(true)
                      setCurrentSegment(segment.value)
                    }
                  }}
                  variant={isCurrent ? 'filled' : 'outline'}
                >
                  {segment.name}
                </Button>
              )
            })}
          </Stack>

          <PieChart
            animationDisabled={animationDisabled}
            data={currentSegmentData
              .filter((client) => +client.balance > 0)
              .map((client) => ({ ...client, balance: Number(client.balance) }))}
            dataKey={'balance'}
            loading={loading}
            nameKey={'name'}
            onMouseOver={() => {
              if (animationDisabled) {
                setAnimationDisabled(false)
              }
            }}
            outerRadius="95%"
            pieActiveIndexCb={(index) => setPieActiveIndex(index)}
            showToolTip={false}
            skeletonAlign={'right'}
            toolTipFormatter={() => ''}
          />
        </Group>
      </Stack>

      <ChartDataPanel dataPanelItems={dataPanelItems} dataSource={getCurretDataPanelSource()} />
    </Stack>
  )
}
