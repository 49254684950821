import React from 'react'
import styled from 'styled-components'
import { FaLock, FaExclamation, FaExclamationCircle, FaExclamationTriangle } from 'react-icons/fa'
import { math } from 'polished'

const bannerStyles = {
  paddingH: '20px',
}

export const S_StatusBanner = styled.div`
  background: #f5f5f5;
  border-radius: 3px;
  padding: 8px ${bannerStyles.paddingH};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  font-size: 16px;
  line-height: 22px;

  &.success {
    background: #f3fffa;
    border-color: #008045;
  }

  &.warning {
    background: #fffef6;
    border-color: #f6e200;
  }

  &.error {
    background: rgb(253, 237, 237);
    border-color: #fed6d7;
    font-size: 14px;
    line-height: 1.5;
    justify-content: flex-start;
    color: rgb(95, 33, 32);
    svg {
      color: #ef5350;
    }
  }

  svg {
    margin-right: ${math(`${bannerStyles.paddingH} / 1.5`)};
    height: 17px;
    width: 17px;
  }

  .message-inner {
    text-align: center;
    max-width: 485px;
    margin: auto;
  }

  .content {
    margin-left: auto;
    button {
      margin-left: 5px;
    }
  }
`

const getIcon = ({ iconType }) => {
  switch (iconType) {
    case '!':
      return <FaExclamation />
    case 'error':
      return <FaExclamationCircle />
    case 'warningtriangle':
      return <FaExclamationTriangle />
    case 'lock':
      return <FaLock />
    default:
      return null
  }
}

export const StatusBanner = ({ message = '', type = 'default', content = null, icon = null, ...rest }) => {
  const Icon = getIcon

  return (
    <S_StatusBanner {...rest}>
      {icon !== null && <Icon iconType={icon} />}
      <div className="status-message">
        <div className="message-inner">{message}</div>
      </div>
      {!content ? null : <div className={'content'}>{content()}</div>}
    </S_StatusBanner>
  )
}
