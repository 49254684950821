// NOTE: due to is a web worker.
// Prefer to not use the destructuring

export const zipDownloaderWorker = () => {
  let baseUrl = ''
  const xhrOptions = {
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'X-Requested-With': 'XMLHttpRequest',
    },
  }

  function ajaxRequest(endpoint, xhrOptions) {
    const response = new Promise((resolve, reject) => {
      const http = new XMLHttpRequest()
      http.onreadystatechange = function () {
        if (XMLHttpRequest.DONE && http.status >= 200 && http.status < 400 && http.responseText) {
          resolve({ response: http.response, responseURL: http.responseURL })
        } else if (XMLHttpRequest.DONE && http.status >= 400) {
          reject(http.response)
        }
      }
      http.open(xhrOptions.method || 'GET', endpoint, true)
      setHeaders(http, xhrOptions.headers)
      http.send(null)
    })

    return response
  }

  function setHeaders(xhr, headers) {
    for (const header in headers) {
      xhr.setRequestHeader(header, headers[header])
    }
  }

  function isZipfileReady(url) {
    xhrOptions.method = 'GET'

    const asyncProcessId = url.split('async_process/')[1]
    const endpoint = `${baseUrl}/async_process/${asyncProcessId}`

    ajaxRequest(endpoint, xhrOptions)
      .then(({ response, responseURL }) => {
        const json = JSON.parse(response)
        if (json.status === 'processing') {
          const request_interval_miliseconds = json.request_interval_miliseconds
          setTimeout(() => isZipfileReady(url), request_interval_miliseconds)
        } else {
          postMessage({ event: 'newData', data: responseURL })
        }
      })
      .catch((error) => {
        console.error(error)
        postMessage({ event: 'error', data: error })
      })
  }

  function scheduleZipAllDocuments(event) {
    const data = event.data.data

    baseUrl = data.baseUrl
    const scheduleId = data.parentId
    const headers = {
      'Session-Token': data.sessionToken,
      'Tenant-Token': data.tenant,
    }

    if (data.accessToken) {
      headers['Authorization'] = 'Bearer ' + data.accessToken
    }

    xhrOptions.headers = Object.assign(xhrOptions.headers, headers)
    xhrOptions.method = 'POST'

    const endpoint = `${baseUrl}/invoice_schedules/${scheduleId}/download_documents`

    ajaxRequest(endpoint, xhrOptions)
      .then(({ response }) => {
        const url = JSON.parse(response).url
        isZipfileReady(url)
      })
      .catch((err) => {
        console.error('WORKER ERROR', err)
      })
  }

  const messageHandlers = {
    start: scheduleZipAllDocuments,
  }
  self.addEventListener('message', (e) => {
    if (typeof messageHandlers[e.data.event] === 'function') {
      messageHandlers[e.data.event](e)
    }
  })
}
