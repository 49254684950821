import styled from 'styled-components'
import { S_FormRow } from '../../../_components/Forms/FormRow'
import { S_EditBusinessForm } from './EditBusiness.style'

export const S_EditBusinessFormV2 = styled(S_EditBusinessForm)`
  max-width: 70%;
  min-width: 400px;

  .form-actions {
    margin-top: 10px;
  }

  ${S_FormRow} {
    .form-row-label,
    .field-and-error-wrapper {
      width: 30%;
      min-width: 200px;
    }
  }
`
