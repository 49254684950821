import React from 'react'

// Components
import Table from '../../../_components/Table'
import { createColumn } from '../../../_components/TableColumn'
import { Button } from '../../../_components/Button'

// Helpers
import { isFeatureEnabled } from '../../../_helpers/flags'
import { useFlags } from '../../../_reduxHooks/flags.hook'

// Constants
import { LENDER_ROUTES } from '../../../_constants/routes'

export const tableColumns = [
  {
    accessor: 'business_name',
    headingText: 'Name',
  },
  createColumn({
    type: 'currency',
    id: 'overall_credit_limit',
    headingText: 'Limit',
  }),
  createColumn({
    type: 'currency',
    id: 'total_ar',
    headingText: 'Outstanding AR',
  }),
  createColumn({
    type: 'currency',
    id: 'balance',
    headingText: 'NFE',
  }),
  createColumn({
    type: 'currency',
    id: 'ineligible_ar',
    headingText: 'Ineligible AR',
  }),
  createColumn({
    type: 'currency',
    id: 'reserve',
    headingText: 'Client Reserves',
  }),
]

export const clientsOverviewColumns = () => {
  return [
    {
      accessor: 'business_name',
      headingText: 'Name',
    },
    createColumn({
      type: 'currency',
      id: 'overall_credit_limit',
      headingText: 'Limit',
    }),
    createColumn({
      type: 'currency',
      id: 'net_funds_employed_balance',
      headingText: 'Outstanding AR',
    }),
    createColumn({
      type: 'currency',
      id: 'client_balance',
      headingText: 'NFE',
    }),
    createColumn({
      type: 'currency',
      id: 'ineligible_ar_balance',
      headingText: 'Ineligible AR',
    }),
    createColumn({
      type: 'currency',
      id: 'cr_balance',
      headingText: 'Client Reserves',
    }),
  ]
}

export const getClientTableColumns = (useNonStandardNFE) => {
  return [
    {
      accessor: 'business_name',
      headingText: 'Name',
    },
    createColumn({
      type: 'currency',
      id: 'overall_credit_limit',
      headingText: 'Limit',
    }),
    createColumn({
      type: 'currency',
      id: 'total_ar',
      headingText: 'Outstanding AR',
    }),
    createColumn({
      type: 'currency',
      id: useNonStandardNFE ? 'funds_employed' : 'balance',
      headingText: useNonStandardNFE ? 'Funds Employed' : 'NFE',
    }),
    createColumn({
      type: 'currency',
      id: 'ineligible_ar',
      headingText: 'Ineligible AR',
    }),
    createColumn({
      type: 'currency',
      id: 'reserve',
      headingText: 'Client Reserves',
    }),
  ]
}

const isClientSelected = (client, selectedClients) => {
  return selectedClients.some((selectedClient) => {
    return selectedClient.id === client.id
  })
}

export const ClientsTable = ({
  clients,
  selectedClients = [],
  sorted = [],
  handleSort,
  handleRowClick = undefined,
  pageSize,
  columns = [],
  defaultSort = [],
}) => {
  if (!clients || clients.length === 0) {
    return null
  }
  const { featureFlags } = useFlags()
  // Currently only used by Marco Finance
  const useNonStandardNFE = isFeatureEnabled('use_non_standard_nfe', featureFlags)

  const trProps = (state, rowInfo) => {
    if (!rowInfo || !rowInfo.original || typeof handleRowClick !== 'function') {
      return {}
    }
    const client = rowInfo.original
    const isSelected = isClientSelected(client, selectedClients)
    return {
      handleRowClick: (props) => {
        handleRowClick(props)
      },
      className: `hovercolor ${isSelected ? 'selected' : ''}`,
      style: { cursor: 'pointer' },
    }
  }

  const tableColumnsWithLink = [
    ...(columns.length ? columns : getClientTableColumns(useNonStandardNFE)),
    {
      accessor: `id`,
      headingText: '',
      maxWidth: 120,
      disableSortBy: true,
      Cell: (props) => (
        <div className={`align-right`}>
          <Button
            buttonType="link"
            className="compact"
            text="View"
            to={`${LENDER_ROUTES.clients_overview}/${props.value}`}
          />
        </div>
      ),
    },
  ]

  const columnsToUse = handleRowClick === undefined ? tableColumnsWithLink : columns

  return (
    <Table
      className="with-border"
      columns={columnsToUse}
      data={clients}
      defaultPageSize={clients.length}
      defaultSort={defaultSort}
      getTrProps={trProps}
      onSortedChange={handleSort}
      pageSize={pageSize}
      sorted={sorted}
    />
  )
}
