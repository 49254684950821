import React from 'react'
import styled from 'styled-components'
import { totalIndicatorStyles } from '../SearchableOverview'

export const S_Indicator = styled.div`
  ${totalIndicatorStyles}
  .label,
    .value {
    display: inline-block;
  }
  .label {
    margin-right: 10px;
    color: #777;
  }
`

export const Indicator = ({ content = null, label = null, value = null }) => (
  <S_Indicator>
    {label !== null && value !== null ? (
      <>
        <div className="label">{label}</div>
        <div className="value">{value}</div>
      </>
    ) : (
      content
    )}
  </S_Indicator>
)
