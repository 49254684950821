import { useSelector, shallowEqual } from 'react-redux'

export const modalSelectors = {
  useModal,
}

function useModal() {
  return useSelector((state) => {
    return state.modal
  }, shallowEqual)
}
