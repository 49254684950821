import { useEffect, useState } from 'react'

import { downloadDocument } from '../../../../_services/documents.service'

// ActionHooks
import { notificationActionHooks } from '../../../../_actionHooks'
// Helpers
import { WebWorker } from '../../../../_helpers/webworker'

// Web-Worker
import { zipDownloaderWorker } from '../../zipDowloaderWorker'

export function useWorker(setProcessingWorker) {
  const showNotification = notificationActionHooks.useShowNotification()
  let [worker, setWorker] = useState(null)

  useEffect(() => {
    // NOTE: testing purposes
    if (Worker && WebWorker) {
      let w = new WebWorker(zipDownloaderWorker)

      w.addEventListener('message', (event) => {
        const {
          data: { data: url, event: message },
        } = event

        if (message === 'newData') {
          const documentId = url.substring(url.lastIndexOf('/') + 1)
          downloadDocument(documentId, 'all_files').then(() => {
            showNotification({
              type: `success`,
              message: `Files are ready for download.`,
            })
            setProcessingWorker(false)
          })
        } else {
          showNotification({
            type: `error`,
            message: `Something happended. Please report to Xen.`,
          })
        }
      })

      setWorker(w)
    }

    return () => {
      if (worker) {
        worker.terminate()
      }
    }
  }, [])

  return worker
}
