import React, { useState } from 'react'
import config from 'config'

// Components
import { S_FileActions, S_ListItem, S_ListItemText, S_Button } from './styles'
import { Button } from '../../../Button'
import { ControlsButton } from '../../../ControlsButton'
import { FaRegFile } from 'react-icons/fa'
// Services
import { deleteDocument, downloadDocument } from '../../../../_services/documents.service'
// Constants
import { documentsConstants } from '../../../../_constants'
// Selectors
import { sessionSelectors } from '../../../../_selectors/session.selectors'
import { useWorker } from './hooks'

const ListItem = ({ allowDelete, onDelete, allowDownload, file, requestsManaged = false }) => {
  const [isConfirmDelete, setIsConfirmDelete] = useState(false)
  const [fileLink, setFileLink] = useState(file.downloadLink?.url || false)
  const [isLoading, setIsLoading] = useState(false)

  const downloadFileById = async () => downloadDocument(file.id, file.document_name)

  return (
    <S_ListItem data-testid="listview-item" key={`s_listItem-file-${file.id}`}>
      <S_ListItemText>
        <FaRegFile />
        {file.document_name}
      </S_ListItemText>
      {/* File Actions */}
      <S_FileActions>
        {!isConfirmDelete && allowDownload && (
          <ControlsButton
            className="download-button"
            handleClick={async (ev) => {
              setIsLoading(true)
              if (!fileLink) {
                ev.preventDefault()
                await downloadFileById()
              }
              setIsLoading(false)
              setIsConfirmDelete(false)
            }}
            // href={file.downloadLink?.url ?? (downloadLink ? downloadLink.url : null)}
            href={fileLink ? fileLink : ''}
            icon="download"
            isLoading={isLoading}
            rel="noopener noreferrer"
            target="_blank"
          />
        )}
        {!isConfirmDelete && allowDelete && (
          <ControlsButton
            className="delete-button"
            handleClick={() => {
              setIsConfirmDelete(!isConfirmDelete)
            }}
            icon="delete"
          />
        )}
        {/* Show Confirm Button if delete */}
        {isConfirmDelete && (
          <>
            <Button className="compact secondary" onClick={() => setIsConfirmDelete(false)} text="Cancel" />
            <Button
              className="compact destroy"
              onClick={() => {
                if (file.id && !requestsManaged) {
                  deleteDocument(file.id).then(() => {
                    setIsConfirmDelete(false)
                    onDelete(file.id)
                  })
                } else {
                  onDelete(file.id, file)
                  setIsConfirmDelete(false)
                }
              }}
              text="Confirm"
            />
          </>
        )}
      </S_FileActions>
    </S_ListItem>
  )
}

export const ListView = ({
  parentId,
  files,
  allowDelete,
  allowDownload,
  hideDownloadAll = false,
  requestsManaged = false,
  onDelete = () => {},
  customDownloadAll = null,
}) => {
  const { sessionToken, accessToken } = sessionSelectors.useAuthentication()
  const { tenant } = sessionSelectors.useEnv()
  let [proccesingWorker, setProcessingWorker] = useState(false)
  let worker = useWorker(setProcessingWorker)

  const nonZipFiles = files.filter((f) => f.document_type !== documentsConstants.ZIP_TYPE_FILE)

  function startZipDownload() {
    if (!customDownloadAll) {
      setProcessingWorker(true)
      worker.postMessage({
        event: 'start',
        data: { sessionToken, accessToken, tenant, parentId, baseUrl: config.apiUrl },
      })
    } else {
      setProcessingWorker(true)
      customDownloadAll().then(() => {
        setProcessingWorker(false)
      })
    }
  }

  return (
    <>
      {nonZipFiles.map((f) => {
        return (
          <ListItem
            allowDelete={allowDelete}
            allowDownload={allowDownload}
            file={f}
            key={`listItem-file-${f.id}`}
            onDelete={onDelete}
            requestsManaged={requestsManaged}
          />
        )
      })}
      {allowDownload && !hideDownloadAll && nonZipFiles.length > 0 && (
        <S_Button
          className="compact"
          disabled={proccesingWorker}
          key="download-all-files-btn"
          onClick={startZipDownload}
        >
          {proccesingWorker ? 'Creating File' : 'Download All Files'}
        </S_Button>
      )}
    </>
  )
}
