import { useQuery } from '@tanstack/react-query';
import { request } from 'xen/api';
import { transformPaymentAssignmentsResponse } from 'xen/_modules/invoice-settlements/api/use-invoice-settlement/transform';
async function getPaymentAssignments(params = {}) {
    try {
        const response = await request({
            url: `/operate/payment_assignments`,
            method: 'GET',
            params,
        });
        return transformPaymentAssignmentsResponse(response);
    }
    catch (_error) {
        throw Error('Error fetching payment assignments');
    }
}
export const useGetPaymentAssignments = (params = {}) => {
    return useQuery({
        queryKey: ['payment-assignments', { params }],
        queryFn: () => getPaymentAssignments(params),
    });
};
