import { factoringBusinessFilter } from '../../../_components/modals/filter/filterFields'

export const INVOICE_SCHEDULES_FILTER_COLUMNS = [
  factoringBusinessFilter,
  {
    fieldName: 'state',
    displayName: 'Status',
    type: 'select',
    options: [
      {
        value: 'pending',
        display: 'Pending',
      },
      {
        value: 'funded',
        display: 'Funded',
      },
      {
        value: 'purchased',
        display: 'Purchased',
      },
      {
        value: 'closed',
        display: 'Closed',
      },

      {
        value: 'rejected',
        display: 'Rejected',
      },

      {
        value: 'incomplete',
        display: 'Incomplete',
      },
      {
        value: 'approved',
        display: 'Approved',
      },
    ],
  },
  {
    fieldName: 'created_start_date',
    displayName: 'Created Start Date',
    type: 'date',
  },
  {
    fieldName: 'created_end_date',
    displayName: 'Created End Date',
    type: 'date',
  },
  {
    fieldName: 'updated_start_date',
    displayName: 'Updated Start Date',
    type: 'date',
  },
  {
    fieldName: 'updated_end_date',
    displayName: 'Updated End Date',
    type: 'date',
  },
]
