import styled from 'styled-components'
import { BarChart } from 'recharts'

export const S_BarChart = styled(BarChart)`
  .recharts-bar-rectangles {
    .recharts-bar-rectangle {
      transition: opacity 0.2s ease-in-out;
    }
    &:hover {
      .recharts-bar-rectangle {
        opacity: 0.5;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
`
