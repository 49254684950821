// Constants
import ROUTES, { ABL_LENDER_ROUTES, LENDER_ROUTES } from '../../_constants/routes'

// Utils
import { isProductEnabled, isFeatureEnabled } from '../../_helpers/flags'
import { isAdminUser, isOperationsUser } from '../../_helpers/user-utils'

// Temporary solution. Need to refactor routes for new nav structure
const navigation = (props) => {
  const isABLEnabled = props?.isABLEnabled || false
  return {
    overview: {
      label: 'Overview',
      route: ROUTES.lender_home,
      index: 0,
    },
    prospects: {
      label: 'Prospects',
      route: LENDER_ROUTES.prospects_overview,
      index: 1,
    },
    clients: {
      label: 'Clients',
      route: LENDER_ROUTES.clients_overview,
      index: 2,
      subMenu: [
        //Here
        {
          label: 'Overview',
          route: LENDER_ROUTES.clients_overview,
          exact: true,
        },
        isABLEnabled
          ? {
              label: 'Borrowing Bases',
              route: LENDER_ROUTES.borrowing_base_overview,
              singleRoute: `${LENDER_ROUTES.borrowing_base_submission}/:view`,
              subMenu: [
                {
                  label: 'Incomplete',
                  route: LENDER_ROUTES.borrowing_base_overview + '/incomplete',
                  exact: true,
                },
                {
                  label: 'Pending',
                  route: LENDER_ROUTES.borrowing_base_overview + '/pending',
                  exact: true,
                },
                {
                  label: 'Approved',
                  route: LENDER_ROUTES.borrowing_base_overview + '/approved',
                  exact: true,
                },
                {
                  label: 'Posted',
                  route: LENDER_ROUTES.borrowing_base_overview + '/posted',
                  exact: true,
                },
                {
                  label: 'Rejected',
                  route: LENDER_ROUTES.borrowing_base_overview + '/rejected',
                  exact: true,
                },
              ],
            }
          : {},
        {
          label: 'Funding Requests',
          route: LENDER_ROUTES.funding_requests,
          singleRoute: [`${LENDER_ROUTES.funding_requests}/:view`, `${ABL_LENDER_ROUTES.funding_requests}/:view`],
          subMenu: [
            {
              label: 'All',
              route: LENDER_ROUTES.funding_requests_all,
              exact: true,
            },
            {
              label: 'Pending',
              route: LENDER_ROUTES.funding_requests_pending,
              exact: true,
            },
            {
              label: 'Approved',
              route: LENDER_ROUTES.funding_requests_approved,
              exact: true,
            },
            {
              label: 'Funded',
              route: LENDER_ROUTES.funding_requests_funded,
              exact: true,
            },
            {
              label: 'Rejected',
              route: LENDER_ROUTES.funding_requests_rejected,
              exact: true,
            },
          ],
        },
        {
          label: 'Invoices',
          route: LENDER_ROUTES.invoices_overview,
          singleRoute: `/invoices/:view`,
          subMenu: [
            {
              label: 'Open',
              route: LENDER_ROUTES.invoices_purchased,
              exact: true,
            },
            {
              label: 'Closed',
              route: LENDER_ROUTES.invoices_closed,
              exact: true,
            },
            {
              label: 'Ineligible',
              route: LENDER_ROUTES.invoices_ineligible,
              exact: true,
            },
            {
              label: 'Pending',
              route: LENDER_ROUTES.invoices_pending,
              exact: true,
            },
            {
              label: 'Not\xa0Funded',
              route: LENDER_ROUTES.invoices_not_funded,
              exact: true,
            },
            {
              label: 'All',
              route: LENDER_ROUTES.invoices_all,
              exact: true,
            },
          ],
        },
        isABLEnabled
          ? {}
          : {
              label: 'Invoice Schedules',
              route: LENDER_ROUTES.schedules,
              singleRoute: `${LENDER_ROUTES.schedules}/:view`,
              subMenu: [
                {
                  label: 'Incomplete',
                  route: LENDER_ROUTES.schedules_incomplete,
                  exact: true,
                },
                {
                  label: 'Pending',
                  route: LENDER_ROUTES.schedules_pending,
                  exact: true,
                },
                {
                  label: 'Approved',
                  route: LENDER_ROUTES.schedules_approved,
                  exact: true,
                },
                {
                  label: 'Completed',
                  route: LENDER_ROUTES.schedules_all,
                },
              ],
            },
        isABLEnabled
          ? {
              label: 'Cash Receipts',
              route: LENDER_ROUTES.cash_receipts_overview,
            }
          : {},
      ],
    },
    debtors: {
      label: 'Debtors',
      route: LENDER_ROUTES.debtors_overview,
      index: 4,
      subMenu: [
        {
          label: 'Overview',
          route: LENDER_ROUTES.debtors_overview,
          exact: true,
        },
        isABLEnabled
          ? { label: null, route: null }
          : {
              label: null,
              route: null,
            },
        isABLEnabled
          ? { label: null, route: null }
          : {
              label: 'Debtor Payments',
              route: LENDER_ROUTES.debtor_payments_overview,
              singleRoute: `${LENDER_ROUTES.debtor_payments_batch}/:view`,
              show: false,
              subMenu: [
                {
                  label: 'All',
                  route: LENDER_ROUTES.debtor_payments_batches_all,
                  exact: true,
                },
                {
                  label: 'Created',
                  route: LENDER_ROUTES.debtor_payments_batches_created,
                  exact: true,
                },
                {
                  label: 'Pending',
                  route: LENDER_ROUTES.debtor_payments_batches_pending,
                  exact: true,
                },
                {
                  label: 'Incomplete',
                  route: LENDER_ROUTES.debtor_payments_batches_incomplete,
                  exact: true,
                },
                {
                  label: 'Complete',
                  route: LENDER_ROUTES.debtor_payments_batches_complete,
                },
                {
                  label: 'Payment List',
                  route: LENDER_ROUTES.debtor_payments_batches_list,
                },
              ],
            },
      ],
    },
    signing_documents: {
      label: 'Signing Documents',
      route: LENDER_ROUTES.signing_documents,
      index: 5,
    },
    brokers: {
      label: 'Brokers',
      route: LENDER_ROUTES.brokers,
      index: 3,
    },
  }
}

const navObj = navigation()

const underwriteLenderNav = [navObj['prospects'], (({ label, route }) => ({ label, route }))(navObj['clients'])]
const operationsLenderNav = [navObj['clients'], (({ label, route }) => ({ label, route }))(navObj['debtors'])]

const factoringLenderNav = [navObj['prospects'], navObj['clients'], navObj['debtors']]

const ablLenderNav = [
  navObj['overview'],
  navObj['prospects'],
  navigation({ isABLEnabled: true })['clients'],
  navigation({ isABLEnabled: true })['debtors'],
]

// Logic copied from SideNav Component
export const getNavLinks = (featureFlags, user, authResources) => {
  const operateEnabled = isProductEnabled('Operate', featureFlags)
  let navLinks = underwriteLenderNav

  if (operateEnabled) {
    // navLinks are now actually sections
    navLinks = isProductEnabled('Asset Based Lending', featureFlags) ? ablLenderNav : factoringLenderNav
    const isAdmin = isAdminUser(user)
    const isOperations = isOperationsUser(user)
    if (!isAdmin && !isOperations) {
      navLinks = underwriteLenderNav
    }

    //remove payment batch links of transactions v2 isn't enabled yet.
    if (isFeatureEnabled('transactions_v2', featureFlags) && !isProductEnabled('Asset Based Lending', featureFlags)) {
      // Section: Operate -> Label: Debtor Payments
      const debtorsIndex = navLinks.findIndex((item) => item.label === 'Debtors')
      if (factoringLenderNav[debtorsIndex]?.subMenu) {
        factoringLenderNav[debtorsIndex].subMenu[2].show = true
      }
    }

    if (authResources['lender_home'] === true) {
      if (!navLinks.find((item) => item.label === 'Overview')) {
        navLinks.push(navObj['overview'])
      }
    }
  }
  if (isProductEnabled('E-Signing', featureFlags) && authResources['e-signing'] === true) {
    if (!navLinks.find((item) => item.label === 'Signing Documents')) {
      navLinks.push(navObj['signing_documents'])
    }
  }

  if (isFeatureEnabled('broker-commission', featureFlags) && authResources['brokers'] === true) {
    if (!navLinks.find((item) => item.label === 'Brokers')) {
      navLinks.push(navObj['brokers'])
    }
  }

  const debtorsMenuItems = navLinks.find((item) => item.label === 'Debtors')?.subMenu?.map((item) => item.label) ?? []
  if (debtorsMenuItems.includes('Debtor Payments') && !debtorsMenuItems.includes('Unapplied Cash')) {
    navLinks
      .find((item) => item.label === 'Debtors')
      .subMenu.push({ label: 'Unapplied Cash', route: '/unapplied-cash' })
  }

  return navLinks.sort((a, b) => (a.index > b.index ? 1 : b.index > a.index ? -1 : 0))
}
