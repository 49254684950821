import { userConstants } from '../_constants'
import { userService } from '../_services'
import { tenantActions } from './'

export const userActions = {
  getUser,
  setUser,
  updateUser,
}

function getUser(sessionToken) {
  return async (dispatch) => {
    dispatch(request())
    return await userService
      .getUser(sessionToken)
      .then((data) => {
        dispatch(success(data.user))
        dispatch(tenantActions.setTenant(data.tenant))
      })
      .catch((error) => dispatch(failure(error)))
  }

  function request() {
    return { type: userConstants.GET_USER_REQUEST }
  }
  function success(user) {
    return { type: userConstants.GET_USER_SUCCESS, user }
  }
  function failure(error) {
    return { type: userConstants.GET_USER_FAILURE, error }
  }
}

function setUser(user) {
  return async (dispatch) => {
    dispatch(success(user))
  }

  function success(user) {
    return { type: userConstants.GET_USER_SUCCESS, user }
  }
}

function updateUser(attr, val, userId) {
  return (dispatch) => {
    dispatch(request(attr, val))
    return userService
      .updateUser(attr, val, userId)
      .then((user) => dispatch(success(user)))
      .catch((error) => dispatch(failure(error)))
  }

  function request() {
    return { type: userConstants.UPDATE_USER_REQUEST, attr, val }
  }
  function success(user) {
    return { type: userConstants.UPDATE_USER_SUCCESS, user }
  }
  function failure(error) {
    return { type: userConstants.UPDATE_USER_FAILURE, error }
  }
}
