import { useSelector, shallowEqual } from 'react-redux'

export const sessionSelectors = {
  useAuthentication,
  useEnv,
}

function useAuthentication() {
  return useSelector((state) => {
    return state.authentication
  }, shallowEqual)
}

function useEnv() {
  return useSelector((state) => {
    return state.env
  }, shallowEqual)
}
