import { useSelector, shallowEqual } from 'react-redux'

export const userSelectors = {
  useUser,
}

function useUser() {
  return useSelector((state) => {
    return state.user
  }, shallowEqual)
}
