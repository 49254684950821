import { jsx as _jsx } from "react/jsx-runtime";
import LegacyClientsFactoring from 'legacy/_views/LenderViews/ClientsOverview';
import LegacyClientsUnderwrite from 'legacy/_views/LenderViews/UnderwriteClientsOverview/NewUnderwriteClientsOverview';
import { Page } from 'xen/components';
import { useConfigurationContext } from 'xen/providers';
const Clients = () => {
    const configuration = useConfigurationContext();
    return (_jsx(Page, { pt: "0", children: configuration.tenant.factoring.enabled ? _jsx(LegacyClientsFactoring, {}) : _jsx(LegacyClientsUnderwrite, {}) }));
};
export default Clients;
