import React, { useState } from 'react'

// PropTypes
import { listOverviewDefaultProps, listOverviewPropTypes } from './ListSearchableOverview.propTypes'

// Components
import { ListSearchableView } from '../ListSearchableView/ListSearchableView'
import { S_ListSearchableOverview, S_SearchWrapper } from './ListSearchableOverview.styles'
import { SearchBar } from '../SearchBar'
import { SearchFilter } from '../SearchFilter'
import { FilterIndicators } from '../SearchableView/FilterIndicators/FilterIndicators'
import { Box } from '../../components/Box/Box'
import debounce from 'lodash/debounce'

export const ListSearchableOverview = ({ views, searchParam, searchPlaceholder, filters }) => {
  const [searchFilters, setSearchFilters] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [setInnerSearchFilter, setSetInnerSearchFilter] = useState(null)

  const handleSearchTermChange = debounce((newTerm) => {
    if (!searchTerm && newTerm.length < 2) {
      return
    }

    setSearchTerm(newTerm)
  }, 1000)

  const handleSearchFiltersChange = (newFilters) => {
    setSearchFilters(newFilters)
  }

  const handleRemoveFilter = (filter) => {
    let newFilters = [...searchFilters]
    let toRemove = newFilters.findIndex((f) => f.column.fieldName === filter.column.fieldName)
    newFilters.splice(toRemove, 1)
    setInnerSearchFilter(newFilters)
  }

  return (
    <S_ListSearchableOverview>
      <S_SearchWrapper>
        {/* Search Term Filter */}
        <SearchBar
          iconClassName={'icon-right icon-desktop-lg'}
          inputClassName={'desktop-lg'}
          onChange={handleSearchTermChange}
          placeholder={searchPlaceholder}
        />

        <SearchFilter
          className="desktop-lg"
          columns={filters}
          defaultFilters={[]}
          getSetSearchFilter={(fn) => {
            setSetInnerSearchFilter(() => fn)
          }}
          onChange={handleSearchFiltersChange}
        />
      </S_SearchWrapper>
      <Box mt={'10px'}>
        <FilterIndicators removeFilter={handleRemoveFilter} searchFilters={searchFilters} />
      </Box>
      {/* Sub-views */}
      {views.map((viewConfig, i) => (
        <ListSearchableView
          key={i}
          searchFilters={searchFilters}
          searchParam={searchParam}
          searchTerm={searchTerm}
          viewConfig={viewConfig}
        />
      ))}
    </S_ListSearchableOverview>
  )
}

ListSearchableOverview.propTypes = listOverviewPropTypes
ListSearchableOverview.defaultProps = listOverviewDefaultProps
