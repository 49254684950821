import { jsx as _jsx } from "react/jsx-runtime";
import { ActionIcon } from 'xen-ui';
import { XenIcon } from 'xen-ui';
import { useDeleteInvoice } from 'xen/api/use-invoice';
import { useDeletePaymentAssignment } from 'xen/_modules/invoice-settlements/api';
export const DeletePaymentAssignmentButton = ({ 'aria-label': ariaLabel = 'Delete Invoice Assignment', assignment, ...props }) => {
    const { id: assignmentId, collectionsCheckId: paymentId, invoiceId } = assignment;
    const { mutateAsync: mutateDeletePaymentAssignment, isPending: isAssignmentPending } = useDeletePaymentAssignment(paymentId);
    const { mutateAsync: mutateDeleteInvoice, isPending: isInvoicePending } = useDeleteInvoice();
    const deletePaymentAssignment = async () => {
        await mutateDeletePaymentAssignment({ id: assignmentId });
        await mutateDeleteInvoice({ id: invoiceId });
    };
    return (_jsx(ActionIcon, { "aria-label": ariaLabel, color: "red", loading: isAssignmentPending || isInvoicePending, onClick: deletePaymentAssignment, size: "md", variant: "transparent", ...props, children: _jsx(XenIcon.Trash, { size: "2em" }) }));
};
