import React, { useState } from 'react'
import { history } from '../../../_helpers'

// Services
import { createEmptyInvoiceSchedule } from '../../../_services/lender.service'
import { businessService } from '../../../_services/business.service'

// Components
import { Button } from '../../../_components/Button'
import { AutoCompleteInput } from '../../../_components/AutoCompleteInput'
import { StatusBanner } from '../../../_components/StatusBanner'
import { LENDER_ROUTES } from '../../../_constants/routes'
import {
  CREATE_NEW_SCHEDULE_BUTTON,
  SELECT_A_CLIENT_ERROR,
  CREATE_NEW_SCHEDULE_TITLE,
  CLIENT_LABEL,
  DEFAULT_AUTOCOMPLETE_SUGGESTION,
} from './constants'
import S_LenderCreateScheduleModal from './LenderCreateScheduleModal.styles'

export const LenderCreateScheduleModal = () => {
  const [businessId, setBusinessId] = useState(0)
  const [overallErr, setOverallErr] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const getBusinessesByName = async (business_name) => {
    const MIN_CHARS_TO_SEARCH = 2
    if (business_name.length < MIN_CHARS_TO_SEARCH) return []

    setIsLoading(true)
    const businesses = await businessService.getBusinessesByName(business_name)
    setIsLoading(false)
    return businesses
  }

  const routeToNewSchedule = async (businessId) => {
    if (businessId) {
      const schedule = await createEmptyInvoiceSchedule(businessId)

      history.push(`${LENDER_ROUTES.schedules}/${schedule.id}/upload?businessId=${businessId}`, {
        business_id: businessId,
      })
    } else {
      setOverallErr(SELECT_A_CLIENT_ERROR)
    }
  }

  return (
    <S_LenderCreateScheduleModal>
      <div className={`business-selection`}>
        <div className="title">{CREATE_NEW_SCHEDULE_TITLE}</div>
        {overallErr && <StatusBanner className="err-banner" icon="error" message={overallErr} />}
        <label className="label">{CLIENT_LABEL}</label>
        <AutoCompleteInput
          addButton={false}
          checkInputValue={false}
          className={`business-auto-complete`}
          defaultSuggestion={DEFAULT_AUTOCOMPLETE_SUGGESTION}
          fetchRecord={businessService.getBusiness}
          fetchSuggestions={async (searchText) => await getBusinessesByName(searchText)}
          isLoading={isLoading}
          onChange={(e) => setBusinessId(e.target.value)}
          placeholder={DEFAULT_AUTOCOMPLETE_SUGGESTION}
          showInputStyles={true}
          value=""
          valueIdAttr={'id'}
          valueLabelAttr={'business_name'}
        />
        <Button className="create-schedule-btn" onClick={() => routeToNewSchedule(businessId)}>
          {CREATE_NEW_SCHEDULE_BUTTON}
        </Button>
      </div>
    </S_LenderCreateScheduleModal>
  )
}
