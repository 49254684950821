import React from 'react'

// Components
import Table from '../../../_components/Table'
import { Button } from '../../../_components/Button'
import { createColumn } from '../../../_components/TableColumn'

// Constants
import { LENDER_ROUTES } from '../../../_constants/routes'

const getStandardFRCols = (isAbl = false) => {
  return [
    {
      accessor: 'business_name',
      headingText: 'Client',
    },
    createColumn({
      type: 'date',
      id: 'updated_at',
      headingText: 'Updated',
    }),
    createColumn({
      type: 'approval',
      id: isAbl ? 'status' : 'last_approval',
      headingText: 'Status',
    }),
    createColumn({
      type: 'currency',
      id: 'amount',
      headingText: 'Amount',
    }),
    createColumn({
      type: 'boolean',
      id: 'funded',
      headingText: 'Funded',
    }),
  ]
}

export const FundingRequestColumns = [
  ...getStandardFRCols(),
  {
    accessor: `id`,
    headingText: '',
    disableSortBy: true,
    Cell: (props) => (
      <div className={`align-right`}>
        <Button
          buttonType="link"
          className="compact"
          text="Details"
          to={`${LENDER_ROUTES.funding_requests}/${props.value}`}
        />
      </div>
    ),
  },
]
export const FundingRequestABLColumns = [
  ...getStandardFRCols(true),
  {
    accessor: `id`,
    headingText: '',
    disableSortBy: true,
    Cell: (props) => (
      <div className={`align-right`}>
        <Button
          buttonType="link"
          className="compact"
          text="Details"
          to={`${LENDER_ROUTES.funding_requests}/abl/${props.value}`}
        />
      </div>
    ),
  },
]

export const FundingRequestsTable = ({
  fundingRequests,
  columns = FundingRequestColumns,
  handleSortChange = () => {},
  defaultSort = [],
}) => {
  if (!fundingRequests || fundingRequests.length === 0) {
    return null
  }

  return (
    <Table
      className="with-border"
      columns={columns}
      data={fundingRequests}
      defaultSort={defaultSort}
      key={2}
      onSortedChange={handleSortChange}
    />
  )
}
