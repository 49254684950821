import React from 'react'

// Components
import { InvoicesTable, defaultColumns } from '../../../LenderViews/InvoicesTable'
import { createColumn } from '../../../../_components/TableColumn'

// Helpers
import { isFeatureEnabled } from '../../../../_helpers/flags'
import { AppendParams } from '../../../../_helpers/util'

// Constants
import { INVOICES_FILTER_COLUMNS } from './constants'
import { getInvoicesPortfolio } from '../../../../_services/invoices.service'

const addBreakOutAdditionalColumns = (columns) => {
  const feesAccruedIdx = columns.findIndex((col) => col.accessor === 'fees_accrued')
  if (feesAccruedIdx !== -1) {
    const amountDue = createColumn({
      type: 'currency',
      accessor: 'amount_due',
      headingText: 'Amount Due',
      minWidth: 80,
    })
    const advanceAmount = createColumn({
      type: 'currency',
      accessor: 'advanced_amount',
      headingText: 'Advanced Amount',
      minWidth: 140,
    })

    columns.splice(feesAccruedIdx + 1, 0, amountDue)
    columns.splice(feesAccruedIdx, 0, advanceAmount)
  }
  return columns
}

const excludeColumns = (columnsToExclude = [], columns) => {
  return columns.filter((column) => {
    const columnName = column?.accessor || column?.id
    if (columnName && columnsToExclude.includes(columnName)) {
      return false
    }
    return true
  })
}

const getInvoicesViewColumns = (featureFlags, withStatus = false, columnsToExclude = []) => {
  let columns = [].concat(defaultColumns())
  if (featureFlags && isFeatureEnabled('breakout_additional_columns', featureFlags)) {
    columns = addBreakOutAdditionalColumns(columns)
  }

  if (columnsToExclude.length) {
    columns = excludeColumns(columnsToExclude, columns)
  }

  if (withStatus) {
    columns.push({
      accessor: 'state',
      headingText: 'Status',
    })
  }

  return columns
}

const getInvoicesEndpointCall = (searchParams, stateParam, compactStatus) => {
  const statusMapper = {
    'Non Funded': 'Non Funded',
    Closed: 'Closed',
    Collected: 'Closed',
    Funded: 'Purchased',
    Incomplete: 'Pending',
    Pending: 'Pending',
    Purchased: 'Purchased',
  }

  if (stateParam) {
    // AppendParams will prioritize filters from the SearchFilter.
    // In this case specifically it will ignore &state[]=purchased`
    // if either one has been selected in the filter.
    searchParams = AppendParams(searchParams, stateParam)
  }
  const alreadySorted = searchParams.has('sort_by')
  if (!alreadySorted) {
    searchParams.set('sort_by', 'updated_at')
    searchParams.set('sort_asc', false)
  }

  return getInvoicesPortfolio(searchParams.toString()).then((response) => {
    const { data = [], headers = {} } = response
    data.forEach((invoice) => {
      if (invoice.business && invoice.business.business_name) {
        invoice.business_name = invoice.business.business_name
      }

      if (compactStatus) {
        invoice.state = statusMapper[invoice.state]
      }
    })
    return {
      results: data,
      total_results: headers['total-count'] || 0,
    }
  })
}

export const createInvoiceView = ({
  title,
  stateParam = null,
  withStatus = false,
  compactStatus = false,
  featureFlags = null,
  columnsToExclude = [],
}) => {
  const columns = getInvoicesViewColumns(featureFlags, withStatus, columnsToExclude)

  return {
    title: title,
    endpointCall: (searchParams) => {
      return getInvoicesEndpointCall(searchParams, stateParam, compactStatus)
    },
    requestMethod: 'GET',
    pageSize: 25,
    columns: columns,
    Component: ({ results, sort, handleSortChange, pageSize, columns }) => {
      return (
        <InvoicesTable
          columns={columns}
          invoices={results}
          onSortedChange={handleSortChange}
          pageSize={pageSize}
          resizable={true}
          sorted={sort}
        />
      )
    },
  }
}

export const getViewsConfig = (viewType, businessId = null) => {
  const viewsConfig = {
    all: { title: 'All', stateParam: null, withStatus: true },
    closed: { title: 'Closed', stateParam: '&exclude_originate=true&state=closed' },
    ineligible: { title: 'Ineligible', stateParam: '&exclude_originate=true&ineligible=1' },
    not_funded: { title: 'Not Funded', stateParam: '&exclude_originate=true&non_funded=1' },
    pending: { title: 'Pending', stateParam: '&exclude_originate=true&state[]=pending' },
    purchased: {
      title: 'Open',
      stateParam: '&exclude_originate=true&state[]=purchased',
    },
    business_view: {
      title: 'All Invoices',
      stateParam: `${businessId ? `&business_id=${businessId}` : ''}&exclude_originate=true`,
      compactStatus: true,
      withStatus: true,
      columnsToExclude: ['business_name'],
    },
  }

  return viewsConfig[viewType]
}

export const viewMap = (featureFlags = null, viewType, businessId = null) => {
  const viewConfig = getViewsConfig(viewType, businessId)
  return createInvoiceView({ ...viewConfig, featureFlags: featureFlags })
}

export const generateFilters = (view) => {
  let newFilterColumns = INVOICES_FILTER_COLUMNS

  if (view !== 'all') {
    newFilterColumns.filter((column) => column.fieldName !== 'state')
  }

  if (view === 'not_funded') {
    newFilterColumns.filter((column) => column.fieldName !== 'non_funded')
  }

  if (view === 'purchased') {
    newFilterColumns = INVOICES_FILTER_COLUMNS.map((column) => {
      if (column.fieldName === 'state') {
        column.options = column.options.filter((option) => {
          return option.value === 'purchased'
        })
      }
      return column
    })
  }

  if (view === 'business_view') {
    newFilterColumns = INVOICES_FILTER_COLUMNS.filter((column) => column.fieldName !== 'business_id')
  }
  return newFilterColumns
}
