import React from 'react'

import { Chip } from '../../../_components/AutoCompleteInput/Chip'
import { useTenant } from '../../../_reduxHooks/tenant.hook'
import { getContrastColor } from '../../../utils/theme'

export const TenantBadge = ({ className = '', prospect }) => {
  const prospectTenantId = prospect.tenant_id

  const { tenantObj: currentTenant } = useTenant()
  const { alias_tenants: aliasTenants, id: currentTenantId } = currentTenant

  // By default, do not show tenant badge
  let badgeTenant
  // If the current tenant has alias tenants, show badge for current / alias tenants
  if (aliasTenants?.length > 0) {
    const aliasTenant = aliasTenants.find((tenant) => tenant.id === prospectTenantId)
    badgeTenant = prospectTenantId === currentTenantId ? currentTenant : aliasTenant
  }
  if (!badgeTenant) return <span data-testid="no-tenant-badge" />

  return (
    <span data-testid="tenant-badge">
      <Chip
        className={className}
        showDelete={false}
        style={{
          color: getContrastColor(badgeTenant.customization?.color_primary ?? 'gray'),
          backgroundColor: badgeTenant.customization?.color_primary ?? 'gray',
        }}
        text={badgeTenant.organization_name}
      />
    </span>
  )
}
