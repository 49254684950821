import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

// Components
import { StandardPage } from '../../../_components/layout/StandardPage'
import { SearchableOverview } from '../../../_components/SearchableOverview'

import Signers from '../EsigningTableNew/Signers'
import { createColumn } from '../../../_components/TableColumn'
import startCase from 'lodash/startCase'
import { Button } from '../../../_components/Button'

// Services
import { getSignatureRequest } from '../../../_services/v2/documents.service'
import request from '../../../_services/axios_service'
import download from 'downloadjs'
import { Table } from '../../../_components/Table'
import { invoiceSchedulePath } from 'legacy/_helpers'

const S_SigningDocuments = styled.div`
  .page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const SigningDocuments = () => {
  const downloadDocument = (url) => {
    request({
      url: url,
      method: 'GET',
      responseType: 'arraybuffer',
    }).then((resp) => {
      download(resp, 'signed_document.pdf')
    })
    return true
  }

  const columns = [
    {
      accessor: 'document.template.label',
      headingText: 'Document Name',
    },
    createColumn({
      id: 'document.operable_entity_type',
      accessor: (d) => {
        let ref_number = null
        if (d.batch_number) {
          ref_number = d.batch_number
        } else {
          ref_number = d.document.operable_entity_id
        }
        return startCase(d.document.operable_entity_type) + ` #${ref_number}`
      },
      type: 'link',
      disableSortBy: true,
      getRoute: (d) => {
        let entity_id = d.document.operable_entity_id
        let context = d.document.operable_entity_type
        let link = null

        if (!entity_id) {
          link = undefined
        }
        if (['InvoiceSchedule'].includes(context)) {
          link = invoiceSchedulePath(entity_id)
        }
        return link
      },
      headingText: 'Source Link',
    }),
    {
      accessor: 'business_name',
      headingText: 'Client',
    },
    createColumn({
      type: 'dateTime',
      id: 'request_date',
      headingText: 'Requested Date',
    }),
    {
      accessor: 'signers',
      headingText: 'Signers',
      disableSortBy: true,
      Cell: (props) => {
        return <Signers signers={props.row.original.signers} />
      },
    },
    {
      accessor: 'document_url',
      headingText: '',
      disableSortBy: true,
      Cell: (props) => {
        return (
          <Button
            disabled={props.row.original.document_url === null}
            onClick={() => downloadDocument(props.row.original.document_url)}
          >
            Signed Document Download
          </Button>
        )
      },
    },
  ]

  return (
    <S_SigningDocuments>
      <StandardPage title="Signing Documents">
        <header className="page-header">
          <h1>Signing Documents</h1>
        </header>
        <SearchableOverview
          allowDownload={false}
          searchPlaceholder="Signing Documents"
          showSearchBar={false}
          views={[
            {
              title: 'All documents',
              endpointCall: (searchParams) => {
                return getSignatureRequest(searchParams).then((resp) => {
                  return {
                    total_results: resp.headers['total-count'],
                    results: resp.data,
                  }
                })
              },
              requestMethod: 'GET',
              pageSizes: [5, 10, 20],
              columns: columns,
              allowDownload: false,
              Component: ({ results, sort, handleSortChange, pageSize, columns }) => (
                <Table
                  className="with-border"
                  columns={columns}
                  data={results}
                  onSortedChange={handleSortChange}
                  pageSize={pageSize}
                  sorted={sort}
                />
              ),
            },
          ]}
        />
      </StandardPage>
    </S_SigningDocuments>
  )
}

const mapStateToProps = (state) => {
  const { esigning, user, env } = state
  return {
    userId: user.id,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

const connectedSigningDocuments = connect(mapStateToProps, mapDispatchToProps)(SigningDocuments)

export { connectedSigningDocuments as SigningDocuments }
