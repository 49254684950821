import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, useForm } from 'react-hook-form';
import { z } from 'zod';
import { Button, Group, Modal, useDisclosure } from 'xen-ui';
import { transformInvoicesToSelectOptionsGroupedByDebtor, useGetInvoices } from 'xen/api/use-invoice';
import { ErrorText } from 'xen/components';
import { FormSelect } from 'xen/components/form';
import { useCreatePaymentAssignment, useGetInvoiceSettlement } from '../../api/';
import { NonFactoredInvoiceCreateForm } from '../../components';
const paymentAssignmentCreateFormSchema = z.object({
    invoiceId: z.string().optional(),
});
export const PaymentAssignmentCreateForm = ({ payment }) => {
    const debtorId = payment.debtorId;
    const params = {
        // Scope invoices to a specific debtor if the payment has a debtor assigned
        ...(debtorId && { debtorId }),
        // Only allow invoices that are purchased to be assigned to a payment
        state: ['purchased'],
        // Fetch only invoices that are not already assigned to the current payment
        collectionsCheckId: payment.id,
        // HACK: This endpoint is paginated, but we want to fetch all invoices for the current debtor.
        // Right now the max amount of purchased invoices in the db is ~1600, so this should be sufficient.
        // Eventually this component should be able to handle paginated results.
        pagination: { count: 2000 },
    };
    const { data: invoices = [], isSuccess: isInvoicesLoaded } = useGetInvoices({ queryParams: params });
    const invoiceSelectDataGroupedByDebtor = transformInvoicesToSelectOptionsGroupedByDebtor(invoices);
    const { data: settlement } = useGetInvoiceSettlement(String(payment.paymentBatchId));
    const isCompleted = settlement?.state === 'completed';
    const defaultValues = {
        invoiceId: '',
    };
    const { control, handleSubmit, setValue } = useForm({
        resolver: zodResolver(paymentAssignmentCreateFormSchema),
        mode: 'onBlur',
        values: defaultValues,
    });
    const { mutate: createPaymentAssignment, isError: isCreateError } = useCreatePaymentAssignment();
    const onSubmit = async (data) => {
        if (!data.invoiceId)
            return;
        const invoiceId = Number(data.invoiceId);
        const invoice = invoices.find((i) => i.id === invoiceId);
        if (!invoice) {
            console.error(`Invoice ${invoiceId} not found`);
            return;
        }
        const payload = {
            amount: 0,
            collectionsCheckId: String(payment.id),
            businessId: invoice.businessId,
            invoiceId,
            type: 'NotSetAction',
        };
        createPaymentAssignment(payload);
    };
    const handleFormSubmit = () => {
        handleSubmit(onSubmit)();
        // TODO: Clear the form after submission (this isn't working as expected, may need a controlled input)
        handleClear();
    };
    const handleError = (e) => {
        console.error('Form error:', e);
    };
    const handleClear = () => {
        setValue('invoiceId', '');
        setSearchValue(lastSearchValue);
    };
    const [lastSearchValue, setLastSearchValue] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const handleSearchChange = (value) => {
        setSearchValue(value);
        setLastSearchValue(value);
    };
    const handleOnBlur = () => {
        // Prevents the search value from being cleared when the input loses focus the first time
        setSearchValue(lastSearchValue);
        // Prevents the search value from being cleared when the input loses focus subsequent times
        setLastSearchValue(lastSearchValue);
    };
    const noPaymentDebtor = !debtorId;
    const noDebtorInvoices = invoices.length === 0;
    let error = '';
    if (noPaymentDebtor) {
        error = 'A payment debtor must be selected before invoices can be assigned.';
    }
    const [opened, { open, close }] = useDisclosure(false);
    return (_jsxs(Form, { control: control, onError: handleError, onSubmit: handleFormSubmit, children: [isCreateError && _jsx(ErrorText, { message: "Error creating payment assignment" }), _jsxs(Group, { align: "flex-end", children: [_jsx(FormSelect, { clearable: true, control: control, data: invoiceSelectDataGroupedByDebtor, description: isInvoicesLoaded && noDebtorInvoices
                            ? `No invoices found for ${payment.debtorName}`
                            : 'Search by invoice number, client, balance, or purchase date', disabled: isCompleted || noPaymentDebtor || noDebtorInvoices, error: error, flex: 1, label: "Invoice Search", name: "invoiceId", nothingFoundMessage: "No invoices match that search", onBlur: handleOnBlur, onChange: handleFormSubmit, onClear: handleClear, onSearchChange: (value) => {
                            handleSearchChange(value ?? '');
                        }, searchValue: searchValue, searchable: true, w: "100%" }), _jsx(Button, { disabled: isCompleted || noPaymentDebtor, onClick: open, variant: "light", children: "Create Non-Factored Invoice" }), _jsx(Modal, { onClose: close, opened: opened, title: "Non-Factored Invoice", children: _jsx(NonFactoredInvoiceCreateForm, { onSuccess: close, payment: payment }) })] })] }));
};
