import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, useForm } from 'react-hook-form';
import { z } from 'zod';
import { Alert, Stack } from 'xen-ui';
import { TextLink } from 'xen/components';
import { ErrorText } from 'xen/components';
import { FormCheckbox, FormCurrencyInput, FormDebtorSelect, FormSelect, FormTextInput, } from 'xen/components/form';
import { formatCurrency } from 'xen/helpers';
import { useDebouncedCallback } from 'xen/hooks';
import { useGetInvoiceSettlement, useGetPaymentAssignments, useUpdatePayment } from '../../api';
import { PaymentSources } from '../../types';
const paymentFormSchema = z.object({
    amount: FormCurrencyInput.schema({ name: 'amount', label: 'Amount', required: true }),
    checkNumber: z.string().optional(),
    debtorId: z.string().optional(),
    nptLockbox: z.boolean().optional(),
    source: z.enum(PaymentSources),
});
export const PaymentForm = ({ payment }) => {
    const { data: settlement } = useGetInvoiceSettlement(String(payment.paymentBatchId));
    const isPartiallyProcessed = settlement?.state === 'partially_processed';
    const isCompleted = settlement?.state === 'completed';
    const isFormDisabled = isPartiallyProcessed || isCompleted;
    const { data: assignments = [] } = useGetPaymentAssignments({ collectionsCheckId: String(payment.id) });
    const defaultValues = {
        amount: String(payment.amount ?? ''),
        checkNumber: String(payment.checkNumber ?? ''),
        debtorId: String(payment.debtorId ?? ''),
        nptLockbox: payment.nptLockbox,
        source: payment.source,
    };
    const { control, handleSubmit, setValue } = useForm({
        resolver: zodResolver(paymentFormSchema),
        mode: 'onChange',
        values: defaultValues,
    });
    const { mutate: updatePayment, isError } = useUpdatePayment(payment.id);
    const onSubmit = (data) => {
        const payload = {
            amount: FormCurrencyInput.formatValue(data.amount),
            checkNumber: data.checkNumber,
            debtorId: data.debtorId ? Number(data.debtorId) : null,
            nptLockbox: data.nptLockbox,
            source: data.source,
        };
        updatePayment({ id: payment.id, data: payload });
    };
    const handleFormSubmit = () => {
        handleSubmit(onSubmit)();
    };
    const handleDebouncedFormSubmit = useDebouncedCallback(handleFormSubmit, 1000);
    const handleError = (e) => {
        console.error('Form error:', e);
    };
    const handleClear = () => {
        setValue('debtorId', '');
        handleFormSubmit();
    };
    const paymentVsPostedDifference = Number(payment.amount) - payment.amountPosted;
    const paymentSourceData = [
        {
            value: 'ach',
            label: 'ACH',
        },
        {
            value: 'check',
            label: 'Check',
        },
        {
            value: 'wire',
            label: 'Wire',
        },
    ];
    const paymentHasAssignments = assignments.length > 0;
    const unappliedCashLink = (_jsx(TextLink, { to: "/unapplied-cash/:paymentId", urlParams: [payment.id], children: "Unapplied Cash" }));
    return (_jsx(Form, { control: control, onError: handleError, onSubmit: handleFormSubmit, children: _jsxs(Stack, { gap: "sm", children: [isError && _jsx(ErrorText, { message: "Error updating payment" }), paymentVsPostedDifference > 0 && (_jsxs(Alert, { color: "yellow", title: unappliedCashLink, variant: "light", children: [_jsx("b", { children: formatCurrency(paymentVsPostedDifference) }), " of this payment is not assigned to an invoice."] })), paymentVsPostedDifference < 0 && (_jsxs(Alert, { color: "red", title: "Insufficient Payment", variant: "light", children: [_jsx("b", { children: formatCurrency(Math.abs(paymentVsPostedDifference)) }), " in assigned invoices over payment amount."] })), _jsx(FormCurrencyInput, { control: control, disabled: isFormDisabled, error: payment.errors.amount, label: "Payment Amount", name: "amount", onBlur: handleDebouncedFormSubmit, required: true }), _jsx(FormTextInput, { control: control, disabled: isFormDisabled, error: payment.errors.checkNumber, label: "Transaction/Check #", name: "checkNumber", onBlur: handleDebouncedFormSubmit, required: true }), _jsx(FormSelect, { control: control, data: paymentSourceData, disabled: isFormDisabled, error: !payment.source && `Source can't be blank`, label: "Source", name: "source", onChange: handleFormSubmit, required: true }), _jsx(FormDebtorSelect, { clearable: !paymentHasAssignments, control: control, description: paymentHasAssignments ? `Can't change debtor with existing assignments` : '', disabled: isFormDisabled || paymentHasAssignments, error: payment.errors.tenantDebtor, name: "debtorId", onChange: handleFormSubmit, onClear: handleClear, required: true, searchable: true }), _jsx(FormCheckbox, { control: control, disabled: isFormDisabled, label: "Not payable to lockbox", name: "nptLockbox", onInput: handleFormSubmit })] }) }));
};
