import React from 'react'

// Components
import { PDFPage } from './PDFPage'

/**
 * View.js
 * Component that works as a pdf pages "container"
 * - default usage is in ../PDFViewer.js
 **/
const PDFViewer = ({ pdf, ...props }) => {
  const numPages = pdf ? pdf.numPages : 0

  if (pdf) {
    return (
      <div className="pdf-viewer">
        {Array.apply(null, { length: numPages }).map((v, i) => (
          <PDFPage index={i + 1} key={`document-page-${i}`} pdf={pdf} {...props} />
        ))}
      </div>
    )
  }

  return null
}

export { PDFViewer }
