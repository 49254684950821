import { jsx as _jsx } from "react/jsx-runtime";
import { Divider } from 'xen-ui';
export const AppLayoutDivider = ({ orientation, ...rest }) => {
    const shared = {
        color: 'var(--app-shell-color-border)',
        my: 0,
    };
    if (orientation === 'vertical') {
        return _jsx(Divider, { ...shared, ...rest, h: "inherit", orientation: "vertical" });
    }
    return _jsx(Divider, { ...shared, ...rest });
};
