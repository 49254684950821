import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { S_ContentColumn } from '../../../_components/layout/ContentColumn'
import { SectionLoader } from '../../../_components/SectionLoader'
import { S_SplitButton } from '../../../_components/SplitButton'
import { NewProspectFormSummary } from '../../../_components/FormSummary/NewProspectFormSummary'
import { S_Subtitle } from '../../../_components/Typography/Titles.styles'
import { Text } from '../../../_components/Typography/Components/Text/Text'
import colors from '../../../_constants/colors'
import { S_PageHeader, S_PageHeaderRow } from '../../../_components/layout/PageHeader/PageHeader.styles'
import { useUser } from '../../../_reduxHooks/user.hook'
// Services
import { getDealMetaForm, showDeal, showDealFormValues } from '../../../_services/deals.service'
import { getUnderwritingChecklist, getUnderwritingChecklistAnswers } from '../../../_services/underwrite.service'
import { UnderwritingChecklistSummary } from 'legacy/_components/FormSummary/UnderwritingChecklistSummary'

export const S_ProspectDetailsApplication = styled.div`
  .prospect-status {
    font-weight: 600;
    margin-left: 25px;
    margin-bottom: 16px;
    color: gray;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    button {
      display: block;
    }

    .sub-actions {
      display: flex;
      ${S_SplitButton} {
        margin-right: 20px;
        font-size: 16px;
        .split-button {
          margin-top: 0;
        }
      }
    }
  }

  .edit-btn {
    max-height: 35px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 11px;
    padding-right: 11px;
  }

  .label-btn-container {
    display: inline;
    padding-left: 10px;
  }

  .edit-btn:first-child {
    margin-right: 16px;
  }

  ${S_ContentColumn} {
    padding: 0;
  }

  @media print {
    .no-print {
      display: none;
    }
  }
`

export const ProspectDetailsApplication = ({
  match,
  onLoaded = () => {},
  defaultShowPII = false,
  dealId = undefined,
  printVersion = false,
  showUnderwritingChecklist = false,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [values, setValues] = useState({})
  const [metaForm, setMetaForm] = useState()
  const { user } = useUser()
  const [deal, setDeal] = useState({})
  const [checklist, setChecklist] = useState(null)
  const [checklistAnswers, setChecklistAnswers] = useState({})

  // Check Deal Id
  dealId = dealId || match.params.businessId
  if (dealId === undefined) {
    return null
  }

  // Get client data
  useEffect(() => {
    // Skip if we've already loaded (unmount)
    if (!isLoading) return
    const redactValues = !defaultShowPII
    Promise.all([
      showDeal(dealId),
      showDealFormValues(dealId, redactValues),
      getDealMetaForm(dealId, user.user_type),
    ]).then(([deal, values, metaForm]) => {
      setDeal(deal)
      setValues(values)
      setMetaForm(metaForm)
      setIsLoading(false)
    })
    if (showUnderwritingChecklist) {
      getUnderwritingChecklistAnswers(dealId).then((resp) => {
        const answers = resp.reduce((newObject, answer) => {
          newObject[answer.question] = answer.value
          return newObject
        }, {})
        setChecklistAnswers(answers)
      })
      getUnderwritingChecklist(dealId).then((resp) => {
        setChecklist(resp)
      })
    }
  }, [dealId])

  useEffect(() => {
    if (!isLoading) {
      onLoaded()
    }
  }, [isLoading])

  return (
    <S_ProspectDetailsApplication date-testid="container-div">
      {/* Loading until we have details */}
      {isLoading && <SectionLoader />}

      {/* Page Header */}
      {!isLoading && (
        <>
          {!printVersion && (
            <S_PageHeader className="no-print">
              <S_PageHeaderRow>
                <h2 className="title">Prospect Application</h2>
              </S_PageHeaderRow>
            </S_PageHeader>
          )}

          {showUnderwritingChecklist && (
            <UnderwritingChecklistSummary checklist={checklist} checklistAnswers={checklistAnswers} deal={deal} />
          )}

          <NewProspectFormSummary
            checklist={checklist}
            checklistAnswers={checklistAnswers}
            deal={deal}
            metaForm={metaForm}
            printVersion={printVersion}
            showEdit={false}
            showTitle={false}
            showUnderwritingChecklist={showUnderwritingChecklist}
            values={values}
          />

          <Disclaimer />
        </>
      )}
    </S_ProspectDetailsApplication>
  )
}

const Disclaimer = () => {
  return (
    <Text data-testid="disclaimer" fs={'12px'} textColor={colors.SUBTITLE_GREY}>
      XEN stores and manages sensitive data using Amazon's Key Management Service, a fully compliant service with best
      in class encryption and security.
    </Text>
  )
}
