import { useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { formatCase, getTenantFromUrl } from 'xen/helpers';
import { notifications } from 'xen/helpers';
import { queryClient } from 'xen/providers';
export const useUpdateConfiguration = () => {
    return useMutation({
        mutationKey: ['update-configuration'],
        mutationFn: async ({ payload }) => {
            try {
                const response = await request({
                    url: `/v2/configuration`,
                    method: 'PATCH',
                    data: payload,
                });
                return formatCase.objectKeys('camel', response);
            }
            catch (error) {
                throw Error('Could not update configuration');
            }
        },
        onError: (error) => {
            notifications.error(error.message);
        },
        onSuccess: () => {
            notifications.success(`Updated configuration`);
            queryClient.invalidateQueries({ queryKey: ['tenants', getTenantFromUrl(), 'configuration'] });
        },
    });
};
