import styled from 'styled-components'
import colors from '../../_constants/colors'
import { S_Subtitle } from '../Typography/Titles.styles'

export const S_FormSummary = styled.div`
  margin: 20px 0;

  ${S_Subtitle} {
    padding-left: 12px;
  }

  h4 {
    padding: 10px 0;
  }

  .summary-paragraph {
    padding-left: 12px;
    color: ${colors.TEXT_DARK};
    margin-top: 5px;
  }

  .section-header {
    display: flex;
    align-items: center;

    .edit-section-icon {
      opacity: 0.5;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
      margin-left: 5px;
      &:hover {
        opacity: 0.75;
      }
    }
  }

  .form-section {
    margin-bottom: 20px;
    @media print {
      padding: 20px 0px;
      grid-gap: 5px;

      &:first-of-type {
        padding-top: 10px;
      }
    }
  }

  .disclaimer-text {
    text-align: left;
    font-weight: bold;
    white-space: pre-line;
    opacity: 0.5;
    line-height: 2em;
  }
  .full-disclaimer {
    text-align: center;
    margin-top: 16px;
    div {
      color: #900;
    }
    div:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .pii-show-control {
    display: flex;
    flex-direction: column;
    max-width: 200px;
  }
  @media print {
    display: block;
  }

  &.print-version {
    ${S_Subtitle} {
      padding-left: 0;
      margin-bottom: 0;
      color: #000;
    }

    .field-group {
      margin-bottom: 0;
    }

    .sections {
      border: 1px solid #000;
      counter-reset: section;
    }

    .form-section {
      border-bottom: 1px solid #000;
      padding: 20px;
      margin-bottom: 0px;
    }

    .field-group:not(.following_question) {
      padding: 4px 0;
      & > .field .label {
        &:before {
          counter-increment: section;
          content: counter(section) '. ';
        }
      }
    }

    .field-group.following_question {
      padding-left: 25px;
    }

    .business-officer {
      counter-reset: officer;
      .field-group .field {
        padding-left: 15px;
        .label {
          &:before {
            counter-increment: officer;
            content: counter(officer, lower-alpha) '. ';
          }
        }
      }
    }
  }

  &.horizontal-padding {
    padding-left: 24px;
    padding-right: 24px;
  }
`

export const FieldGroup = styled.div`
  display: grid;
  grid-row-gap: 5px;
  margin-bottom: ${(props) => props.hasFollowingQuestion && `25px`};

  &:not(.print-version) {
    .field {
      display: grid;
      padding: 12px;

      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;

      .label {
        color: ${colors.TEXT_LIGHT};
      }

      .value {
        color: ${colors.TEXT_DARK};
        word-break: break-word;

        .no-value {
          opacity: 0.5;
          font-style: italic;
          font-weight: normal;
        }
      }
    }

    .business_officers {
      padding-left: 40px;
    }
  }
`

export const S_PrintSummaryHeader = styled.div`
  ${S_Subtitle} {
    &.section {
      padding: 10px 0;
      border-top: 2px solid #000;
      border-bottom: 2px solid #000;
      margin-top: 20px;
    }
  }

  p {
    margin: 20px 0;
  }
`

export const S_FormSectionFields = styled.div`
  ${FieldGroup} {
    &:not(.print-version) {
      &:nth-child(odd) {
        background: ${colors.INNER_GREY_BOX};
      }
    }
  }
`
