import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

// Components
import { StandardPage } from '../../../_components/layout/StandardPage'
import { ClientsTable, getClientTableColumns, clientsOverviewColumns } from '../ClientsTable'
import { SearchableOverview } from '../../../_components/SearchableOverview'

// Services
import { getActiveClients, getClientsOverview } from '../../../_services/businesses.service'
import { getABLBusinessesOverview } from '../../../_services/abl/abl-business.service'

// Styled Component
export const S_ClientsOverview = styled.div``

//Helpers
import { getDateFromDatetime, getDateInputFormat } from '../../../_helpers/dates'
const todayIsoDate = getDateInputFormat(new Date())
import { useFlags } from '../../../_reduxHooks/flags.hook'
import { isProductEnabled, isFeatureEnabled } from '../../../_helpers/flags'
import { ABLClientsTable, ABLClientsTableColumns } from '../ABLClientsTable'

export const ABLClientsView = (showTotals = false) => {
  return {
    title: 'Active Clients',
    endpointCall: (search) => {
      return getABLBusinessesOverview(search).then((clients) => {
        const totalData = JSON.parse(clients.headers['totals'])
        return {
          results: clients.data.map((client) => ({
            ...client,
            eligible: client.collateral - client.ineligible,
          })),
          total_results: clients.headers['total-count'],
          totals: showTotals
            ? { ...totalData, name: 'Total', eligible: totalData.collateral - totalData.ineligible }
            : null,
        }
      })
    },
    requestMethod: 'GET',
    showPagination: true,
    columns: ABLClientsTableColumns,
    Component: ({ results, sort, handleSortChange, pageSize, columns, totals }) => (
      <ABLClientsTable
        clients={results}
        columns={columns}
        handleSort={handleSortChange}
        pageSize={pageSize}
        sorted={sort}
        totals={totals}
      />
    ),
  }
}

export const FactoringClientsView = (useNonStandardNFE, clientsOverviewEnabled) => {
  const getOverviewData = (searchParams) => {
    if (clientsOverviewEnabled) {
      return getClientsOverview(searchParams)
    }
    return getActiveClients(searchParams)
  }

  return {
    title: 'Active Clients',
    endpointCall: (searchParams) => getOverviewData(searchParams),
    requestMethod: clientsOverviewEnabled ? 'GET' : 'POST',
    columns: clientsOverviewEnabled ? clientsOverviewColumns() : getClientTableColumns(useNonStandardNFE),
    defaultSort: [{ id: 'business_name', desc: false, disableNoSort: true }],
    Component: ({ results, sort, handleSortChange, pageSize, columns, defaultSort }) => (
      <ClientsTable
        clients={results}
        columns={columns}
        defaultSort={defaultSort}
        handleSort={handleSortChange}
        pageSize={pageSize}
        sorted={sort}
      />
    ),
  }
}

export const ClientsOverview = () => {
  const { featureFlags } = useFlags()
  const isAblEnabled = isProductEnabled('Asset Based Lending', featureFlags)
  const [views, setViews] = useState([])
  const useNonStandardNFE = isFeatureEnabled('use_non_standard_nfe', featureFlags)
  const clientsOverviewEnabled = isFeatureEnabled('operation_performed_async', featureFlags)

  useEffect(() => {
    if (isAblEnabled) {
      setViews([ABLClientsView()])
    } else {
      setViews([FactoringClientsView(useNonStandardNFE, clientsOverviewEnabled)])
    }
  }, [])

  return (
    <StandardPage title="Clients Overview">
      <S_ClientsOverview>
        <h1>Clients Overview</h1>

        <SearchableOverview
          filterColumns={[
            {
              fieldName: 'start_became_client_at',
              displayName: 'Client Added Date Start',
              formatter: (value) => (typeof value === 'string' && value ? getDateFromDatetime(value) : `-`),
              max: todayIsoDate,
              type: 'date',
            },
            {
              fieldName: 'end_became_client_at',
              displayName: 'Client Added Date End',
              formatter: (value) => (typeof value === 'string' && value ? getDateFromDatetime(value) : `-`),
              max: todayIsoDate,
              type: 'date',
            },
            {
              fieldName: 'archived_clients',
              displayName: 'Only archived clients',
              type: 'customTruthy',
              fieldNameAsLabel: true,
              customTruthy: [
                {
                  paramName: 'client_status',
                  paramValue: 'archived',
                },
              ],
            },
          ]}
          searchPlaceholder="Search Clients"
          showSearchFilter={true}
          views={views}
        />
      </S_ClientsOverview>
    </StandardPage>
  )
}
