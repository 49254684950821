import React from 'react'

// Entities
import { useDebtorPayment } from '../../../_entityHooks/debtorPayment.hook'
import { useTheme } from 'styled-components'

// Components
import Table from '../../../_components/Table'
import { S_CompletedPayment } from './completedPayment.styles'
import { FilesManager } from '../../FilesManager'
import { RenderIf } from '../../../components/helpers/RenderIf/RenderIf'
import { CompletedPaymentPdf } from './CompletedPayment.pdf'
import { completedPaymentXlsx } from './CompletedPayment.xlsx'
import { SectionLoader } from '../../SectionLoader'

// Helpers
import { createColumn } from '../../TableColumn'
import { formatCurrency } from '../../../_helpers'
import { Grid, GridCol } from '../../../components/Grid'
import { Text } from '../../Typography/Components/Text/Text'
import colors from '../../../_constants/colors'
import { Box } from '../../../components/Box/Box'
import { Downloader } from '../../PaginationControls'
import { downloadFile } from '../../../_helpers/download'

import ReactPDF from '@react-pdf/renderer'

const invoiceColumns = [
  {
    accessor: 'business_name',
    headingText: 'Client',
    disableSortBy: true,
  },
  createColumn({
    type: 'text',
    id: 'invoice_number',
    headingText: 'Invoice #',
    disableSortBy: true,
  }),
  createColumn({
    type: 'currency',
    id: 'invoice_amount',
    headingText: 'Invoice Amount',
    disableSortBy: true,
  }),
  createColumn({
    type: 'currency',
    id: 'amount',
    headingText: 'Payment Amount',
    disableSortBy: true,
  }),
  {
    accessor: 'action',
    headingText: 'Payment Action',
    disableSortBy: true,
  },
  createColumn({
    type: 'currency',
    id: 'invoice_balance',
    headingText: 'Balance',
    disableSortBy: true,
  }),
  {
    accessor: 'adjust_amount',
    headingText: 'Adjust Amount',
    type: 'currency',
    disableSortBy: true,
    Cell: (props) => {
      const { action, invoice_amount, amount } = props.row.original
      const isShortPay = action === 'Short Pay'
      const isOverPay = action === 'Over Pay'
      if (isShortPay || isOverPay) {
        const adjust_value = amount - invoice_amount
        const adjust_amount = Math.abs(adjust_value)
        return formatCurrency(adjust_amount)
      }
      return ''
    },
  },
  createColumn({
    type: 'date',
    id: 'purchase_date',
    headingText: 'Purchase Date',
    disableSortBy: true,
  }),
  {
    accessor: 'invoice_schedule_batch_number',
    headingText: 'Schedule',
    disableSortBy: true,
  },
  createColumn({
    type: 'count',
    id: 'age',
    headingText: 'Age',
    accessor: 'age',
    disableSortBy: true,
  }),
  createColumn({
    accessor: 'escrow_reserve',
    headingText: 'Escrow Amount',
    type: 'currency',
    disableSortBy: true,
  }),
  createColumn({
    type: 'currency',
    id: 'fees_earned',
    headingText: 'Earned Fees',
    accessor: 'fees_earned',
    disableSortBy: true,
  }),
]

export const completedPayment = ({ debtorPayment }) => {
  // const { closeModal } = useModal()

  const theme = useTheme()

  const { payment, escrowReserves, fees, nonFactored, loading } = useDebtorPayment(debtorPayment.id)
  return (
    <S_CompletedPayment>
      {loading && <SectionLoader size={50} />}

      {!loading && (
        <>
          <Box mb={'15px'}>
            <Grid>
              <GridCol xs={'auto'}>
                <Text fs={'16px'} mb={'5px'} textColor={colors.TEXT_LIGHT}>
                  Debtor
                </Text>
                <Text fs={'20px'} fw={500}>
                  {payment.debtor_name}
                </Text>
              </GridCol>
              <GridCol xs={'auto'}>
                <Text fs={'16px'} mb={'5px'} textColor={colors.TEXT_LIGHT}>
                  Check/Transaction #
                </Text>
                <Text fs={'20px'} fw={500}>
                  {payment.check_number}
                </Text>
              </GridCol>
              <GridCol xs={'auto'}>
                <Text fs={'16px'} mb={'5px'} textColor={colors.TEXT_LIGHT}>
                  Payment Amount
                </Text>
                <Text fs={'20px'} fw={500}>
                  {formatCurrency(payment.amount)}
                </Text>
              </GridCol>
            </Grid>
          </Box>
        </>
      )}
      {!loading && payment && (
        <Table
          className="with-border"
          columns={invoiceColumns}
          data={payment.invoices}
          pageSize={payment.invoices.length}
        />
      )}

      <div style={{ marginTop: '30px' }} />

      {!loading && (
        <>
          <Box mb={'15px'}>
            <Grid columns={6}>
              <GridCol xs={'auto'}>
                <Text className={'summary-title'} display={'inline-block'} fs={'20px'} fw={500} mt={'15px'}>
                  Summary Info
                </Text>
              </GridCol>
              <GridCol xs={'auto'}>
                <Text fs={'14px'} mb={'5px'} textColor={colors.TEXT_LIGHT}>
                  Total Amount Paid
                </Text>
                <Text fs={'18px'} fw={500}>
                  {formatCurrency(payment.amount)}
                </Text>
              </GridCol>
              <GridCol xs={'auto'}>
                <Text fs={'14px'} mb={'5px'} textColor={colors.TEXT_LIGHT}>
                  Applied to AR
                </Text>
                <Text fs={'18px'} fw={500}>
                  ({formatCurrency(payment.amount)})
                </Text>
              </GridCol>
              <GridCol xs={'auto'}>
                <Text fs={'14px'} mb={'5px'} textColor={colors.TEXT_LIGHT}>
                  Escrow Reserves Released
                </Text>
                <Text fs={'18px'} fw={500}>
                  {formatCurrency(escrowReserves)}
                </Text>
              </GridCol>
              <GridCol xs={'auto'}>
                <Text fs={'14px'} mb={'5px'} textColor={colors.TEXT_LIGHT}>
                  Client Reserves
                </Text>
                <Text fs={'18px'} fw={500}>
                  {formatCurrency(escrowReserves - fees + nonFactored)}
                </Text>
              </GridCol>
            </Grid>
          </Box>
        </>
      )}
      <RenderIf condition={payment.documents && payment.documents.length > 0}>
        <div style={{ marginTop: '50px' }} />
        <FilesManager
          allowDelete={false}
          allowUpload={false}
          draggable={false}
          files={payment.documents}
          title="Files"
          viewType="gallery"
        />
      </RenderIf>
      {!loading && (
        <div className="download-container">
          <Downloader
            color={colors.DEFAULT_SECONDARY}
            isLoadingDownload={false}
            onDownloadClick={(type) => {
              if (type === 'pdf') {
                ReactPDF.pdf(
                  <CompletedPaymentPdf
                    colors={theme.colors}
                    escrowReserves={escrowReserves}
                    fees={fees}
                    payment={payment}
                  />
                )
                  .toBlob()
                  .then((blob) => {
                    downloadFile(blob, 'payment.pdf', 'application/pdf')
                  })
              } else {
                completedPaymentXlsx(payment, escrowReserves, fees, invoiceColumns)
              }
            }}
            showDownload={true}
          />
        </div>
      )}
    </S_CompletedPayment>
  )
}
