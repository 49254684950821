import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { FeatureNotEnabled } from 'xen/components';
import { useFeatureFlags } from 'xen/hooks';
const InvoiceSettlements = lazy(() => import('../pages/invoice-settlements'));
const InvoiceSettlementEdit = lazy(() => import('../pages/invoice-settlement-edit'));
export const INVOICE_SETTLEMENTS_PATHS = {
    root: '/invoice-settlements',
    edit: '/invoice-settlements/:settlementId/edit',
};
export const InvoiceSettlementsRoutes = () => {
    const { productFactoring: factoringEnabled } = useFeatureFlags();
    return [
        _jsx(Route, { exact: true, path: INVOICE_SETTLEMENTS_PATHS.root, children: factoringEnabled ? _jsx(InvoiceSettlements, {}) : _jsx(FeatureNotEnabled, { feature: "Factoring" }) }, INVOICE_SETTLEMENTS_PATHS.root),
        _jsx(Route, { path: INVOICE_SETTLEMENTS_PATHS.edit, children: factoringEnabled ? _jsx(InvoiceSettlementEdit, {}) : _jsx(FeatureNotEnabled, { feature: "Factoring" }) }, INVOICE_SETTLEMENTS_PATHS.edit),
    ];
};
