import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { FeatureNotEnabled } from 'xen/components';
import { useFeatureFlags } from 'xen/hooks';
const InvoiceScheduleEdit = lazy(() => import('../pages/invoice-schedule-edit'));
const InvoiceScheduleNew = lazy(() => import('../pages/invoice-schedule-new'));
const InvoiceSchedules = lazy(() => import('../pages/invoice-schedules'));
const InvoiceScheduleNotEnabled = () => _jsx(FeatureNotEnabled, { feature: "Invoice Schedules" });
export const INVOICE_SCHEDULES_PATHS = {
    root: '/invoice-schedules',
    new: '/invoice-schedules/new',
    edit: '/invoice-schedules/:scheduleId/edit',
};
export const InvoiceSchedulesRoutes = () => {
    const { xenInvoiceSchedule: isXenInvoiceScheduleFeatureFlagEnabled } = useFeatureFlags();
    /*
     * We will in the future want to restrict this route under config flags but for now
     * we will just check if the feature flag is enabled
     */
    return [
        _jsx(Route, { exact: true, path: INVOICE_SCHEDULES_PATHS.root, children: _jsx(InvoiceSchedules, {}) }, INVOICE_SCHEDULES_PATHS.root),
        _jsx(Route, { exact: true, path: INVOICE_SCHEDULES_PATHS.new, children: isXenInvoiceScheduleFeatureFlagEnabled ? _jsx(InvoiceScheduleNew, {}) : _jsx(InvoiceScheduleNotEnabled, {}) }, INVOICE_SCHEDULES_PATHS.new),
        _jsx(Route, { exact: true, path: INVOICE_SCHEDULES_PATHS.edit, children: isXenInvoiceScheduleFeatureFlagEnabled ? _jsx(InvoiceScheduleEdit, {}) : _jsx(InvoiceScheduleNotEnabled, {}) }, INVOICE_SCHEDULES_PATHS.edit),
    ];
};
