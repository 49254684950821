import React, { useEffect, useState } from 'react'
import { Bar, ResponsiveContainer, CartesianGrid, Cell, Tooltip, XAxis, YAxis } from 'recharts'
import colors from '../../../_constants/colors'
import { axisTickStyles, labelChartStyles } from '../Chart.styles'
import { getCompactCurrencyValue } from '../../../_helpers'
import { S_BarChart } from './BarChart.styles'
import PropTypes from 'prop-types'
import { Skeleton } from '../../Skeleton/Skeleton'

export const BarChart = (props) => {
  const { data, loading, height, nameKey, dataKey, barActiveIndex, toolTipFormatter, labelToolTipFormatter, onClick } =
    props
  const [activeIndex, setActiveIndex] = useState(barActiveIndex)

  useEffect(() => {
    setActiveIndex(barActiveIndex)
  }, [barActiveIndex])

  if (loading && !data.length) {
    return <Skeleton height={`${height}px`} isLoaded={!loading} width={'100%'} />
  }

  return (
    <ResponsiveContainer height={height} width={`100%`}>
      <S_BarChart
        data={data}
        onClick={(e) => {
          if (!onClick) {
            return
          }
          onClick(e)
        }}
      >
        <CartesianGrid horizontal={true} stroke={colors.GREY_LIGHT_FILLS} vertical={true} />
        <XAxis
          axisLine={{ stroke: colors.TEXT_LIGHT }}
          dataKey={nameKey}
          height={15}
          name={'Age'}
          tick={axisTickStyles}
          tickLine={false}
        />
        <YAxis
          axisLine={{ stroke: colors.TEXT_LIGHT }}
          dataKey={'value'}
          tick={axisTickStyles}
          tickFormatter={(dollarAmt) => getCompactCurrencyValue(dollarAmt)}
          tickLine={false}
          width={40}
        />
        <Bar barSize={40} dataKey={dataKey} fill={colors.DEFAULT_SECONDARY}>
          {data.map((entry, index) => (
            <Cell
              cursor="pointer"
              fillOpacity={activeIndex === index ? 1 : activeIndex === -1 ? 1 : 0.5}
              key={`cell-${index}`}
            />
          ))}
        </Bar>
        <Tooltip
          active={true}
          contentStyle={labelChartStyles}
          cursor={{ cursor: 'pointer', fill: 'transparent' }}
          formatter={toolTipFormatter}
          labelFormatter={labelToolTipFormatter}
        />
      </S_BarChart>
    </ResponsiveContainer>
  )
}

BarChart.propTypes = {
  /**
   * The source data which each element is an object.
   */
  data: PropTypes.array,
  /**
   * Prevents render
   */
  loading: PropTypes.bool,
  /**
   * Height for ResponsiveChart and PieChart components
   */
  height: PropTypes.number,
  /**
   * Event handler of click on the sectors
   */
  onClick: PropTypes.func,
  /**
   * Formatter handler for value on hover
   */
  toolTipFormatter: PropTypes.func,
  /**
   * Formatter handler for label on hover
   */
  labelToolTipFormatter: PropTypes.func,
  /**
   * Name of object's key for x-axis
   */
  nameKey: PropTypes.string,
  /**
   * Name of object's key for value
   */
  dataKey: PropTypes.string,
}

BarChart.defaultProps = {
  data: [],
  loading: false,
  barActiveIndex: -1,
  onClick: false,
  nameKey: 'name',
  dataKey: 'value',
  height: 200,
  toolTipFormatter: (value, name) => [`${value}`],
  labelToolTipFormatter: (name) => `${name}`,
}
