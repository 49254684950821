import React from 'react'

// Components
import Table from '../../../_components/Table'
import { createColumn } from '../../../_components/TableColumn'
import { history } from '../../../_helpers/history'

// Constants
import { LENDER_ROUTES } from '../../../_constants/routes'

export const ABLClientsTableColumns = [
  {
    accessor: 'name',
    headingText: 'Name',
    disableSortBy: true,
  },
  createColumn({
    type: 'currency',
    id: 'overall_credit_limit',
    headingText: 'Limit',
    disableSortBy: true,
  }),
  createColumn({
    type: 'currency',
    id: 'balance',
    headingText: 'Balance',
    disableSortBy: true,
  }),
  createColumn({
    type: 'currency',
    id: 'availability',
    headingText: 'Availability',
    disableSortBy: true,
  }),
  createColumn({
    type: 'currency',
    id: 'collateral',
    headingText: 'Collateral',
    disableSortBy: true,
  }),
  createColumn({
    type: 'currency',
    id: 'ineligible',
    headingText: 'Ineligible',
    disableSortBy: true,
  }),
  createColumn({
    type: 'currency',
    id: 'eligible',
    headingText: 'Eligible',
    disableSortBy: true,
  }),
]

export const ABLClientsTable = ({
  clients,
  sorted = [],
  handleSort,
  handleRowClick = undefined,
  pageSize,
  columns = [],
  defaultSort = [{ id: 'name', desc: false, disableNoSort: true }],
  totals = null,
}) => {
  if (!clients || clients.length === 0) {
    return null
  }

  const tableColumnsWithLink = [...(columns.length ? columns : ABLClientsTableColumns)]

  const columnsToUse = handleRowClick === undefined ? tableColumnsWithLink : columns

  return (
    <Table
      className="with-border"
      columns={columnsToUse}
      data={clients}
      defaultPageSize={clients.length}
      defaultSort={defaultSort}
      handleRowClick={({ id }) => history.push(`${LENDER_ROUTES.clients_overview}/${id}`)}
      onSortedChange={handleSort}
      pageSize={pageSize}
      sorted={sorted}
      totals={totals}
    />
  )
}
