import React, { useState } from 'react'
import { FaRegFile } from 'react-icons/fa'

// Components
import { PaginationControls } from '../../../PaginationControls'
import { FileViewer } from '../../../FileViewer'
import { ControlsButton } from '../../../ControlsButton'
import { Button } from '../../../Button'

// Utils
import { useFile } from '../../../../_helpers/useFile'

// Services
import { deleteDocument } from '../../../../_services/documents.service'

// Styles
import { S_GalleryView } from './GalleryView.styles'

export const GalleryView = ({ files, activeFileIndex, setActiveFileIndex, allowDelete, onDelete = () => {} }) => {
  if (!files || !files.length || activeFileIndex >= files.length || activeFileIndex < 0) {
    return null
  }

  const file = files[activeFileIndex]
  const { document_name, id } = file

  const [pdf, img, downloadLink, isLoadingFile] = useFile(id)
  const [isConfirmDelete, setIsConfirmDelete] = useState(false)

  return (
    <S_GalleryView>
      <header className="file-header">
        <div className="left">
          <h3>
            <FaRegFile />
            {document_name}
          </h3>
          {/* File Actions */}
          <div className="file-actions">
            <ControlsButton
              className="download-button"
              disabled={isLoadingFile}
              handleClick={() => setIsConfirmDelete(false)}
              href={downloadLink ? downloadLink.url : null}
              icon="download"
              rel="noopener noreferrer"
              target="_blank"
            />
            {allowDelete && (
              <ControlsButton
                className="delete-button"
                disabled={isLoadingFile}
                handleClick={() => {
                  setIsConfirmDelete(!isConfirmDelete)
                }}
                icon="delete"
              />
            )}
            {/* Show Confirm Button if delete */}
            {isConfirmDelete && (
              <>
                <Button className="compact secondary" onClick={() => setIsConfirmDelete(false)} text="Cancel" />
                <Button
                  className="compact destroy"
                  onClick={() => {
                    deleteDocument(id).then(() => {
                      onDelete(id)
                    })
                  }}
                  text="Confirm"
                />
              </>
            )}
          </div>
        </div>
        {files && files.length > 1 && (
          <PaginationControls
            disabled={isLoadingFile}
            onNextPage={() => {
              setActiveFileIndex(activeFileIndex + 1)
            }}
            onPrevPage={() => {
              setActiveFileIndex(activeFileIndex - 1)
            }}
            pageNum={activeFileIndex + 1}
            pageSize={1}
            totalResults={files.length}
          />
        )}
      </header>
      <div className="file-container">
        <FileViewer downloadLink={downloadLink} img={img} isLoadingFile={isLoadingFile} pdf={pdf} />
      </div>
    </S_GalleryView>
  )
}
