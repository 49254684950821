import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

// Actions
import { modalActions, esigningActions } from '../../../_actions'

// CONSTANTS
import colors from '../../../_constants/colors'

// Components
import { SearchableOverview } from '../../../_components/SearchableOverview'
import EsigningTable from '../../../_views/LenderViews/EsigningTable'

// Services
import { getESigningRequests } from '../../../_services/v2/documents.service'

import { isLender } from '../../../_helpers/user-utils'
import { modalActionHooks } from '../../../_actionHooks'

const EsigningDocumentsListComponent = styled.div`
  display: grid;
  grid-gap: 1em;
  overflow: scroll;

  .documents {
    display: grid;
    grid-gap: 10px;
    overflow: scroll;

    .document {
      display: grid;
      padding: 20px;
      grid-gap: 10px;
      background-color: ${colors.GREY};
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: ${colors.DARK_GREY};
      }

      .document-created-at {
        opacity: 0.5;
      }
    }
  }
`

const EsigningDocumentsList = ({ requestsCount, openEsigningDocument, user, env }) => {
  const isUserLender = isLender(true, user?.user_type)
  const switchModal = modalActionHooks.useSwitchModal()
  return (
    <EsigningDocumentsListComponent>
      <h1 className={`title`}>Sign Documents</h1>
      <p className={`list-length`}>You have {requestsCount} document(s) to sign</p>
      <SearchableOverview
        allowDownload={false}
        searchPlaceholder="Signing Documents"
        showSearchBar={false}
        views={[
          {
            title: 'All documents',
            endpointCall: (searchParams) => {
              return getESigningRequests(searchParams).then((resp) => {
                let signature_requests = resp.signature_requests

                return {
                  total_results: resp.count,
                  results: signature_requests,
                }
              })
            },
            requestMethod: 'GET',
            pageSizes: [5, 10, 20],
            columns: [],
            Component: ({ results, sort, handleSortChange, pageSize, columns }) => (
              <EsigningTable
                documents={results}
                extra={{
                  switchModal,
                  openEsigningDocument,
                  user,
                  env,
                  lastDoc: requestsCount === 1,
                }}
                onSortedChange={handleSortChange}
                pageSize={pageSize}
                sorted={sort}
                tableType={`${isUserLender ? 'lender_pending' : 'borrower_pending'}`}
              />
            ),
          },
        ]}
      />
    </EsigningDocumentsListComponent>
  )
}

const mapStateToProps = (state) => {
  const { esigning, user, env } = state
  return {
    requestsCount: esigning.requestsCount,
    requests: esigning.requests,
    user,
    env: env.env,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    switchModal: (content) => {
      dispatch(modalActions.switchModal(content))
    },
    openEsigningDocument: (requestId, user, env, lastDoc) => {
      dispatch(esigningActions.openEsigningDocument(requestId, user, env, lastDoc))
    },
  }
}

const connectedEsigningDocumentsList = connect(mapStateToProps, mapDispatchToProps)(EsigningDocumentsList)
export { connectedEsigningDocumentsList as EsigningDocumentsList }
