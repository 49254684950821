import styled from 'styled-components'
import colors from 'legacy/_constants/colors'

// Components
import { cssBreakpoints } from '../../../_constants/styles'

export const S_ListHeader = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding-right: 15.5em;
  padding-bottom: 0.5em;
  @media screen and (max-width: ${cssBreakpoints.desktopSm}) {
    padding-right: 9.5em;
  }
  @media screen and (max-width: ${cssBreakpoints.mobileLg}) {
    display: none;
  }
`

export const S_Date = styled.div`
  color: ${colors.TEXT_PRIMARY};
  justify-content: right;
  display: flex;
  margin-right: ${(props) => (props.prospect ? '2em' : '1.5em')};
  @media screen and (max-width: ${cssBreakpoints.mobileLg}) {
    width: 100%;
    display: flex;
    text-align: left;
    margin: 0;
    margin-bottom: 10px;
    border: none;
  }
`
