const adjustmentFields = [
  {
    id: 'positive_adjustments',
    label: 'Positive Adjustment',
    type: 'currency',
    icon: '+',
  },
  {
    id: 'negative_adjustments',
    label: 'Negative Adjustment',
    type: 'currency',
    icon: '-',
  },
  {
    id: 'new_collections',
    label: 'New Collections',
    type: 'currency',
    icon: '-',
  },
  {
    id: 'overpayment',
    label: 'Overpayment',
    type: 'currency',
    icon: '+',
  },
]

export const getArFields = () => {
  return [
    {
      id: 'sales',
      label: 'Sales',
      type: 'currency',
      icon: '+',
    },
    {
      id: 'credits',
      label: 'Credits',
      type: 'currency',
      icon: '-',
    },
    ...adjustmentFields,
  ]
}

export const getEquipmentFields = () => {
  return [
    {
      id: 'new_equipment',
      label: 'New Equipment',
      type: 'currency',
      icon: '+',
    },
    {
      id: 'removed_equipment',
      label: 'Removed Equipment',
      type: 'currency',
      icon: '-',
    },
    ...adjustmentFields,
  ]
}

export const getInventoryFields = () => {
  return [
    {
      id: 'new_inventory',
      label: 'New Inventory',
      type: 'currency',
      icon: '+',
    },
    {
      id: 'removed_inventory',
      label: 'Removed Inventory',
      type: 'currency',
      icon: '-',
    },
    ...adjustmentFields,
  ]
}

// MOCK
export const bbc = {
  id: 1,
  status: 'incomplete',
  collaterals: [
    {
      id: 5,
      sales: 30.0,
      credits: 0,
      adjustments: 80.0,
      new_collections: 60.0,
      overpayment: 120.0,
      advance_rate: 0.9,
      collateral_line: {
        id: 5,
        name: 'Accounts Receivable',
        type: 'AccountReceivablesLine',
      },
      ineligibles: [
        {
          id: 2,
          value: 10.0,
          ineligible: {
            id: 2,
            name: 'Over 90 Days',
          },
        },
        {
          id: 3,
          value: 20.0,
          ineligible: {
            id: 3,
            name: 'Retention',
          },
        },
      ],
    },
    {
      id: 9,
      sales: 0.0,
      credits: 0.0,
      adjustments: 0.0,
      new_collections: 0.0,
      overpayment: 0.0,
      advance_rate: 0.7,
      collateral_line: {
        id: 9,
        name: 'Inventory',
        type: 'InventoryLine',
      },
      ineligibles: [
        {
          id: 8, //<-
          value: 30.0,
          ineligible: {
            id: 8,
            name: 'Cross Aged',
          },
        },
      ],
    },
  ],
}
