/*
    Eventually, Each one of these is a type, and you can have multiple of each
*/
const commonAdjustments = [
  {
    id: 'adjustments_pos',
    label: 'Adjustments',
    deltaType: 'positive',
  },
  {
    id: 'adjustments_neg',
    label: 'Adjustments',
    deltaType: 'negative',
  },
]

export const adjustmentTemplates = {
  'Accounts Receivable': [
    {
      id: 'sales',
      label: 'Sales',
      deltaType: 'positive',
    },
    {
      id: 'credits',
      label: 'Credits',
      deltaType: 'negative',
    },
    ...commonAdjustments,
    {
      id: 'net_collections',
      label: 'New Collections',
      deltaType: 'negative',
    },
    {
      id: 'overpayment',
      label: 'Overpayment',
      deltaType: 'positive',
    },
  ],
  Inventory: [
    {
      id: 'new_inventory',
      label: 'Added Inventory',
      deltaType: 'positive',
    },
    {
      id: 'removed_inventory',
      label: 'Removed Inventory',
      deltaType: 'negative',
    },
  ],
  Equipment: [
    {
      id: 'new_equipment',
      label: 'Added Equipment',
      deltaType: 'positive',
    },
    {
      id: 'removed_equipment',
      label: 'Removed Equipment',
      deltaType: 'negative',
    },
    ...commonAdjustments,
    {
      id: 'reduction',
      label: 'Reduction',
      deltaType: 'negative',
    },
  ],
  Property: [],
  'Letter of Credit': [],
  'Equity Line': [],
  'Term Loan': [],
}
