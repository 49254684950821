import { jsx as _jsx } from "react/jsx-runtime";
import { Button, modals } from 'xen-ui';
import { useDeletePayment } from 'xen/_modules/invoice-settlements/api';
export const DeletePaymentButton = ({ children = 'Delete Payment', paymentId, ...props }) => {
    const { mutate: deletePayment, isPending: isPaymentDeletePending, isError: isPaymentDeleteError } = useDeletePayment();
    const handleDeletePayment = () => {
        deletePayment(String(paymentId));
        if (isPaymentDeleteError)
            console.error('Error deleting payment');
    };
    const confirmDeletePayment = () => {
        modals.openConfirmModal({
            title: 'Confirm',
            children: 'Are you sure you want to delete this payment and its assigned invoices? This action cannot be undone.',
            confirmProps: { color: 'red' },
            labels: { cancel: 'Cancel', confirm: 'Delete Payment' },
            onConfirm: handleDeletePayment,
        });
    };
    return (_jsx(Button, { loading: isPaymentDeletePending, onClick: confirmDeletePayment, ...props, children: children }));
};
