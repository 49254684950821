import React, { useState, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import { MdKeyboardArrowDown } from 'react-icons/md'
import colors from '../../_constants/colors'
import { darken } from 'polished'

export const S_SplitButton = styled.div`
  .split-button {
    border: none;
    display: inline-block;
    margin-top: 20px;
    position: relative;
    text-align: center;
  }

  .btn-container {
    text-align: center;
  }

  .btn {
    position: relative;
    margin: 0;
    float: left;
    outline: none;
    line-height: 30px;
    background-color: ${(props) => props.customColor || colors.DEFAULT_SECONDARY};
    color: white;
    font-weight: bold;
    outline: none;
    transition: all 0.3s ease-in-out;
    letter-spacing: 0.3px;

    &:disabled {
      opacity: 0.5 !important;
      pointer-events: none !important;
    }
  }

  .btn:hover {
    cursor: pointer;
    background-color: ${(props) => darken(0.15, props.customColor || colors.DEFAULT_SECONDARY)};
  }
  .btn-main {
    font-size: 14px;
    border-radius: 4px 0 0 4px;
    min-width: 150px;
    /**Ran into some strang chrome specific issues so leaving this as rather verbose */
    border-bottom-color: rgba(0, 0, 0, 0.1);
    border-bottom-style: solid;
    border-left-color: rgba(0, 0, 0, 0.1);
    border-left-style: solid;
    border-top-color: rgba(0, 0, 0, 0.1);
    border-top-style: solid;
    border-right-color: rgba(0, 0, 0, 0.1);
    border-right-style: solid;
  }
  .btn-dropdown {
    border-left: 0;
    border-radius: 0 4px 4px 0;
    border-bottom-color: rgba(0, 0, 0, 0.1);
    border-bottom-style: solid;
    border-left-style: none;
    border-top-color: rgba(0, 0, 0, 0.1);
    border-top-style: solid;
    border-right-color: rgba(0, 0, 0, 0.1);
    border-right-style: solid;
  }
  .dropdown-open {
    border-bottom-right-radius: 0px;
  }
  .open {
    display: block;
  }
  .dropdown-menu {
    position: absolute;
    width: auto;
    top: 30px;
    right: 0;
    z-index: 1000;
    float: left;
    border-radius: 0 0 4px 4px;
    padding: 5px 0;
    margin: 3px 0 0;
    font-size: 14px;
    border: rgb(216, 216, 216) solid 1px;
    border-top: 0;
    list-style: none;
    overflow: hidden;
    white-space: nowrap;
    background-color: white;
  }
  .dropdown-menu li div {
    display: block;
    padding: 3px 20px;
    clear: both;
    text-align: center;
  }
  .dropdown-menu li div:hover {
    background: #d3d3d3;
    cursor: pointer;
  }
  .close {
    display: none;
  }

  ${(props) => props.customCss}
`
// This is the Component
export const SplitButton = ({ mainButton, dropdownButtons, disabled, customCss, mainButtonWidth }) => {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef(null)
  const dropdownButtonsList = dropdownButtons.map((dropdownButton, index) => {
    return (
      <li
        key={index}
        onClick={(e) => {
          dropdownButton.function(e)
          setIsOpen(false)
        }}
      >
        <div>{dropdownButton.label}</div>
      </li>
    )
  })

  const handleOutsideClick = (e) => {
    if (ref.current && ref.current.contains(e.target)) {
      // If clicking inside the dropdown list, do nothing, the onClickHandler will hide when appropriate
      return
    }

    // If clicking outside, hide
    setIsOpen(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  })

  return (
    <S_SplitButton customCss={customCss} mainButtonWidth={mainButtonWidth}>
      <div className="btn-container">
        <div className="split-button">
          <button className="btn btn-main" disabled={disabled} onClick={mainButton.function} type="button">
            {mainButton.label}
          </button>
          <button
            className={`btn btn-dropdown ${isOpen ? 'dropdown-open' : ''}`}
            onClick={() => {
              setIsOpen(!isOpen)
            }}
            type="button"
          >
            <MdKeyboardArrowDown />
          </button>
          <ul className={isOpen ? 'dropdown-menu open' : 'close'} ref={ref}>
            {dropdownButtonsList}
          </ul>
        </div>
      </div>
    </S_SplitButton>
  )
}

SplitButton.propTypes = {
  customCss: PropTypes.any,
}

SplitButton.defaultProps = {
  customCss: css``,
}
