import styled from 'styled-components'

// Constants
import { S_FormInput } from '../Forms/FormInput/FormInput.styles'

export const S_ListSearchableOverview = styled.section``

export const S_SearchWrapper = styled.div`
  display: flex;
  ${S_FormInput} {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }

  .filter-btn {
    margin-left: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
`
