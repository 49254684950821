import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// We want to replace this when we handle login work for Xen 1.0
import { useSignOut } from 'legacy/_reduxHooks/authentication.hook';
import { useMediaQuery } from 'xen-ui';
import { useGetNavigation } from 'xen/api/use-navigation';
import { useAuthentication } from 'xen/hooks';
import { useAppShellNavBarContext, useConfigurationContext, useXenTheme } from 'xen/providers';
import { NOTIFICATIONS_PATHS, USERS_PATHS } from 'xen/routes';
import { NavItem } from '../../link';
import { AppLayoutDivider } from '../divider';
export const ProspectNavBarFooter = () => {
    const { signOut } = useSignOut();
    const { sessionToken } = useAuthentication();
    const { data: navigation, isLoading: isNavigationLoading, isSuccess: isNavigationSuccess } = useGetNavigation();
    const theme = useXenTheme();
    const isMobileXS = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
    const { toggleNavBarState } = useAppShellNavBarContext();
    const configuration = useConfigurationContext();
    const toggleNavForMobile = () => {
        if (isMobileXS) {
            toggleNavBarState();
        }
    };
    if (!isNavigationSuccess) {
        return _jsx("span", { children: 'Could not load navigation data' });
    }
    const { notifications } = navigation;
    if (isNavigationLoading)
        return null;
    const handleSignOut = () => {
        signOut(sessionToken);
    };
    return (_jsxs(_Fragment, { children: [_jsx(AppLayoutDivider, {}), notifications && (_jsx(NavItem.Link, { icon: "Bell", label: "Notifications", onClick: toggleNavForMobile, to: NOTIFICATIONS_PATHS.root })), _jsx(NavItem.Link, { icon: "UserCircle", label: "Profile", onClick: toggleNavForMobile, to: USERS_PATHS.profile }), configuration.tenant.branding.urls.homepage && (_jsx(NavItem.LinkExternal, { href: configuration.tenant.branding.urls.homepage, icon: "ExternalLink", label: "Contact us" })), _jsx(NavItem.Button, { icon: "Logout", label: "Sign out", onClick: handleSignOut })] }));
};
