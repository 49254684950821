import { jsx as _jsx } from "react/jsx-runtime";
import LegacyInvoices from 'legacy/_views/common/lender/InvoicesOverview';
import { Page } from 'xen/components';
const Invoices = () => {
    {
        /*
          Should eventually be replaced by new Xen 1.0 Invoices page
          Setting this to all invoices for now, the filter allows to filter
          to the invoice state "Purchased" but that's all that's available
        */
    }
    return (_jsx(Page, { pt: "0", children: _jsx(LegacyInvoices, { match: { path: 'invoices/all' } }) }));
};
export default Invoices;
