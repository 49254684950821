import request from '../axios_service'
import { fundingRequestDatesToUTC, fundingRequestDatesToSystemTime } from '../../_helpers/abl/funding_requests'

// Utilities
import { adjustmentTemplates } from '../../_helpers/abl/adjustments'
import { receiptDatesToSystemTime, receiptDatesToUTC } from '../../_helpers/abl/cash-receipts'
import { collateralLineDatesToSystemTime, collateralLineOperationDatesToSystemTime } from '../../_helpers/bbcs'
import { addSummaryToBBC } from '../../BorrowingBaseCertificate/BSS.utils'

export const getConfigForBBC = (bbcId) => {
  return request({
    url: `/ui_configs/entities/bbc/${bbcId}`,
    method: 'GET',
  }).then(
    (resp) => {
      return resp.config
    },
    (error) => {
      // Make a new config setting if one doesn't exist
      if (error === 'ui config does not exist') {
        return request({
          url: `/ui_configs/entities/bbc/${bbcId}`,
          method: 'PUT',
          data: {
            files: [],
          },
        }).then((resp) => {
          return resp.config
        })
      }
    }
  )
}

export const getConfigForAsset = (assetId, sectionType, ratesForSection) => {
  return request({
    url: `/ui_configs/entities/asset/${assetId}`,
    method: 'GET',
  }).then(
    (resp) => {
      // const assetAdjustments = adjustmentTemplates[sectionType]
      // request({
      //     url: `/ui_configs/entities/asset/${assetId}`,
      //     method: 'PUT',
      //     data: {
      //         adjustments: assetAdjustments,
      //         files: [],
      //         advanceRates: ratesForSection,
      //     },
      // })
      return {
        id: assetId,
        config: resp.config,
      }
    },
    (error) => {
      // Make a new config if one doesn't exist
      if (error === 'ui config does not exist') {
        const assetAdjustments = adjustmentTemplates[sectionType]
        return request({
          url: `/ui_configs/entities/asset/${assetId}`,
          method: 'PUT',
          data: {
            adjustments: assetAdjustments,
            files: [],
            advanceRates: ratesForSection,
          },
        }).then((resp) => {
          return {
            id: assetId,
            config: resp.config,
          }
        })
      }
    }
  )
}

export const getConfigForAssets = (sections, inventoryRates) => {
  // Map each asset to the call to get its config
  const assetDocuments = sections.map(({ assetId, section_type }) => {
    const ratesForSection = inventoryRates.filter((inventoryRate) => {
      return inventoryRate.assetId === assetId
    })
    return getConfigForAsset(assetId, section_type, ratesForSection)
  })
  return Promise.all(assetDocuments).then((values) => {
    return values
  })
}

export const addDocumentsToEntity = async (documents, entity, entityType) => {
  // For each file, add the document to the system, then update the UI config
  const entityId = entity.id
  const currentDocuments = entity.documents ? entity.documents : []
  const newDocuments = [...currentDocuments]
  for (let i = 0; i < documents.length; i++) {
    const document = documents[i]
    // Create a placeholder for the new doc
    await request({
      url: `/documents`,
      method: 'POST',
      data: {
        document: {
          document_name: document.name,
        },
      },
    }).then((resp) => {
      // Upload the new doc
      const documentId = resp.id
      const data = new FormData()
      data.append(`filename`, document.name)
      data.append(`filedata`, document)
      data.append(`deal_id`, 1)
      return request({
        url: `/documents/upload/${documentId}`,
        method: 'POST',
        data: data,
      })
        .then(() => {
          // Get the data for the new doc
          return request({
            url: `/documents/${documentId}`,
            method: 'GET',
          })
        })
        .then((resp) => {
          // Add new doc to local list
          newDocuments.push(resp)
        })
    })
  }
  return updateConfig(entityId, entityType, 'files', newDocuments).then((resp) => {
    return resp.config.files
  })
}

// Need to get the asset config, then overwrite just the value of the specific adjustment
export const saveAdjustmentValue = (itemId, newValue, currentBBC) => {
  // Only split off the first underscore to get asset id
  const [assetId, adjustmentId, rest] = itemId.split(/_(.+)/)
  const newAdjustments = currentBBC.adjustments
    .filter((adjustment) => {
      return adjustment.assetId == assetId
    })
    .map((adjustment) => {
      // Have to reset the adjustment id here
      const [assetId, subAdjustmentId, rest] = adjustment.id.split(/_(.+)/)
      const newAdjustment = {
        ...adjustment,
        id: subAdjustmentId,
      }
      if (newAdjustment.id === adjustmentId) {
        newAdjustment.value = newValue
      }
      return newAdjustment
    })
  return updateConfig(assetId, 'asset', 'adjustments', newAdjustments)
}

export const updateConfig = (assetId, entityType, fieldKey, fieldValue) => {
  return request({
    url: `/ui_configs/entities/${entityType}/${assetId}`,
    method: 'GET',
  }).then((resp) => {
    const config = resp.config
    const newConfig = { ...config }
    newConfig[fieldKey] = fieldValue
    return request({
      url: `/ui_configs/entities/${entityType}/${assetId}`,
      method: 'PUT',
      data: newConfig,
    })
  })
}

export const archiveTemplate = (bbcTemplateId) => {
  return request({
    url: `/operate/bbc_form_template/sections/${bbcTemplateId}`,
    method: 'DELETE',
  })
}

export const setConfigForClientTemplate = (templateId, inventoryRates) => {
  return request({
    url: `/ui_configs/entities/bbcTemplate/${templateId}`,
    method: 'PUT',
    data: {
      inventoryRates: inventoryRates,
    },
  })
}

export const getConfigForClientTemplate = (templateId, inventoryRates) => {
  return request({
    url: `/ui_configs/entities/bbcTemplate/${templateId}`,
    method: 'GET',
  }).then(
    (resp) => {
      return resp.config
    },
    () => {
      return null
    }
  )
}

// Need to get the asset config, then overwrite just the value of the specific adjustment
export const saveAdvanceValue = (itemId, newValue, currentBBC, assetId) => {
  // Only split off the first underscore to get asset id
  // const [assetId, adjustmentId, rest] = itemId.split(/_(.+)/)

  const newAdjustments = currentBBC.inventoryRates.filter((advanceRate) => {
    return advanceRate.assetId == assetId
  })
  // .map(advanceRate => {
  //     // Have to reset the adjustment id here
  //     const [assetId, subAdjustmentId, rest] = adjustment.id.split(/_(.+)/)
  //     const newAdjustment = {
  //         ...adjustment,
  //         id: subAdjustmentId,
  //     }
  //     if (newAdjustment.id === adjustmentId) {
  //         newAdjustment.value = newValue
  //     }
  //     return newAdjustment
  // })
  return updateConfig(assetId, 'asset', 'advanceRates', newAdjustments)
}

export const getCollateralLines = (businessId, params = '') => {
  return request({
    url: `/abl/collateral_lines/?business_id=${businessId}&${params.toString()}`,
    method: 'GET',
  }).then((lines) => {
    lines = lines.map(collateralLineDatesToSystemTime)
    return lines
  })
}

export const removeCollateralLine = (lineId) => {
  return request({
    url: `/abl/collateral_lines/${lineId}`,
    method: 'DELETE',
  })
}

export const getCollateralLine = (lineId) => {
  return request({
    url: `/abl/collateral_lines/${lineId}`,
    method: 'GET',
  }).then(collateralLineDatesToSystemTime)
}

export const getCollateralLineOperations = (lineId, params = '') => {
  return request(
    {
      url: `/abl/collateral_lines/${lineId}/collateral_line_operations?${params.toString()}`,
      method: 'GET',
    },
    null,
    true
  ).then((operations) => {
    operations.data = operations.data.map(collateralLineOperationDatesToSystemTime)
    return operations
  })
}

export const collateralLinePerformOperation = (lineId, data) => {
  return request({
    url: `/abl/collateral_lines/${lineId}/collateral_line_operations`,
    method: 'POST',
    data: data,
  }).then(collateralLineDatesToSystemTime)
}

export const collateralLinePerformExternalOperation = (lineId, data) => {
  return request({
    url: `/abl/collateral_lines/${lineId}/perform_external_operation`,
    method: 'PUT',
    data: data,
  }).then(collateralLineDatesToSystemTime)
}

export const updateCollateralLine = (lineId, data) => {
  return request({
    url: `/abl/collateral_lines/${lineId}`,
    method: 'PUT',
    data: data,
  }).then(collateralLineDatesToSystemTime)
}

export const createCollateralLine = (data) => {
  return request({
    url: `/abl/collateral_lines/`,
    method: 'POST',
    data: data,
  }).then(collateralLineDatesToSystemTime)
}

export const getABLIneligibles = (searchParams) => {
  return request(
    {
      url: `/abl/ineligibles/?${searchParams.toString()}`,
      method: 'GET',
    },
    null,
    true
  )
}

export const createABLIneligible = (ineligible) => {
  return request({
    url: `/abl/ineligibles`,
    method: 'POST',
    data: ineligible,
  })
}

export const updateABLIneligible = (id, ineligible) => {
  return request({
    url: `/abl/ineligibles/${id}`,
    method: 'PUT',
    data: ineligible,
  })
}

export const deleteABLIneligible = (id) => {
  return request({
    url: `/abl/ineligibles/${id}`,
    method: 'DELETE',
  })
}

export const getBbc = (bbcId) => {
  return request({
    url: `/abl/client/bbc/${bbcId}`,
    method: 'GET',
  }).then((bbc) => {
    return addSummaryToBBC(bbc)
  })
}

export const submitBBC = (bbcId) => {
  return updateBbc(bbcId, { status: 'pending' })
}

export const updateBbc = (bbcId, attribute) => {
  return request({
    url: `/abl/client/bbc/${bbcId}`,
    method: 'PUT',
    data: attribute,
  })
}

export const createBbc = (businessId) => {
  return request(
    {
      url: `/abl/client/bbc`,
      method: 'POST',
      data: { business_id: businessId },
    },
    null,
    false,
    false
  )
}

export const getBBCs = (params) => {
  return request(
    {
      url: `/abl/client/bbc?${params.toString()}`,
      method: 'GET',
    },
    null,
    true
  ).then((response) => {
    response['data'] = response['data'].map((bbc) => addSummaryToBBC(bbc))
    return response
  })
}

export const getBbcCollateralById = (collateralId) => {
  return request({
    url: `/abl/client/bbc_collaterals/${collateralId}`,
    method: 'GET',
  })
}

export const updateBbcCollaterals = (collateralId, attribute) => {
  return request({
    url: `/abl/client/bbc_collaterals/${collateralId}`,
    method: 'PUT',
    data: attribute,
  })
}

export const updateBbcIneligibles = (ineligibleId, attribute) => {
  return request({
    url: `/abl/client/bbc_ineligibles/${ineligibleId}`,
    method: 'PUT',
    data: attribute,
  })
}

export const createCashReceipt = (cashReceipt) => {
  return request({
    url: `/abl/client/cash_receipts`,
    method: 'POST',
    data: receiptDatesToUTC(cashReceipt),
  })
}

export const getCashReceipt = (id) => {
  return request({
    url: `/abl/client/cash_receipts/${id}`,
    method: 'GET',
    data: null,
  }).then(receiptDatesToSystemTime)
}

export const getCashReceipts = (params) => {
  return request(
    {
      url: `/abl/client/cash_receipts?${params.toString()}`,
      method: 'GET',
      data: null,
    },
    null,
    true
  ).then((res) => {
    res.data = res.data.map(receiptDatesToSystemTime)
    return res
  })
}

export const getCashReceiptsBySource = (params) => {
  return request({
    url: `/abl/client/cash_receipts/by_source?${params.toString()}`,
    method: 'GET',
    data: null,
  })
}

export const getCashReceiptsByLine = (params) => {
  return request({
    url: `/abl/client/cash_receipts/by_line?${params.toString()}`,
    method: 'GET',
    data: null,
  })
}

export const getCashReceiptsLastTwelveMonths = (params) => {
  return request({
    url: `/abl/client/cash_receipts/last_twelve_months?${params.toString()}`,
    method: 'GET',
    data: null,
  })
}

export const getABLBusinessBalances = (businessId) => {
  return request({
    url: `/abl/client/businesses/${businessId}/balances`,
    method: 'GET',
  })
}

export const getABLBorrowerBBcs = (params = false) => {
  return request({
    url: `/abl/client/bbc${params ? `?${params}` : ''}`,
    method: 'GET',
  })
}

export const createAblFundingRequest = (funding_request) => {
  return request({
    url: `/abl/funding_requests`,
    method: 'POST',
    data: fundingRequestDatesToUTC(funding_request),
  })
}

export const getAblFundingRequest = (funding_request_id) => {
  return request({
    url: `/abl/funding_requests/${funding_request_id}`,
    method: 'GET',
  }).then(fundingRequestDatesToSystemTime)
}

export const updateAblFundingRequest = (funding_request_id, data) => {
  return request(
    {
      url: `/abl/funding_requests/${funding_request_id}`,
      method: 'PUT',
      data: fundingRequestDatesToUTC(data),
    },
    null,
    false,
    false
  )
}

export const getAblFundingRequests = (params) => {
  return request(
    {
      url: `/abl/funding_requests?${params.toString()}`,
      method: 'GET',
    },
    null,
    true
  ).then((resp) => {
    let fundingRequests = resp.data.map(fundingRequestDatesToSystemTime)
    return {
      ...resp,
      data: fundingRequests,
    }
  })
}

export const uploadAblDocuments = (record_type, record_id, files) => {
  const formData = new FormData()
  formData.set('record_type', record_type)
  formData.set('record_id', record_id)
  for (let file of files) {
    formData.append('file[]', file)
  }
  return request({
    url: `/abl/client/documents`,
    method: 'POST',
    data: formData,
  })
}

export const getAblDocuments = (record_type, record_id, searchParams) => {
  return request(
    {
      url: `/abl/client/documents/${record_type}/${record_id}?${searchParams.toString()}`,
      method: 'GET',
    },
    null,
    true
  )
}

export const deleteAblDocument = (record_type, record_id, documentId) => {
  return request({
    url: `/abl/client/documents/${record_type}/${record_id}/${documentId}`,
    method: 'DELETE',
  })
}

export const getCashReceiptsPostBBC = (businessId) => {
  return request({
    url: `/abl/client/cash_receipts/post_bbc_amount?business_id=${businessId}`,
    method: 'GET',
  })
}

export const getABLBusinessActivity = (businessId = null) => {
  const url = businessId ? `/abl/client/businesses/${businessId}/activities` : `/abl/client/businesses/activities`
  return request({
    url: url,
    method: 'GET',
  }).then((resp) => {
    let fundingRequests = resp.map(fundingRequestDatesToSystemTime)
    return fundingRequests
  })
}

export const getABLCashInOut = (businessId) => {
  return request({
    url: `/abl/client/businesses/${businessId}/cash_in_out`,
    method: 'GET',
  })
}

export const getLastPostedBBC = (businessId) => {
  return request({
    url: `/abl/client/businesses/${businessId}/last_posted`,
    method: 'GET',
  })
}

export const uploadBbcInvoices = (bbcId, files) => {
  const formData = new FormData()
  for (let file of files) {
    const fileExtension = file.name.split('.')[1]
    const fileParameterName = fileExtension === 'xlsx' ? 'xlsx[]' : 'csv[]'
    formData.append(fileParameterName, file)
  }
  return request({
    url: `/abl/bbc/${bbcId}/invoices`,
    method: 'POST',
    data: formData,
  })
}

export const getBBCInvoices = (bbcId) => {
  return request({
    url: `/abl/bbc/${bbcId}/invoices`,
    method: 'GET',
  })
}
