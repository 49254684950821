import { useState, useEffect } from 'react'
import { getDebtorPayments, getDebtorPaymentsCount, getDebtorPayment } from '../_services/lender.service'
export const useDebtorPayments = (defaultFilters = '') => {
  const [filters, setFilters] = useState(defaultFilters)
  const [payments, setPayments] = useState([])
  const [totalResults, setTotalResults] = useState(0)
  const [loading, setLoading] = useState(false)

  const refresh = () => {
    setLoading(true)
    getDebtorPayments(filters).then((response) => {
      setPayments(response)
      //setTotalResults(response.headers["Total-Count"])
      setLoading(false)
    })
    getDebtorPaymentsCount(filters).then((response) => {
      setTotalResults(response.count)
    })
  }
  useEffect(refresh, [filters])
  return {
    payments,
    totalResults,
    loading,
    //Can be either URLSearchParams or string
    setFilters: (newFilters) => {
      if (newFilters instanceof URLSearchParams) {
        setFilters(newFilters.toString())
      } else {
        setFilters(newFilters)
      }
    },
    currentFilters: filters,
    refresh,
  }
}

export const useDebtorPayment = (debtorPaymentId) => {
  const [payment, setPayment] = useState({})
  const [escrowReserves, setEscrowReserves] = useState(0)
  const [fees, setFees] = useState(0)
  const [loading, setLoading] = useState(true)
  const [nonFactored, setNonFactored] = useState(0)

  const calcTotals = (invoices) => {
    let totalEscrow = 0
    let totalFees = 0
    let totalNonFactored = 0

    if (invoices && invoices.length) {
      invoices.forEach((invoice) => {
        const { escrow_reserve, fees_earned, amount, is_non_factored } = invoice
        totalEscrow += +escrow_reserve
        totalFees += +fees_earned
        if (is_non_factored) {
          totalNonFactored += +amount
        }
      })
    }

    return {
      totalEscrow: totalEscrow,
      totalFees: totalFees,
      totalNonFactored: totalNonFactored,
    }
  }

  const refresh = () => {
    setLoading(true)
    getDebtorPayment(debtorPaymentId).then((response) => {
      setPayment(response)
      const { totalEscrow, totalFees, totalNonFactored } = calcTotals(response?.invoices)
      setEscrowReserves(totalEscrow)
      setFees(totalFees)
      setNonFactored(totalNonFactored)
      setLoading(false)
    })
  }
  useEffect(refresh, [debtorPaymentId])
  return {
    payment,
    escrowReserves,
    fees,
    loading,
    refresh,
    nonFactored,
  }
}
