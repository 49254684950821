import React from 'react'
import { useTable, useSortBy, useRowState, useExpanded } from 'react-table'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import colors from '../../_constants/colors'

// Table Constants
const TABLE_CONSTS = {
  defaultPageSize: 10,
}

const PDFTable = ({
  data = [],
  columns = [],
  pageSize = TABLE_CONSTS.defaultPageSize,
  onSortedChange,
  onFilesAccepted = async () => {},
  getTrProps = () => {},
  handleRowClick = null,
  updateResults = () => {},
  defaultSort = null, // should have props id and desc
  keyProp = 'id',
  resizable,
  externalSort = null,
  mobileScroll = false,
  expandInitials = {},
  totals = null,
  borderLess = false,
  className = '',
  hideHeaders = false,
  getSubRows = (row) => row.subRows || [],
  ...rest
}) => {
  let memoData = totals ? [...data, totals] : JSON.parse(JSON.stringify(data))

  const styles = StyleSheet.create({
    table: {
      display: 'table',
      width: 'auto',
    },
    tableRow: {
      margin: 'auto',
      flexDirection: 'row',
    },
    tableCol: {
      width: `${Math.floor((1 / columns.length) * 100)}%`,
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      borderRightWidth: 0,
      borderColor: colors.TEXT_LIGHT,
    },
    tableCell: {
      marginLeft: 5,
      marginRight: 5,
      marginTop: 5,
      marginBottom: 2,
    },
    th: {
      marginLeft: 5,
      marginTop: 5,
      marginBottom: 2,
      marginRight: 5,
    },
    tableHeaderRow: {
      width: `${Math.floor((1 / columns.length) * 100)}%`,
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderTopWidth: 0,
      color: colors.TEXT_LIGHT,
      borderColor: colors.TEXT_LIGHT,
    },
  })

  const column = {
    Header: (props) => {
      const { headingText = '', textAlignment = 'left', canSort } = props.column
      return <Text style={[styles.th, { textAlign: textAlignment }]}>{headingText}</Text>
    },
    Cell: (props) => {
      return <Text>{props.value}</Text>
    },
    minWidth: 50,
    width: 125,
    maxWidth: 400,
  }

  columns
    .filter((column) => column.evaluate)
    .forEach((column) => {
      for (let index = 0; index < memoData.length; index++) {
        memoData[index][column.id] = column.evaluate(memoData[index])
      }
    })

  let memoColumns = columns

  const tableOptions = [
    {
      columns: memoColumns,
      data: memoData,
      defaultColumn: column,
      disableMultiSort: true,
      manualSortBy: true,
      getSubRows: getSubRows,
      stateReducer: (newState, action, prevState) => {
        return newState
      },
      initialState: {
        sortBy: defaultSort ? defaultSort : [],
        expanded: expandInitials,
      },
    },
    useRowState,
    useSortBy,
    useExpanded,
  ]

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, ...restTab } = useTable(
    ...tableOptions
  )

  return (
    //define min rows to keep the min number of rows from being the same as the page size
    <View style={styles.table} {...getTableProps()}>
      {!hideHeaders &&
        headerGroups.map((headerGroup) => (
          //Fixed true so that we re-render the table columns after a page break.
          <View style={styles.tableRow} {...headerGroup.getHeaderGroupProps()} fixed={true}>
            {headerGroup.headers.map((column) => (
              <View {...column.getHeaderProps()} style={styles.tableHeaderRow}>
                {column.render('Header')}
              </View>
            ))}
          </View>
        ))}

      {rows.map((row, rowIndex) => {
        prepareRow(row)
        const subRows = row.subRows || []
        return (
          <View wrap={false}>
            <View
              key={
                typeof keyProp === 'function'
                  ? keyProp(memoData[row.index])
                  : memoData[row.index]
                    ? `${memoData[row.index][keyProp]}${rowIndex}${row.depth}`
                    : `${rowIndex}${row.depth}`
              }
              rowIndex={rowIndex}
              style={styles.tableRow}
            >
              {row.cells.map((cell, cellIndex) => {
                return (
                  <View
                    key={cellIndex}
                    style={[
                      styles.tableCol,
                      rowIndex == rows.length - 1 || subRows.length ? { borderBottomWidth: 0 } : {},
                    ]}
                  >
                    <Text style={[styles.tableCell, cell.column.pdfFormat]}>
                      {/**
                       * Manual rendering of formatted value here because react table doesn't seem to get along with reactpdf
                       */}
                      {cell.column.formatter?.(cell.value) ?? cell.value}
                    </Text>
                  </View>
                )
              })}
            </View>
            {subRows.map((subRow, i) => {
              prepareRow(subRow)
              return (
                <View
                  key={
                    typeof keyProp === 'function'
                      ? keyProp(memoData[subRow.index])
                      : memoData[subRow.index]
                        ? `${memoData[subRow.index][keyProp]}${rowIndex}${subRow.depth}`
                        : `${rowIndex}${subRow.depth}`
                  }
                  rowIndex={rowIndex}
                  style={styles.tableRow}
                >
                  {subRow.cells.map((cell, cellIndex) => {
                    const allowEmpty = cell.column.allowEmpty
                    let cellValue = cell.value
                    if (allowEmpty && !cellValue) {
                      cellValue = ''
                    } else {
                      cellValue = cellIndex == 0 ? null : cell.column.formatter?.(cell.value) ?? cell.value
                    }
                    return (
                      <View
                        key={cellIndex}
                        style={[
                          styles.tableCol,
                          rowIndex == rows.length - 1 || i != subRows.length - 1 ? { borderBottomWidth: 0 } : {},
                        ]}
                      >
                        <Text style={[styles.tableCell, cell.column.pdfFormat]}>
                          {/**
                           * Manual rendering of formatted value here because react table doesn't seem to get along with reactpdf
                           */}
                          {cellValue}
                        </Text>
                      </View>
                    )
                  })}
                </View>
              )
            })}
          </View>
        )
      })}
    </View>
  )
}

export { PDFTable }
