import { useState, useEffect } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import * as PdfJs from 'pdfjs-dist'
import * as PdfJsWorker from 'pdfjs-dist/build/pdf.worker.entry'
PdfJs.GlobalWorkerOptions.workerSrc = PdfJsWorker

// Services
import { getDocument } from '../_services/lender.service'
import { getDocumentLink, getBase64Document } from '../_services/documents.service'

// Utils
import { logError } from './errors'

/**
 * Wrapper function to get file links and data
 * @param {number, string} value
 */
export const useFile = (fileId) => {
  const [pdf, setPDF] = useState(null)
  const [img, setImg] = useState(null)
  const [downloadLink, setDownloadLink] = useState(null)
  const [isLoadingFile, setisLoadingFile] = useState(null)

  useEffect(() => {
    if (fileId === undefined || fileId === null) {
      return
    }
    // Reset before getting the next file
    unstable_batchedUpdates(() => {
      setisLoadingFile(true)
      setDownloadLink(null)
      setImg(null)
      setPDF(null)
    })
    let documentPromise = getBase64Document(fileId)
    let downloadPromise = getDocumentLink(fileId)
    getDocument(fileId).then(async (resp) => {
      let documentLink = null
      let downloadLink = null
      try {
        downloadLink = await downloadPromise
      } catch (e) {
        console.error('Error Retrieving Download Link', e)
      }

      try {
        //throws on unpreviewable file type
        documentLink = await documentPromise
        // Will always be either a pdf or an image
        if (documentLink.includes('application/pdf')) {
          let jspdf = await PdfJs.getDocument(documentLink).promise
          setPDF(jspdf)
        } else {
          setImg(documentLink)
        }
      } catch (e) {
        console.error('Error Retrieving Document', e)
      }

      unstable_batchedUpdates(() => {
        if (!resp.document_name) {
          resp.document_name = 'No Document Name'
        }
        setDownloadLink(downloadLink)
        setisLoadingFile(false)
      })
    }, logError)
  }, [fileId])

  return [pdf, img, downloadLink, isLoadingFile]
}
