import styled from 'styled-components'
import { colors } from '../_constants/colors'
import { cssBreakpoints } from '../_constants/styles'

const tileSpacing = 15
export const tileBorder = `1px solid ${colors.BORDER_GREY}`

export const S_LenderOverviewTile = styled.div`
  &:not(&.grid-only) {
    .no-click,
    a {
      display: block;
      border: ${tileBorder};
      padding: 15px;
      border-radius: 3px;
      min-width: 150px;
      text-align: center;

      &:not(._no-hover) {
        &:hover {
          background: #f6f6f6;
        }
      }

      &.large {
        min-height: 280px;
        text-align: center;

        &.no-flex {
          height: 100%;
        }

        &:not(.no-flex) {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
        }

        &.row-layout {
          flex-direction: row;
        }
      }
    }
  }

  .no-click {
    height: 100%;
    &:hover {
      background: #00000000;
    }
  }

  .one-third {
    display: block;
    border: ${tileBorder};
    padding: 15px;
    border-radius: 3px;
    min-width: 150px;
    text-align: center;
    &:hover {
      background: #f6f6f6;
    }

    &.large {
      min-height: 280px;
      text-align: center;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }
  .tile-label {
    font-size: 18px;
    color: ${colors.TEXT_LIGHT};
    margin-top: 8px;
    font-weight: 300;
    letter-spacing: 0.3px;
  }

  .tile-value-wrapper {
    position: relative;

    & + .tile-value-wrapper {
      &:before {
        max-width: 95px;
        width: 90%;
        background: ${colors.BORDER_GREY};
        height: 1px;
        position: absolute;
        content: '';
        bottom: 125%;
        margin: 0 auto;
        left: 0;
        right: 0;
      }
    }
  }

  .row-layout {
    .tile-value-wrapper {
      &:before {
        display: none;
      }
    }
  }

  .tile-value {
    min-height: 41px;
    font-size: 38px;
    color: ${colors.TEXT_DARK};
    font-weight: 600;
  }

  .tile-link {
    font-size: 14px;
    color: #666;
  }

  .sub-line {
    min-width: 170px;
    position: absolute;
    hr {
      position: relative;
      height: 3px;
      border-width: 0;
      color: #ccc;
      background-color: #ccc;
      left: -4px;
    }
  }

  @media (min-width: ${cssBreakpoints.desktopSm}) {
    &.lg-60 {
      width: calc(60% - ${tileSpacing * 2}px);
      flex-grow: unset !important;
    }

    &.lg-50 {
      width: calc(50% - ${tileSpacing * 2}px);
      flex-grow: unset !important;
    }

    &.lg-33 {
      width: calc(33.3% - ${tileSpacing * 2}px);
      flex-grow: unset !important;
    }

    &.lg-40 {
      width: calc(40% - ${tileSpacing * 2}px);
      flex-grow: unset !important;
    }
  }
`
