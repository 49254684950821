import React from 'react'
import styled from 'styled-components'

// Constants
import { ButtonComponent, LinkComponent } from '../../Button'

export const S_ButtonRow = styled.div`
  margin: ${({ spacingV, spacingH }) => `-${spacingV} -${spacingH}`};
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &.align-left {
    justify-content: flex-start;
  }

  &.align-center {
    justify-content: center;
  }

  &.align-right {
    justify-content: flex-end;
  }

  &.align-space-between {
    justify-content: space-between;
  }

  ${ButtonComponent}, ${LinkComponent}, button {
    margin: ${({ spacingV, spacingH }) => `${spacingV} ${spacingH}`};
  }
`

export const ButtonRow = ({ children, spacingV = '5px', spacingH = '5px', align = 'left', className = '' }) => (
  <S_ButtonRow className={`align-${align} ${className}`} spacingH={spacingH} spacingV={spacingV}>
    {children}
  </S_ButtonRow>
)
