import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
import { MantineProvider } from '@mantine/core';
import { useGetFlipperFeatures } from 'xen/api/use-flipper';
import { AppLoader, Error } from 'xen/components';
const defaultFeatures = {
    accounting_integration: false,
    activities_async_processing: false,
    alias_tenant_badge: false,
    applicant_v1: false,
    applied_payments: false,
    applied_payments_total_fees: false,
    ar_aging: false,
    auto_assign_sales_person: false,
    auto_change_prospect_to_client: false,
    auto_funding_request: false,
    backdating_enabled: false,
    banking_integration: false,
    banking_integration_client_disconnect: false,
    breakout_additional_columns: false,
    'broker-commission': false,
    'broker-commission_prospect-page': false,
    business_officer_unique_email: false,
    change_email_receivers: false,
    chargeback_backdate: false,
    check_number_required: false,
    client_activity_page: false,
    client_concentration: false,
    client_debtors_module: false,
    client_details_payments_tab: false,
    client_reserves_page: false,
    client_summary_report: false,
    client_vendors_module: false,
    cognito_auth: false,
    custom_header_logo: false,
    daily_funds_employed: false,
    daily_net_funds_employed: false,
    debtor_balance_improvement: false,
    debtor_concentration: false,
    debtor_credit_limit: false,
    dilution: false,
    documents_page: false,
    drawer_navigation: false,
    esigning_pill: false,
    esigning_tab_business: false,
    fr_posting_date: false,
    fund_checkbox: false,
    funding_for_borrower: false,
    funding_request_fee_from_cash: false,
    funding_request_gl_movement: false,
    funding_request_notification: false,
    funding_requests: false,
    generated_reports: false,
    gl_overview: false,
    gl_summary: false,
    hide_due_diligence: false,
    invoice_advanced_amount: false,
    invoice_schedule_esigning: false,
    invoice_settlement_expected_total: false,
    invoices_batch_processing: false,
    invoices_schedule_timeout: false,
    meta_form_by_deal_new_serializer: false,
    meta_forms_by_tenant_new_serializer: false,
    'monthly-funding-amount-requested': false,
    new_client_button_on_client_page: false,
    new_configurations_and_settings_ui: false,
    new_fees: false,
    new_invoice_redesign: false,
    new_tenant_style_structure: false,
    new_underwriting_sign_up_flow: false,
    new_underwriting: false,
    operation_performed_async: false,
    payment_batch_notification: false,
    payoff: false,
    plutus_gl_movement: false,
    portfolio_summary: false,
    print_application_disclaimer: false,
    print_underwrite_checklist: false,
    print_underwriting_checklist: false,
    product_abl: false,
    product_esigning: false,
    product_factoring: false,
    product_operate: false,
    product_risk_and_fraud: false,
    product_underwrite: false,
    prospect_button: false,
    'prospect-quotes': false,
    purchase_invoices_async: false,
    purchased_invoices: false,
    quickbooks_gl_overview: false,
    quickbooks_gl_overview_csv: false,
    reconciliation_process: false,
    require_underwriting_approval: false,
    require_underwriting_checklist: false,
    return_checks: false,
    risk_and_fraud: false,
    sales_email_bcc_new_leads: false,
    salesforce_integration: false,
    saleslink: false,
    schedule_page_new: false,
    secrets_manager: false,
    send_emails: false,
    show_all_funds_employed_reports: false,
    show_client_ineligibles: false,
    silly_feature: false,
    static_due_diligence: false,
    stop_invoice_fees: false,
    store_generated_report: false,
    transaction: false,
    transactions_v2: false,
    underwrite_activities: false,
    underwrite_audit: false,
    unprocess_payment_batch: false,
    unprocess_schedule: false,
    'unprocess-fr': false,
    upload_invoices_enabled: false,
    use_non_standard_nfe: false,
    'user-notification-settings': false,
    'user-notifications': false,
    xen_invoice_schedule: false,
    xen_prospect_details: false,
    xen_prospect_details_all: false,
};
export const FeaturesContext = createContext(defaultFeatures);
export const FeaturesProvider = ({ children }) => {
    const { data: features = defaultFeatures, isLoading, isError } = useGetFlipperFeatures();
    if (isLoading)
        return (_jsx(MantineProvider, { children: _jsx(AppLoader, {}) }));
    if (isError)
        return (_jsx(MantineProvider, { children: _jsx(Error, { message: "Could not fetch features" }) }));
    return _jsx(FeaturesContext.Provider, { value: features, children: children });
};
export const useFeaturesContext = () => {
    const context = useContext(FeaturesContext);
    return context;
};
