import React from 'react'

// Components
import Table from '../../../_components/Table'
import { createColumn } from '../../../_components/TableColumn'
import { Button } from '../../../_components/Button'

// Constants
import { LENDER_ROUTES } from '../../../_constants/routes'

export const DebtorTableColumns = [
  {
    accessor: 'id',
    headingText: 'Debtor ID',
  },
  {
    accessor: 'debtor_name',
    headingText: 'Name',
  },
  createColumn({
    id: 'overall_credit_limit',
    type: 'currency',
    headingText: 'Credit Limit',
  }),
  createColumn({
    id: 'ar_balance',
    type: 'currency',
    headingText: 'Balance',
  }),
  createColumn({
    id: 'concentration',
    type: 'precise-percentage',
    precision: 2,
    headingText: 'Concentration',
  }),
  createColumn({
    id: 'net_dilution',
    type: 'precise-percentage',
    precision: 2,
    headingText: 'Net Dilution',
  }),
  {
    accessor: `na`,
    headingText: '',
    disableSortBy: true,
    maxWidth: 120,
    Cell: (props) => (
      <div className={`align-right`}>
        <Button
          buttonType="link"
          className="compact"
          text="View"
          to={`${LENDER_ROUTES.debtor_details}/${props.row.original.id}`}
        />
      </div>
    ),
  },
]

export const DebtorsTable = ({ debtors, pageSize, columns = DebtorTableColumns, handleSortChange }) => {
  if (!debtors || debtors.length === 0) {
    return null
  }

  return (
    <Table
      className="with-border"
      columns={columns}
      data={debtors}
      defaultSorted={[
        {
          id: 'updated_at',
          desc: true,
        },
      ]}
      onSortedChange={handleSortChange}
      pageSize={pageSize}
    />
  )
}
