import { useIsMutating, useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { notifications } from 'xen/helpers';
import { queryClient } from 'xen/providers';
import { transformPaymentAssignmentParams, transformPaymentAssignmentResponse, } from 'xen/_modules/invoice-settlements/api/use-invoice-settlement/transform';
async function createPaymentAssignment(createData) {
    try {
        const payloadData = transformPaymentAssignmentParams(createData);
        const response = await request({
            method: 'POST',
            url: `/operate/payment_assignments`,
            data: payloadData,
        });
        const data = transformPaymentAssignmentResponse(response);
        return data;
    }
    catch (_error) {
        throw Error('Could not assign invoice to payment');
    }
}
export const useCreatePaymentAssignment = () => {
    return useMutation({
        mutationKey: ['create-payment-assignment'],
        mutationFn: (params) => createPaymentAssignment(params),
        onError: (error) => {
            notifications.error(error.message);
        },
        onSuccess: (assignment) => {
            notifications.success(`Assigned invoice ${assignment.invoice.invoiceNumber} to payment`);
            queryClient.invalidateQueries({ queryKey: ['payment-assignments'] });
            queryClient.invalidateQueries({ queryKey: ['payments'] });
            queryClient.invalidateQueries({ queryKey: ['invoices'] });
            queryClient.invalidateQueries({ queryKey: ['invoice-settlements'] });
        },
    });
};
export const useIsPaymentAssignmentCreating = () => {
    return useIsMutating({ mutationKey: ['create-payment-assignment'] });
};
