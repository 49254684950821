import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { isFeatureEnabled } from '../../../_helpers/flags'

// Components
import { ListRow } from '../../../_components/ListSearchableView/ListRow'

// Helpers
import { history } from '../../../_helpers/history'

// Constants
import { LENDER_ROUTES } from '../../../_constants/routes'

// Services
import { S_Date, S_ListHeader } from './ProspectRows.styles'
import { markDealAsViewed } from '../../../_services/deals.service'

import { useTenant } from '../../../_reduxHooks/tenant.hook'
import { useUser } from '../../../_reduxHooks/user.hook'
import { useFeatureFlags, useXenHistory } from 'xen/hooks'

export const ProspectsRow = ({ asideData: salesPeople, isSectionEnable, prospects, onlyUnderwriteProduct }) => {
  const xenHistory = useXenHistory()
  const { xenProspectDetails, xenProspectDetailsAll } = useFeatureFlags()
  const isMonthlyFundingEnabled = isFeatureEnabled('monthly-funding-amount-requested')

  if (!prospects || prospects.length === 0) {
    return null
  }

  const { user } = useUser()
  const { tenantObj } = useTenant()
  const { prospect_overview_value_field_v2 = '' } = tenantObj
  const location = useLocation()
  const isProspectsPage = location.pathname.includes('prospects') || false

  const handleRedirection = ({ value, type = false }) => {
    const baseRoute = onlyUnderwriteProduct
      ? `${LENDER_ROUTES.underwrite_borrower_details}/${value}`
      : `${LENDER_ROUTES.prospect_details}/${value}`
    const state = type ? { prospectSection: type } : {}

    const isBorrower = user.user_type === 'client' || user.user_type === 'prospect'
    if ((isBorrower && xenProspectDetails) || xenProspectDetailsAll) {
      xenHistory.push({ to: '/prospects/:id', urlParams: [value] })
    } else {
      history.push(baseRoute, state)
    }

    // we don't need to await for the result of this call
    markDealAsViewed(value)
  }

  const renderProspects = () =>
    prospects.map((prospect) => (
      <div data-testid={`listrow-${prospect.id}`} key={`listrow-key-${prospect.id}`}>
        <ListRow
          isSectionEnable={isSectionEnable}
          prospect={prospect}
          redirection={handleRedirection}
          salesPerson={salesPeople[prospect.sales_person_id]}
        />
      </div>
    ))

  return (
    <>
      <S_ListHeader>
        <S_Date prospect={isProspectsPage}>Date Updated</S_Date>
        <S_Date prospect={isProspectsPage}>Date Created</S_Date>
        {isMonthlyFundingEnabled ? <S_Date prospect={isProspectsPage}>Monthly Funding Amount Requested</S_Date> : null}
        {!isMonthlyFundingEnabled && prospect_overview_value_field_v2 ? <S_Date>Facility Size</S_Date> : null}
      </S_ListHeader>
      {renderProspects()}
    </>
  )
}

ProspectsRow.propTypes = {
  asideData: PropTypes.object,
  isSectionEnable: PropTypes.bool.isRequired,
  prospects: PropTypes.array,
  refresh: PropTypes.func,
}
ProspectsRow.defaultProps = {
  refresh: () => {},
}
