import styled from 'styled-components'
import colors from '../../../../_constants/colors'
import { cssBreakpoints } from '../../../../_constants/styles'

export const S_SliderView = styled.div`
  padding: 0 40px 1px 40px;
  margin-bottom: 50px;
  position: relative;

  &.nav-disabled {
    .download-all-files-btn {
      display: block;
      margin-left: auto;
    }
  }

  &.nav-enabled {
    .download-all-files-btn {
      right: 0;
      bottom: -45px;
      position: absolute;
      margin: 0;
    }
  }

  .slick-dots {
    bottom: -40px;
    button {
      &:before {
        display: none;
      }
      font-size: 14px;
      color: ${colors.TEXT_LIGHT};
    }

    .slick-active {
      button {
        color: ${colors.DEFAULT_SECONDARY};
      }
    }
  }

  .slick-arrow {
    width: 21px;
    height: 21px;
    transform: rotate(135deg);
    &:hover {
      &:before {
        border-color: ${colors.DEFAULT_SECONDARY};
      }
    }
    &:before {
      content: '';
      border: solid ${colors.TEXT_LIGHT};
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 10px;
      position: absolute;
      top: 0;
      left: 0;
    }
    &.slick-next {
      transform: rotate(-45deg);
      &:before {
        left: 0;
      }
    }
  }
`

export const S_SliderViewFile = styled.div`
  margin: 5px 0;
  .slider-file {
    padding: 2px 0;
    color: ${colors.TEXT_DARK};
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    &.disabled {
      pointer-events: none;
    }
    &:hover {
      color: ${colors.DEFAULT_SECONDARY};
    }
  }

  .slider-file-text {
    margin: 0 3px;
    text-overflow: ellipsis;
  }
`

export const S_SliderViewItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${cssBreakpoints.mobileLg}) {
    display: block;
  }

  ${S_SliderViewFile} {
    width: 50%;
    box-sizing: border-box;
    &:nth-child(odd) {
      padding-right: 20px;
    }

    @media (max-width: ${cssBreakpoints.mobileLg}) {
      width: 100%;
      &:nth-child(odd) {
        padding-right: 0;
      }
    }
  }
`
