import React, { useState, useEffect, useRef } from 'react'
import ReactDropzone from 'react-dropzone'
import { unstable_batchedUpdates } from 'react-dom'
import PropTypes from 'prop-types'

// Components
import { Button } from '../Button'
import { ButtonRow } from '../layout/ButtonRow'
import { ControlsButton } from '../ControlsButton'
import { DropGallery } from './views/DropGallery'
import { GalleryView } from './views/GalleryView/GalleryView'
import { SliderView } from './views/SliderView/SliderView'
import { Indicator } from '../Indicator'
import { ListView } from './views/ListView'
import { PaginationControls } from '../PaginationControls'
import { SectionLoader } from '../SectionLoader'
import { SlimGalleryView } from './views/GalleryView/SlimGalleryView'
import { S_FilesManager, S_FilesManagerBrowser } from './FilesManager.styles'

// Utils
import { logError } from '../../_helpers/errors'

export const FilesManager = ({
  allowDelete,
  allowDownload,
  allowMultiple,
  allowPagination,
  allowUpload,
  draggable,
  emptyText,
  files,
  isUploading,
  onDelete,
  onFilesAccepted,
  pageSize,
  paginationRequest,
  parentId,
  showDropzone,
  showHeader,
  showToggle,
  title,
  viewConfig,
  viewType,
  requestsManaged = false,
  hideDownloadAll = false,
  allowExpand = false,
  disabled = false,
  customDownloadAll = null,
  className = '',
}) => {
  const { pageSizePostfix = 'results/page', pageSizes = [5, 10, 25] } = viewConfig

  const [activeFileIndex, setActiveFileIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(!showToggle)
  const [totalResults, setTotalResults] = useState(0)
  const [isDeleting, setIsDeleting] = useState(false)
  const [localIsUploading, setLocalIsUploading] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [recordsPerPage, setRecordsPerPage] = useState(pageSize)
  const [currentFiles, setCurrentFiles] = useState(files)
  const [pageNum, setPageNum] = useState(1)
  const dropzoneRef = useRef()
  const isDataChanging = isFetching || isDeleting || localIsUploading || isUploading

  const nonDeletedFiles = currentFiles.filter((file) => {
    return file !== null && file.deleted == false
  })
  const hasFiles = nonDeletedFiles && nonDeletedFiles.length > 0
  const indicatorString =
    nonDeletedFiles.length > 0
      ? `${nonDeletedFiles.length} ${nonDeletedFiles.length === 1 ? 'file' : 'files'}`
      : '0 files'

  const handleDelete = (...args) => {
    setIsDeleting(true)
    onDelete(...args).then(() => {
      setIsDeleting(false)
    })
  }

  const handleAccept = (...args) => {
    setLocalIsUploading(true)
    onFilesAccepted(...args).then((response) => {
      setLocalIsUploading(false)
    })
  }

  const checkPageNum = (newTotalResults) => {
    if (newTotalResults <= (pageNum - 1) * recordsPerPage && pageNum > 1) {
      setPageNum(pageNum - 1)
    }
  }

  const loadFn = () => {
    const hasDataChanged = !isDeleting && !localIsUploading
    if (!hasDataChanged) return

    let searchString = `start_index=${(pageNum - 1) * recordsPerPage}&count=${recordsPerPage}`

    setIsFetching(true)
    paginationRequest(searchString).then(
      (response) => {
        if (!response) {
          setIsFetching(false)
          return
        }

        unstable_batchedUpdates(() => {
          // Handle both object and array responses
          if (Array.isArray(response)) {
            setCurrentFiles(response)
            setTotalResults(response.length)
            checkPageNum(response.length)
          } else {
            setCurrentFiles(response.results)
            setTotalResults(response.total_results)
            checkPageNum(response.total_results)
          }
          setIsFetching(false)
        })
      },
      (e) => {
        setIsFetching(false)
        logError(e)
      }
    )
  }
  // Call endpoint
  useEffect(loadFn, [pageNum, recordsPerPage, isDeleting, localIsUploading])

  // Reset file index when files change
  useEffect(() => {
    setCurrentFiles(files)
    setActiveFileIndex(0)
  }, [JSON.stringify(files)])

  // TODO: set the initial state to a sorted (most recent first), filtered set of files
  // TODO: standardize this header, with indicator etc

  const showDropGallery = viewType == 'dropgallery' && currentFiles && currentFiles.length

  return (
    <S_FilesManager className={`${isOpen ? 'open' : ''} ${viewType === 'list' ? `list-class` : ''} ${className}`}>
      {/* Header */}
      {showHeader && (
        <header>
          <div className="left">
            <h2 className="title">{title}</h2>
            {hasFiles && !allowPagination && <Indicator content={indicatorString} />}
          </div>

          <div className="right">
            {hasFiles && allowPagination && (
              <PaginationControls
                onNextPage={() => {
                  setPageNum(pageNum + 1)
                }}
                onPageSelected={(page) => {
                  setPageNum(page)
                }}
                onPageSizeChange={(newPageSize) => {
                  setRecordsPerPage(+newPageSize)
                  setPageNum(1)
                }}
                onPrevPage={() => {
                  setPageNum(pageNum - 1)
                }}
                pageNum={pageNum}
                pageSize={recordsPerPage}
                pageSizePostfix={pageSizePostfix}
                pageSizes={pageSizes}
                totalResults={totalResults}
              />
            )}

            {/* Proxy for dropzone */}
            {(allowUpload || showToggle) && (
              <ButtonRow>
                {showToggle && hasFiles && (
                  <ControlsButton
                    icon={isOpen ? 'less' : 'more'}
                    onClick={() => {
                      setIsOpen(!isOpen)
                    }}
                  />
                )}
                {allowUpload && (
                  <Button
                    className="md-compact"
                    disabled={disabled}
                    onClick={() => {
                      dropzoneRef.current.click()
                    }}
                    text="Add Files"
                  />
                )}
              </ButtonRow>
            )}
          </div>
        </header>
      )}

      {/* Putting these two in a single div to allow for flex side-by-side and wrapping */}
      <S_FilesManagerBrowser files={currentFiles} viewType={viewType}>
        {/* Dropzone - hidden if files already uploaded */}
        {allowUpload && !isDataChanging && (
          <ReactDropzone
            className={`dropzone ${disabled ? 'disabled' : ''} ${
              (!hasFiles || viewType == 'list' || viewType == 'dropgallery') && showDropzone && 'active'
            }`}
            disabled={disabled}
            multiple={allowMultiple}
            onDrop={(acceptedFiles, rejectedFiles, e) => {
              e.persist()
              const file = acceptedFiles && acceptedFiles.length > 0
              if (file) {
                handleAccept(acceptedFiles)
              } else {
                // some sort of error to handle in the component,
                // also call error callback
              }
            }}
            onDropCapture={(e) => {
              //when an already uploaded image is being dragged over we'll handle it here instead of the onDrop handler
              let base64Image = e.dataTransfer.getData('base64')
              let imageName = e.dataTransfer.getData('imageName')
              if (base64Image) {
                let imageData = base64Image.split(',')
                let mimeType = imageData[0].replace('data:', '').replace(';base64', '')
                let image = imageData[1]

                //base64 to proper File type, goes byteString -> byteArray (ints) -> byteArray -> blob
                const byteCharacters = atob(image)
                const byteNumbers = new Array(byteCharacters.length)
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i)
                }
                const byteArray = new Uint8Array(byteNumbers)
                const blob = new Blob([byteArray], { type: mimeType })

                handleAccept([
                  new File([blob], imageName, {
                    type: mimeType,
                  }),
                ])
                e.stopPropagation()
              }
            }}
          >
            {showDropGallery ? (
              <DropGallery
                allowDelete={allowDelete}
                allowDownload={allowDownload}
                draggable={draggable}
                files={nonDeletedFiles}
                onDelete={handleDelete}
                viewConfig={viewConfig}
              />
            ) : null}
            <div className={`inner-dropzone ${showDropGallery ? 'hidden' : ''}`} ref={dropzoneRef}>
              {emptyText}
            </div>
          </ReactDropzone>
        )}
        {/* Container for active view */}
        {viewType != 'dropgallery' && (
          <div className="view-container">
            {/* Loading State */}
            {isDataChanging && <SectionLoader />}

            {/* Gallery View - One File at a Time */}
            {!isDataChanging && isOpen && viewType === 'gallery' && (
              <GalleryView
                activeFileIndex={activeFileIndex}
                allowDelete={allowDelete}
                allowDownload={allowDownload}
                files={nonDeletedFiles}
                onDelete={handleDelete}
                setActiveFileIndex={setActiveFileIndex}
                viewConfig={viewConfig}
              />
            )}

            {/* vertical scroll */}
            {!isDataChanging && isOpen && viewType === 'slimgallery' && (
              <SlimGalleryView
                allowDelete={allowDelete}
                allowDownload={allowDownload}
                allowExpand={allowExpand}
                draggable={draggable}
                files={nonDeletedFiles}
                onDelete={handleDelete}
                viewConfig={viewConfig}
              />
            )}

            {/* TODO: Add Grid, List Table Views Here */}
            {!isDataChanging && isOpen && viewType === 'list' && (
              <ListView
                allowDelete={allowDelete}
                allowDownload={allowDownload}
                customDownloadAll={customDownloadAll}
                files={nonDeletedFiles}
                hideDownloadAll={hideDownloadAll}
                onDelete={handleDelete}
                parentId={parentId}
                requestsManaged={requestsManaged}
              />
            )}

            {/* Slider View - One File at a Time */}
            {!isDataChanging && isOpen && viewType === 'slider' && (
              <SliderView
                activeFileIndex={activeFileIndex}
                allowDelete={allowDelete}
                allowDownload={allowDownload}
                customDownloadAll={customDownloadAll}
                files={nonDeletedFiles}
                onDelete={handleDelete}
                setActiveFileIndex={setActiveFileIndex}
                viewConfig={viewConfig}
              />
            )}
          </div>
        )}
      </S_FilesManagerBrowser>
    </S_FilesManager>
  )
}

FilesManager.propTypes = {
  allowDelete: PropTypes.bool,
  allowDownload: PropTypes.bool,
  allowMultiple: PropTypes.bool,
  allowPagination: PropTypes.bool,
  allowUpload: PropTypes.bool,
  draggable: PropTypes.bool,
  emptyText: PropTypes.string,
  files: PropTypes.array,
  isUploading: PropTypes.bool,
  onDelete: PropTypes.func,
  onFilesAccepted: PropTypes.func,
  pageSize: PropTypes.number,
  paginationRequest: PropTypes.func,
  parentId: PropTypes.number,
  showDropzone: PropTypes.bool,
  showHeader: PropTypes.bool,
  showToggle: PropTypes.bool,
  title: PropTypes.string,
  viewConfig: PropTypes.object,
  viewType: PropTypes.string,
}

FilesManager.defaultProps = {
  allowDelete: true,
  allowDownload: true,
  allowMultiple: true,
  allowPagination: false,
  allowUpload: true,
  draggable: false,
  emptyText: 'Drop files here or click to upload',
  files: [],
  isUploading: false,
  onDelete: async () => {},
  onFilesAccepted: async () => {},
  pageSize: 5,
  paginationRequest: async () => {},
  parentId: null,
  showDropzone: true,
  showHeader: true,
  showToggle: false,
  title: 'Files',
  viewConfig: {},
  viewType: 'gallery',
}
