import React, { useEffect, useState } from 'react'
import { Grid, GridCol } from '../../../../components/Grid'
import { Box } from '../../../../components/Box/Box'
import { Text } from '../../../../_components/Typography/Components/Text/Text'
import colors from '../../../../_constants/colors'
import { Divider } from '../../../../components/Divider/Divider'
import { H2 } from '../../../../_components/Typography/Components/Headings/H2'
import { BarChart } from '../../../../components/Chart/BarChart/BarChart'
import { SimpleTable } from '../../../../components/SimpleTable/SimpleTable'
import { formatCurrency, formatPercentage } from '../../../../_helpers'
import { getInvoiceSummarized } from '../../../../_services/invoices.service'
import { BUSINESS_INVOICES_FILTER_COLUMNS } from './constants'
import { getViewsConfig } from './index'

const start_age_column = BUSINESS_INVOICES_FILTER_COLUMNS.find((f) => f.fieldName === 'start_age')
const end_age_column = BUSINESS_INVOICES_FILTER_COLUMNS.find((f) => f.fieldName === 'end_age')

export const InvoicesOverviewSummary = ({ view, tableQuery, businessId, setFilters }) => {
  const [totals, setTotals] = useState('$0.00')
  const [pastDueTotals, setPastDueTotals] = useState('$0.00')
  const [chartData, setChartData] = useState([])
  const [totalsData, setTotalsData] = useState([])
  const [loading, setLoading] = useState(true)
  const viewConfig = getViewsConfig(view, businessId)
  const configQuery = viewConfig?.stateParam || ''

  const getBatchPercent = (total, batchTotal) => {
    if (!total) {
      return formatPercentage(0)
    }
    return formatPercentage(batchTotal / total)
  }

  useEffect(() => {
    const params = `${configQuery}` + `${tableQuery ? `&${tableQuery}` : ''}`
    getInvoiceSummarized('ar_aging', params).then((response) => {
      const totals = response.totals
      const totalsArr = Object.keys(totals)
        .filter((key) => key !== 'total')
        .map((key) => {
          return {
            name: key,
            value: Number(totals[key]),
          }
        })
      const totalsData = Object.keys(totals)
        .filter((key) => key !== 'total')
        .map((key) => {
          return {
            name: key,
            value: formatCurrency(Number(totals[key])),
            percent: getBatchPercent(totals?.total, Number(totals[key])),
          }
        })
      setTotalsData(totalsData)
      setChartData(totalsArr)
      setTotals(formatCurrency(totals?.total || 0))
      setLoading(false)
    })

    getInvoiceSummarized('past_due', params).then((response) => {
      setPastDueTotals(formatCurrency(response?.past_due_total || 0))
    })
  }, [tableQuery])

  const toggleBatch = (batch) => {
    const params = getBatchFilter(batch)
    const newFilters = []
    params.forEach((filterValue, filterName) => {
      if (filterName === 'start_age') {
        newFilters.push({
          column: start_age_column,
          value: filterValue,
        })
      }
      if (filterName === 'end_age') {
        newFilters.push({
          column: end_age_column,
          value: filterValue,
        })
      }
    })
    setFilters(newFilters)
  }

  const getBatchFilter = (batch) => {
    const params = new URLSearchParams()
    if (!batch) {
      return params
    }
    let [start_age, end_age] = batch.split('-')
    if (!end_age) {
      params.set('start_age', +start_age.replace('+', ''))
    } else {
      params.set('start_age', +start_age === 1 ? 0 : +start_age)
      params.set('end_age', +end_age)
    }
    return params
  }

  return (
    <Grid alignItems={'stretch'}>
      <GridCol flexGrow={1} lg={3} margin={'0 0 1rem 0'} md={4} minWidth={'min-content'}>
        <Box
          borderRadius={'3px'}
          borderWidth={'1px'}
          boxHeight={'100%'}
          d={'flex'}
          fd={'column'}
          justifyContent={'center'}
          p={'15px'}
        >
          <Box>
            <Text align={'center'} fs={'38px'} fw={600}>
              {totals}
            </Text>
            <Text align={'center'} fs={'18px'} fw={300} mt={'8px'} textColor={colors.TEXT_LIGHT}>
              Outstanding Invoices
            </Text>
          </Box>
          <Divider boxMaxWidth={'100px'} boxWidth={'100%'} mb={'15px'} ml={'auto'} mr={'auto'} mt={'15px'} />
          <Box>
            <Text align={'center'} fs={'38px'} fw={600}>
              {pastDueTotals}
            </Text>
            <Text align={'center'} fs={'18px'} fw={300} mt={'8px'} textColor={colors.TEXT_LIGHT}>
              Past Due Invoices
            </Text>
          </Box>
        </Box>
      </GridCol>

      <GridCol flexGrow={1} lg={6} margin={'0 0 1rem 0'} md={4} minWidth={'min-content'}>
        <Box borderRadius={'3px'} borderWidth={'1px'} boxHeight={'100%'} p={'15px'}>
          <H2 fw={400} mb={'10px'}>
            Open Invoice Aging at a Glance (Days)
          </H2>
          <BarChart
            data={chartData}
            labelToolTipFormatter={(name) => `Age: ${name}`}
            loading={loading}
            onClick={(e) => {
              toggleBatch(e?.activeLabel)
            }}
            toolTipFormatter={(value) => [formatCurrency(value), `Balance `]}
            xAxisKey={'name'}
          />
        </Box>
      </GridCol>

      <GridCol flexGrow={1} lg={3} margin={'0 0 1rem 0'} md={4} minWidth={'min-content'}>
        <Box borderRadius={'3px'} borderWidth={'1px'} boxHeight={'100%'} p={'15px'}>
          <H2 fw={400} mb={'10px'}>
            Aging Breakdown (Days)
          </H2>

          <SimpleTable data={totalsData} />
        </Box>
      </GridCol>
    </Grid>
  )
}
