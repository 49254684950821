import styled from 'styled-components'

import colors from '../../_constants/colors'

export const S_ControlsButton = styled.button`
  align-items: center;
  background: none;
  border: none;
  color: ${colors.ICON_GREY};
  display: inline-flex;
  height: 35px;
  justify-content: center;
  outline: none;
  padding: 0;
  width: 35px;

  border-radius: 50%;

  &:not(:disabled) {
    cursor: pointer;
    border-color: #666;
    &:hover {
      background: #f1f1f1;
    }
  }

  svg,
  img.svg {
    width: 15px;
    height: 15px;
  }
`
