import React from 'react'

import './Signers.css'
import { formatValueByType } from '../../../_helpers'
import { RenderIf } from '../../../components/helpers/RenderIf/RenderIf'

const Signers = (props) => {
  const signers = props.signers

  return (
    <div>
      {signers.map((signer) => (
        <div key={`container-${signer.id}`}>
          <div className={'signer-box'} key={signer.id}>
            <div className={signer.state} />
            <span>{signer.name}</span>
          </div>
          <div className={'viewed'} key={`view` + signer.id}>
            <RenderIf condition={signer.viewed_at}>
              <p>
                <span className={'date-info'}>
                  Viewed: {formatValueByType(Date.parse(signer.viewed_at), 'dateTime')}
                </span>
              </p>
            </RenderIf>
            <RenderIf condition={signer.signed_at}>
              <p>
                <span className={'date-info'}>
                  Signed: {formatValueByType(Date.parse(signer.signed_at), 'dateTime')}
                </span>
              </p>
            </RenderIf>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Signers
