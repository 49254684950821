import styled, { css } from 'styled-components'
import colors from '../../_constants/colors'
import { S_Text } from '../../_components/Typography/Components/Text/Text.styles'

export const S_SimpleTable = styled.div`
  display: table;
  width: 100%;
`

export const S_SimpleTableCell = styled.div`
  display: table-cell;
  border-bottom: 1px solid ${colors.OUTLINE_GREY_BOX};
  padding: 8px 2px;
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
`

const getTextColor = (hasHighLight, highlight) => {
  if (!hasHighLight) {
    return ''
  }

  if (hasHighLight && !highlight) {
    return css`
      ${S_Text} {
        color: ${colors.TEXT_LIGHT};
      }
    `
  }
  if (hasHighLight && highlight) {
    return css`
      ${S_Text} {
        color: ${colors.DEFAULT_TEXT};
      }
    `
  }
}

export const S_SimpleTableRow = styled.div`
  display: table-row;
  ${({ hasHighLight, highlight }) => getTextColor(hasHighLight, highlight)}
  &:last-child {
    ${S_SimpleTableCell} {
      border-bottom: 0;
    }
  }
`
