import React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { connect } from 'react-redux'

// Components
import { Box, Select } from 'xen-ui'
import { ContentColumn } from '../../../_components/layout/ContentColumn'
import { PageContents } from '../../../_components/layout/PageContents'
import { FundingRequestsTable, FundingRequestColumns, FundingRequestABLColumns } from './FundingRequestsTable'
import { SearchableOverview } from '../../../_components/SearchableOverview'

// Services
import { getFundingRequests, getFundingRequestsCount } from '../../../_services/lender.service'
import { getAblFundingRequests } from '../../../_services/abl/bbc.service'
import { Button } from '../../../_components/Button'

// Constants
import { LENDER_ROUTES } from '../../../_constants/routes'
import { FR_FILTER_COLUMNS } from './constants'

// Helpers
import { isProductEnabled } from '../../../_helpers/flags'
import { history } from '../../../_helpers/history'

// Styled Component
export const S_FundingRequestOverview = styled.div``

const S_Header = styled.div`
  display: flex;
  align-items: flex-end;

  a {
    max-height: 45px;
    margin-left: auto;
  }
`

const FundingRequestOverview = ({ featureFlags, location }) => {
  const pathName = location.pathname
  const factoringFREndpointCall = (searchParams) => {
    const alreadySorted = searchParams.has('sort_by')
    if (!alreadySorted) {
      searchParams.set('sort_by', 'updated_at')
      searchParams.set('sort_asc', false)
    }

    switch (pathName) {
      case LENDER_ROUTES.funding_requests_pending:
        searchParams.set('pending', true)
        break
      case LENDER_ROUTES.funding_requests_approved:
        searchParams.set('pending', false)
        searchParams.set('approved', true)
        searchParams.set('funded', false)
        break
      case LENDER_ROUTES.funding_requests_funded:
        searchParams.set('funded', true)
        break
      case LENDER_ROUTES.funding_requests_rejected:
        searchParams.set('pending', false)
        searchParams.set('approved', false)
        break
    }

    return Promise.all([
      getFundingRequestsCount(searchParams.toString()),
      getFundingRequests(searchParams.toString()),
    ]).then((values) => {
      const result = {
        total_results: values[0],
        results: values[1],
      }

      return result
    })
  }

  const ablFREndpointCall = (searchParams) => {
    switch (pathName) {
      case LENDER_ROUTES.funding_requests_pending:
        searchParams.set('status', 'pending')
        break
      case LENDER_ROUTES.funding_requests_approved:
        searchParams.set('status', 'approved')
        break
      case LENDER_ROUTES.funding_requests_funded:
        searchParams.set('status', 'funded')
        break
      case LENDER_ROUTES.funding_requests_rejected:
        searchParams.set('status', 'rejected')
        break
    }

    return getAblFundingRequests(searchParams).then((resp) => {
      const result = {
        total_results: resp.headers['total-count'],
        results: resp.data,
      }

      return result
    })
  }

  // Filter only makes sense if both ABL and Factoring are enabled
  const hasFactoringAndAbl =
    isProductEnabled('Asset Based Lending', featureFlags) && isProductEnabled('Factoring', featureFlags)
  const ablEnabled = isProductEnabled('Asset Based Lending', featureFlags)
  const factoringEnabled = isProductEnabled('Factoring', featureFlags)

  let title = 'Funding Requests'
  switch (pathName) {
    case LENDER_ROUTES.funding_requests_pending:
      title = 'Pending Funding Requests'
      break
    case LENDER_ROUTES.funding_requests_approved:
      title = 'Approved Funding Requests'
      break
    case LENDER_ROUTES.funding_requests_funded:
      // 'Funded Funding Requests' sounds weird
      title = 'Funded Requests'
      break
    case LENDER_ROUTES.funding_requests_rejected:
      title = 'Rejected Funding Requests'
      break
  }

  const filterStatusSelect = (
    <Box mb="lg">
      <Select
        data={[
          { value: LENDER_ROUTES.funding_requests_all, label: 'All' },
          { value: LENDER_ROUTES.funding_requests_approved, label: 'Approved' },
          { value: LENDER_ROUTES.funding_requests_funded, label: 'Funded' },
          { value: LENDER_ROUTES.funding_requests_pending, label: 'Pending' },
          { value: LENDER_ROUTES.funding_requests_rejected, label: 'Rejected' },
        ]}
        label="Filter by Status"
        onChange={(value) => history.push(value)}
        value={pathName}
      />
    </Box>
  )

  return (
    <PageContents>
      <Helmet>
        <title>Funding Requests</title>
      </Helmet>
      <ContentColumn>
        <S_FundingRequestOverview>
          <S_Header>
            <h1>{title}</h1>
            <Button buttonType="link" to={`${LENDER_ROUTES.funding_requests_create}`}>
              New Funding Request
            </Button>
          </S_Header>

          {factoringEnabled && (
            <SearchableOverview
              filterColumns={FR_FILTER_COLUMNS}
              searchPlaceholder="Search Funding Requests"
              showSearchBar={true}
              showSearchFilter={hasFactoringAndAbl}
              views={[
                {
                  title: 'Funding Requests',
                  beforeTable: filterStatusSelect,
                  endpointCall: factoringFREndpointCall,
                  requestMethod: 'GET',
                  columns: FundingRequestColumns,
                  pageSizes: [25, 50, 100],
                  pageSize: 25,
                  defaultSort: [{ id: 'updated_at', desc: true }],
                  Component: ({ results, columns, handleSortChange, defaultSort }) => {
                    return (
                      <FundingRequestsTable
                        columns={columns}
                        defaultSort={defaultSort}
                        fundingRequests={results}
                        handleSortChange={handleSortChange}
                      />
                    )
                  },
                },
              ]}
            />
          )}

          {ablEnabled && (
            <SearchableOverview
              filterColumns={FR_FILTER_COLUMNS}
              searchPlaceholder="Search Funding Requests"
              showSearchBar={true}
              showSearchFilter={hasFactoringAndAbl}
              views={[
                {
                  title: 'Funding Requests',
                  endpointCall: ablFREndpointCall,
                  requestMethod: 'GET',
                  pageSizes: [25, 50, 100],
                  pageSize: 25,
                  columns: FundingRequestABLColumns,
                  Component: ({ results, columns, handleSortChange }) => (
                    <FundingRequestsTable
                      columns={columns}
                      fundingRequests={results}
                      handleSortChange={handleSortChange}
                    />
                  ),
                },
              ]}
            />
          )}
        </S_FundingRequestOverview>
      </ContentColumn>
    </PageContents>
  )
}

const ConnectedComponent = connect(
  (state) => {
    return {
      featureFlags: state.featureFlags,
    }
  },
  () => {
    return {}
  }
)(FundingRequestOverview)
export { ConnectedComponent as FundingRequestOverview }
