export const FR_FILTER_COLUMNS = [
  {
    fieldName: 'financing_type',
    displayName: 'Financing Type',
    type: 'select',
    options: [
      {
        value: 'abl',
        display: 'ABL',
      },
      {
        value: 'factoring',
        display: 'Factoring',
      },
    ],
  },
]
