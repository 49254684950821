import React from 'react'
import {
  S_PrintAppDisclaimerText,
  S_PrintAppDisclaimer,
  S_PrintAppDisclaimerBody,
  S_PrintAppSubmissionData,
} from './PrintAppDisclaimer.styles'
import { FormField } from '../../Forms/FormField'
import { dateUTCAmPm, UTCDateToSystemString } from '../../../_helpers'

export const PrintAppDisclaimer = ({ prospectData, disclaimerText }) => {
  const isBbusinessSubmitted = prospectData?.business?.completed_profile
  const isDealSubmitted = prospectData?.deal?.deal_status === 'submitted'
  const date = prospectData?.additional_business_data?.submitted_at || prospectData?.business?.updated_at
  const prospectEmail = prospectData?.user?.email_address
  const prospectName = `${prospectData?.user.first_name} ${prospectData?.user.last_name}`
  if (!isBbusinessSubmitted || !isDealSubmitted) {
    return null
  }
  return (
    <S_PrintAppDisclaimer>
      <S_PrintAppDisclaimerBody>
        <FormField checked={true} disabled={true} type={'checkbox'} />
        <S_PrintAppDisclaimerText>{disclaimerText}</S_PrintAppDisclaimerText>
      </S_PrintAppDisclaimerBody>

      <S_PrintAppSubmissionData>
        Submitted and Consented to by: {prospectName} ({prospectEmail})
      </S_PrintAppSubmissionData>
      <S_PrintAppSubmissionData>Submitted: {dateUTCAmPm(UTCDateToSystemString(date))}</S_PrintAppSubmissionData>
    </S_PrintAppDisclaimer>
  )
}
