import React from 'react'
import useGridContext from '../../GridContext'
import { S_GridCol } from './GridCol.styles'
import PropTypes from 'prop-types'

export const GridCol = ({ children, ...props }) => {
  const itemContext = useGridContext()
  return (
    <S_GridCol {...props} {...itemContext}>
      {children}
    </S_GridCol>
  )
}

GridCol.propTypes = {
  /**
   * Defines the column size as base width (when we don't need responsive styles)
   * Max - `columns` value from `Grid` component
   */
  size: PropTypes.number,
  /**
   * Defines the column size on XS screens
   */
  xs: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([false])]),
  /**
   * Defines the column size on SM screens
   */
  sm: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([false])]),
  /**
   * Defines the column size on MD screens
   */
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([false])]),
  /**
   * Defines the column size on LG screens
   */
  lg: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([false])]),
  /**
   * Defines the column size on XL screens
   */
  xl: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([false])]),
  /**
   * Applies 'max-width' for the column
   */
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.oneOf([false])]),
  /**
   * Applies 'min-width' for the column
   */
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.oneOf([false])]),
  /**
   * Applies 'flex-basis' for the column
   */
  flexBasis: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
  /**
   * Applies 'flex-grow' for the column
   */
  flexGrow: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([false])]),
  /**
   * Applies 'margin' for the column
   */
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
}

GridCol.defaultProps = {
  size: 0,
  xs: false,
  sm: false,
  md: false,
  lg: false,
  xl: false,
  maxWidth: false,
  minWidth: false,
  flexBasis: false,
  flexGrow: false,
  margin: false,
}
