import { jsx as _jsx } from "react/jsx-runtime";
import LegacyFactoringLenderDashboard from 'legacy/_views/FactoringLender/FactoringLenderDashboard';
import { Page } from 'xen/components';
/**
 * Overview page for Factoring Lender. May eventually be moved or renamed when
 * we support pages for other products and user types.
 */
const Overview = () => {
    // Should eventually be replaced by new Xen 1.0 overview page
    return (_jsx(Page, { pt: "0", children: _jsx(LegacyFactoringLenderDashboard, {}) }));
};
export default Overview;
