import React, { useState } from 'react'
import { ValidationForm } from '../../../../../_components/Forms/ValidationForm'
import { createTenantBroker } from '../../../../../_services'
import { LoaderButton } from '../../../../../_components/LoaderButton'
import { S_CreateBrokerModal } from './CreateBrokerModal.styles'
import { H2 } from '../../../../../_components/Typography/Components/Headings/H2'
import { Box } from '../../../../../components/Box/Box'
import { useModal } from '../../../../../_reduxHooks/modal.hook'
import { useNotifications } from '../../../../../_reduxHooks/notifications.hook'

export const CreateBrokerModal = () => {
  const [submitting, setSubmitting] = useState(false)
  const [submitForm, setSubmitForm] = useState(() => {})
  const { closeModal } = useModal()
  const { showNotification } = useNotifications()
  return (
    <S_CreateBrokerModal>
      <H2 mb={'10px'}>Add New Broker</H2>
      <ValidationForm
        entity={{}}
        fields={[
          {
            type: 'text',
            id: 'name',
            layout: '',
            label: 'Name',
            required: true,
          },
        ]}
        getFormFunctions={({ submitForm }) => {
          setSubmitForm(submitForm)
        }}
        submitHandler={async (values) => {
          setSubmitting(true)
          createTenantBroker(values)
            .then((result) => {
              showNotification({
                type: `success`,
                message: `Broker ${result.name} successfully created`,
              })
            })
            .catch((error) => {
              showNotification({
                type: `error`,
                message: error || 'Sorry, an error occurred. Please try again.',
              })
            })
            .finally(() => {
              setSubmitting(false)
              closeModal()
            })
        }}
      />

      <Box mt={'15px'}>
        <LoaderButton disabled={submitting} isLoading={submitting} onClick={submitForm} text="Save" />
      </Box>
    </S_CreateBrokerModal>
  )
}
