import React from 'react'
import { connect } from 'react-redux'
import { FaFileUpload } from 'react-icons/fa'
import { isFeatureEnabled } from '../../../_helpers/flags'
import { useModal } from '../../../_reduxHooks/modal.hook'
import { Button } from '../../../_components/Button'
import { LenderCreateScheduleModal } from '../../../_components/modals/scheduleInvoices/LenderCreateScheduleModal'
import { modalActions, notificationsActions } from '../../../_actions'
import { LENDER_ROUTES } from '../../../_constants/routes'
import { createInternalLinkUrl } from 'xen/helpers'

const NewScheduleButtonContent = () => {
  return (
    <div className={`upload-btn-internal`}>
      <FaFileUpload />
      <div className={`upload-btn-text`}>
        {isFeatureEnabled('xen_invoice_schedule') ? 'Create Invoice Schedule' : 'New Schedule'}
      </div>
    </div>
  )
}

const ButtonToModal = ({ openModal }) => {
  return (
    <Button
      onClick={() => {
        openModal(LenderCreateScheduleModal, {}, {})
      }}
    >
      <NewScheduleButtonContent />
    </Button>
  )
}

const ButtonToPage = ({ to }) => {
  return (
    <Button buttonType={`link`} to={to}>
      <NewScheduleButtonContent />
    </Button>
  )
}

const UploadInvoicesInline = ({ featureFlags }) => {
  const { openModal } = useModal()

  // If the xen invoice schedule feature is enabled, always redirect to the new schedule page
  if (isFeatureEnabled('xen_invoice_schedule', featureFlags)) {
    return <ButtonToPage to={createInternalLinkUrl({ to: '/invoice-schedules/new' })} />
  }

  // Otherwise, if the new invoice redesign feature is enabled, open the modal
  if (isFeatureEnabled('new_invoice_redesign', featureFlags)) {
    return <ButtonToModal openModal={openModal} />
  }

  // Else, redirect to the old invoice schedule page
  return <ButtonToPage to={LENDER_ROUTES.invoices_create} />
}

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (content) => {
      dispatch(modalActions.openModal(content))
    },
    uploadNotify: () => {
      dispatch(
        notificationsActions.showNotification({
          type: `success`,
          message: `Upload Successful`,
          id: `invoice-upload-success`,
        })
      )
    },
  }
}
const connectedView = connect(
  (state) => ({ featureFlags: state.featureFlags }),
  mapDispatchToProps
)(UploadInvoicesInline)
export { connectedView as UploadInvoicesInline }
