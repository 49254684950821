import {
  ablBusinessFilter,
  factoringBusinessFilter,
  factoringProspectsFilter,
} from '../../../../_components/modals/filter/filterFields'

export const getProspectOverviewFilters = (spFilterOptions, salesPeople, isAbl = false, showClients = true) => {
  let businessFilter

  if (isAbl) {
    businessFilter = ablBusinessFilter
  } else {
    businessFilter = showClients ? factoringBusinessFilter : factoringProspectsFilter
  }

  return [
    businessFilter,
    {
      fieldName: 'sales_person_id',
      displayName: 'Sales Person',
      type: 'select-dropdown',
      options: spFilterOptions,
      formatter: (val) => salesPeople[val],
    },
    {
      fieldName: 'start_date',
      displayName: 'Created Start Date',
      type: 'date',
    },
    {
      fieldName: 'end_date',
      displayName: 'Created End Date',
      type: 'date',
    },
  ]
}
