import React, { useState, useEffect } from 'react'

// Components
import { Button } from '../../../_components/Button'
import { ListSearchableOverview } from '../../../_components/ListSearchableOverview/ListSearchableOverview'
import { ProspectsRow } from '../ProspectsRows'
import { prospectColumns } from '../ProspectsTable'
import { StandardPage } from '../../../_components/layout/StandardPage'

// Selectors
import { userSelectors } from '../../../_selectors/user.selectors'

// Services
import { getProspectsWithState } from '../../../_services/deals.service'
import { getSalesPeople } from '../../../_services/lender.service'

import { useTenant } from '../../../_reduxHooks/tenant.hook'

// Helpers
import { S_PageHeader, S_PageHeaderRow } from '../../../_components/layout/PageHeader/PageHeader.styles'
import { S_Title } from '../../../_components/Typography/Titles.styles'
import { cssConstants } from '../../../_constants/styles'
import { S_ProspectsOverview } from './UnderwriteClientsOverview.styles'
import { useFlags } from '../../../_reduxHooks/flags.hook'
import { isFeatureEnabled, isProductEnabled } from '../../../_helpers/flags'
import { getProspectOverviewFilters } from '../../factoring/lender/ProspectsOverview/prospectOverview.utils'
import { isAdminUser, isUnderwriterUser } from '../../../_helpers/user-utils'
import { RenderIf } from '../../../components/helpers/RenderIf/RenderIf'

const NewUnderwriteClientsOverview = () => {
  const [salesPeople, setSalesPeople] = useState(
    new Proxy(
      {},
      {
        get: function (target, prop, receiver) {
          return ''
        },
        ownKeys: (target) => {
          return Reflect.ownKeys(target)
        },
      }
    )
  )
  const [spFilterOptions, setSpFilterOptions] = useState([])
  const [showTable, setShowTable] = useState(false)
  const user = userSelectors.useUser()
  const isUserAdmin = isAdminUser(user)
  const isUserUnderwriter = isUnderwriterUser(user)
  const { featureFlags } = useFlags()
  const underwriteEnabled = isProductEnabled('Underwrite', featureFlags)
  const factoringEnabled = isProductEnabled('Factoring', featureFlags)
  const showNewClientButton = isFeatureEnabled('new_client_button_on_client_page', featureFlags)
  const onlyUnderwriteProduct = underwriteEnabled && !factoringEnabled
  const { tenantObj } = useTenant()

  useEffect(() => {
    getSalesPeople().then((salesPeopleResp) => {
      let sp = salesPeopleResp.reduce((personMap, person) => {
        return { ...personMap, [person.id]: `${person.user.first_name} ${person.user.last_name}` }
      }, {})
      setSpFilterOptions(
        Object.keys(sp).map((key) => {
          return {
            value: key,
            display: sp[key],
          }
        })
      )
      let salesPeopleMap = new Proxy(sp, {
        get: function (target, prop, receiver) {
          if (target[prop] !== undefined) {
            return target[prop]
          } else {
            return 'Unassigned'
          }
        },
        ownKeys: (target) => {
          return Reflect.ownKeys(target)
        },
      })
      setSalesPeople(salesPeopleMap)
      setShowTable(true)
    })
  }, [user])

  let tableColumns = [...prospectColumns]
  let salesContacts = []

  salesContacts = salesPeople
  tableColumns = [
    ...tableColumns,
    {
      id: 'sales_person_id',
      headingText: 'Sales Person',
      accessor: (d) => {
        return salesPeople[d.sales_person_id]
      },
    },
  ]

  const filters = getProspectOverviewFilters(spFilterOptions, salesPeople, false)
  return (
    <StandardPage contentWidth={cssConstants.contentWidthMd} title="Clients Overview">
      <S_ProspectsOverview>
        <S_PageHeader>
          <S_PageHeaderRow>
            <S_Title marginBottom={'0'}>Clients Overview</S_Title>
            <RenderIf condition={showNewClientButton && (isUserAdmin || isUserUnderwriter)}>
              <Button buttonType={`link`} text={`+ Add Client`} to={'/client_application'} />
            </RenderIf>
          </S_PageHeaderRow>
        </S_PageHeader>

        {showTable && (
          <ListSearchableOverview
            filters={filters}
            searchPlaceholder="Search Prospects"
            views={[
              {
                asideData: salesContacts,
                columns: tableColumns,
                endpointCall: (searchParams) => getProspectsWithState('active', searchParams, tenantObj.id),
                explainer:
                  'Clients that you are actively doing business with or have no risk status. This risk status can be changed in the client details view.',
                pageSize: 5,
                pageSizes: [5, 10, 25, 50, 100],
                requestMethod: 'GET',
                title: 'Active',
                Component: ({ isSectionEnable, asideData, results, sort, handleSortChange, refresh }) => {
                  return (
                    <ProspectsRow
                      asideData={asideData}
                      handleSort={handleSortChange}
                      isSectionEnable={isSectionEnable}
                      onlyUnderwriteProduct={onlyUnderwriteProduct}
                      prospects={results}
                      refresh={refresh}
                      sorted={sort}
                    />
                  )
                },
              },
              {
                asideData: salesContacts,
                columns: tableColumns,
                endpointCall: (searchParams) => getProspectsWithState('watching', searchParams, tenantObj.id),
                explainer:
                  'Clients with some risk that you are currently monitoring. This risk status can be changed in the client details view.',
                pageSize: 5,
                pageSizes: [5, 10, 25, 50, 100],
                requestMethod: 'GET',
                title: 'Watch',
                Component: ({ isSectionEnable, asideData, results, sort, handleSortChange, refresh }) => {
                  return (
                    <ProspectsRow
                      asideData={asideData}
                      handleSort={handleSortChange}
                      isSectionEnable={isSectionEnable}
                      onlyUnderwriteProduct={onlyUnderwriteProduct}
                      prospects={results}
                      refresh={refresh}
                      sorted={sort}
                    />
                  )
                },
              },
              {
                asideData: salesContacts,
                columns: tableColumns,
                endpointCall: (searchParams) => getProspectsWithState('collecting_out', searchParams, tenantObj.id),
                explainer:
                  'Clients that are in some form of collections. This risk status can be changed in the client details view.',
                pageSize: 5,
                pageSizes: [5, 10, 25, 50, 100],
                requestMethod: 'GET',
                title: 'Collect Out',
                Component: ({ isSectionEnable, asideData, results, sort, handleSortChange, refresh }) => {
                  return (
                    <ProspectsRow
                      asideData={asideData}
                      handleSort={handleSortChange}
                      isSectionEnable={isSectionEnable}
                      onlyUnderwriteProduct={onlyUnderwriteProduct}
                      prospects={results}
                      refresh={refresh}
                      sorted={sort}
                    />
                  )
                },
              },
              {
                asideData: salesContacts,
                endpointCall: (searchParams) => getProspectsWithState('inactive', searchParams, tenantObj.id),
                explainer:
                  'Clients that are no longer actively doing business with you. This risk status can be changed in the client details view.',
                pageSize: 5,
                pageSizes: [5, 10, 25, 50, 100],
                requestMethod: 'GET',
                title: 'Inactive',
                Component: ({ isSectionEnable, asideData, results, sort, handleSortChange, refresh }) => (
                  <ProspectsRow
                    asideData={asideData}
                    handleSort={handleSortChange}
                    isSectionEnable={isSectionEnable}
                    onlyUnderwriteProduct={onlyUnderwriteProduct}
                    prospects={results}
                    refresh={refresh}
                    sorted={sort}
                  />
                ),
              },
            ]}
          />
        )}
      </S_ProspectsOverview>
    </StandardPage>
  )
}

export default NewUnderwriteClientsOverview
