import React from 'react'
import { S_TileTitle } from '../../../../../_components/TableTile/TileTitle.styles'

// Helpers
import { formatCurrency } from '../../../../../_helpers'

// Styles
import { S_TopClientsSlider, StyledSlider } from './TopClientsSlider.styles'

// Constants
const MIN_TOP_TEN = 5
const MAX_TOP_TEN = 10
const SLIDER_SETTINGS = {
  dots: true,
  infinite: false,
  speed: 400,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableHeight: true,
  draggable: false,
  swipe: false,
  arrows: false,
  accessibility: false,
}

const getDebtorRows = (debtors, startIndex, endIndex) => {
  return debtors.slice(startIndex, endIndex).map((d, i) => {
    return (
      <tr key={d.id}>
        <td className={'debtor-name'}>
          {i + startIndex + 1}. {d.display_name || d.debtor_name}
        </td>
        <td className={'debtor-balance'}>{formatCurrency(d.balance)}</td>
      </tr>
    )
  })
}

export const TopClientsSlider = ({ topTenDebtors, title = 'Top Debtors', getRowsHandler = getDebtorRows }) => {
  return (
    <S_TopClientsSlider>
      <S_TileTitle>{title}</S_TileTitle>
      <StyledSlider {...SLIDER_SETTINGS}>
        <div>
          <table className="ar-overview">
            <tbody>{getRowsHandler(topTenDebtors, 0, MIN_TOP_TEN)}</tbody>
          </table>
        </div>
        {topTenDebtors.length > 5 && (
          <div>
            <table className="ar-overview">
              <tbody>{getRowsHandler(topTenDebtors, MIN_TOP_TEN, MAX_TOP_TEN)}</tbody>
            </table>
          </div>
        )}
      </StyledSlider>
    </S_TopClientsSlider>
  )
}
