import React, { useState } from 'react'
import styled from 'styled-components'

// Constants
import { initialDebtorConfig } from '../../../../_configs/debtor.config'

// Actions

// Components
import { ValidationForm } from '../../../../_components/Forms/ValidationForm/ValidationForm'
import { LoaderButton } from '../../../../_components/LoaderButton'

// Services
import { createDebtor, createChildDebtor } from '../../../../_services/debtor.service'
import { modalActionHooks, notificationActionHooks } from '../../../../_actionHooks'

const S_CreateDebtorModal = styled.div`
  min-width: 50vw;
  .submit {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
  h2 {
    margin-bottom: 20px;
  }
`

const initialFunctions = {
  onSubmit: null,
}

const initialStates = {
  isSubmitting: false,
}
export const CreateDebtorModal = ({ debtorId = false }) => {
  const showNotification = notificationActionHooks.useShowNotification()
  const closeModal = modalActionHooks.useCloseModal()
  const [formStates, setFormStates] = useState(initialStates)
  const [formFunctions, setFormFunctions] = useState(initialFunctions)

  return (
    <S_CreateDebtorModal>
      <h2>Create New Debtor</h2>

      <ValidationForm
        entity={initialDebtorConfig.entity}
        fields={initialDebtorConfig.fields}
        getFormFunctions={({ submitForm }) => {
          setFormFunctions((prevState) => {
            let formFunctionsDup = { ...prevState }
            formFunctionsDup.onSubmit = submitForm()
            return formFunctionsDup
          })
        }}
        isSubmitting={formStates.isSubmitting}
        submitHandler={async (values, setError) => {
          setFormStates({ ...formStates, isSubmitting: true })

          if (debtorId) {
            await createChildDebtor(debtorId, values)
          } else {
            await createDebtor(values)
          }

          setFormStates({ ...formStates, isSubmitting: false })
          showNotification({ id: 'create-debtor-noty', type: 'success', message: 'Debtor Created' })
          closeModal()
        }}
      />
      <div className="submit">
        <LoaderButton isLoading={formStates.isSubmitting} onClick={formFunctions.onSubmit} text="Create" />
      </div>
    </S_CreateDebtorModal>
  )
}
