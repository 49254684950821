import { dateToUTCStringSlashes } from '../dates'

// Copied from underwrite project: path => formatters/index.js
// Just change field.field_type to field.type
export const fieldFormatter = (field) => {
  switch (field.type) {
    case 'number':
      return (value) => (value === '' ? '' : Number(value))
    case 'currency':
      return (value) => (!Number.isNaN(parseFloat(value)) ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '$ ')
    case 'tel':
      return (value) => formatTelField(value.toString())
    case 'radio_upload':
      return (value) => formatRadioUpload(value)
    case 'date':
      return (value) => dateToUTCStringSlashes(value)
    default:
      return undefined
  }
}

const formatRadioUpload = (value) => {
  if (value === 'later') {
    return 'Upload Later'
  }
  return value
}

export const formatTelField = (val) => {
  let numsOnly = val.replace(/\D/g, '')
  let toReturn = ''
  if (numsOnly.length > 10) {
    if (numsOnly.length > 13) {
      numsOnly = numsOnly.substring(0, 13)
    }
    const chars = []
    for (let i = numsOnly.length - 1; i >= 0; i--) {
      chars.push(numsOnly[i])
      if (chars.length === 4 || chars.length === 8 || chars.length === 12) {
        chars.push(' ')
      }
    }
    return '+' + chars.reverse().join('')
  } else {
    toReturn = '(' + toReturn
    for (let i = 0; i < numsOnly.length; i++) {
      toReturn += numsOnly[i]
      if (i === 2) {
        toReturn += ') '
      }
      if (i === 5) {
        toReturn += '-'
      }
    }
    if (toReturn[toReturn.length - 1] === '-' || toReturn[toReturn.length - 1] === '(') {
      toReturn = toReturn.substring(0, toReturn.length - 1)
    }
    if (toReturn[toReturn.length - 1] === ' ') {
      toReturn = toReturn.substring(0, toReturn.length - 2)
    }
    return toReturn
  }
}

export const formatField = (field, value) => {
  const formatter = fieldFormatter(field)
  if (formatter) {
    return formatter(value)
  }
  return value
}
