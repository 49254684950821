import React from 'react'
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'
import { PDFTable } from '../../Table/Table.pdf'
import { createColumn } from '../../TableColumn'
import { formatCurrency } from '../../../_helpers'

export const CompletedPaymentPdf = ({ payment, escrowReserves, fees, colors }) => {
  const columns = [
    createColumn({
      id: 'business_name',
      headingText: 'Client',
      disableSortBy: true,
    }),
    createColumn({
      id: 'invoice_number',
      headingText: 'Invoice #',
      disableSortBy: true,
    }),
    createColumn({
      type: 'currency',
      id: 'invoice_amount',
      headingText: 'Invoice Amount',
      width: 300,
      disableSortBy: true,
    }),
    createColumn({
      type: 'currency',
      id: 'amount',
      headingText: 'Payment Amount',
      disableSortBy: true,
    }),
    createColumn({
      id: 'action',
      headingText: 'Payment Action',
      disableSortBy: true,
    }),
    createColumn({
      type: 'currency',
      id: 'invoice_balance',
      headingText: 'Balance',
      disableSortBy: true,
    }),
    createColumn({
      type: 'currency',
      id: 'adjust_amount',
      headingText: 'Adjust Amount',
      disableSortBy: true,
      evaluate: (props) => {
        const { action, invoice_amount, amount } = props
        const isShortPay = action === 'Short Pay'
        const isOverPay = action === 'Over Pay'
        if (isShortPay || isOverPay) {
          const adjust_value = amount - invoice_amount
          const adjust_amount = Math.abs(adjust_value)
          return Number(adjust_amount)
        }
        return ''
      },
    }),
    createColumn({
      type: 'date',
      id: 'purchase_date',
      headingText: 'Purchase Date',
      addExpander: true,
      disableSortBy: false,
    }),
    createColumn({
      id: 'invoice_schedule_id',
      headingText: 'Schedule',
      disableSortBy: true,
    }),
    createColumn({
      id: 'age',
      headingText: 'Age',
      disableSortBy: true,
    }),
    createColumn({
      type: 'currency',
      id: 'advance_rate',
      headingText: 'Escrow Amount',
      disableSortBy: true,
      evaluate: (props) => {
        const { advance_rate, invoice_amount, action } = props
        const reserve_rate = advance_rate ? 1 - advance_rate : 1

        if (action === 'Pay Closed' || action === 'Partial Pay' || action === 'Non Factored Payment') {
          return Number(0.0)
        }

        return Number(reserve_rate * invoice_amount)
      },
    }),
    createColumn({
      type: 'currency',
      id: 'fees_earned',
      headingText: 'Earned Fees',
      disableSortBy: true,
    }),
  ]

  const styles = StyleSheet.create({
    page: { fontSize: 12, padding: 32 },
    debtorSection: {
      labels: {
        display: 'flex',
        flexDirection: 'row',
        color: '#9EAEB5',
        marginLeft: '15px',
      },
      values: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '5px',
        marginLeft: '15px',
      },
    },
    totalsSectionContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: '15px',
      marginTop: '30px',
    },
    summary: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    totalField: {
      display: 'flex',
      flexDirection: 'column',
    },
    totalHeader: {
      color: '#9EAEB5',
    },
    totalValue: {
      marginTop: '5px',
      textAlign: 'left',
    },
  })

  return (
    <Document>
      <Page orientation={'landscape'} size="A4" style={styles.page}>
        <View style={styles.debtorSection.labels}>
          <View style={{ width: '150px' }}>
            <Text>Debtor</Text>
          </View>
          <View style={{ width: '150px' }}>
            <Text>Check/Transaction #</Text>
          </View>
          <View style={{ width: '150px' }}>
            <Text>Payment Amount</Text>
          </View>
        </View>
        <View style={styles.debtorSection.values}>
          <View style={{ width: '150px' }}>
            <Text>{payment.debtor_name}</Text>
          </View>
          <View style={{ width: '150px' }}>
            <Text>{payment.check_number}</Text>
          </View>
          <View style={{ width: '150px' }}>
            <Text>{formatCurrency(payment.amount)}</Text>
          </View>
        </View>

        <View style={{ marginTop: '15px', fontSize: '10px' }}>
          <PDFTable columns={columns} data={payment.invoices} />
        </View>

        <View style={styles.totalsSectionContainer}>
          <View style={styles.summary}>
            <View style={{ borderBottom: `2px solid ${colors.secondary}` }}>
              <Text>Summary Info</Text>
            </View>
          </View>
          <View style={styles.totalField}>
            <View style={{ marginLeft: '30px' }}>
              <View style={styles.totalHeader}>
                <Text>Total Amount Paid</Text>
              </View>
              <View style={styles.totalValue}>
                <Text>{formatCurrency(payment.amount)}</Text>
              </View>
            </View>
          </View>
          <View style={styles.totalField}>
            <View style={{ marginLeft: '30px' }}>
              <View style={styles.totalHeader}>
                <Text>Applied to AR</Text>
              </View>
              <View style={styles.totalValue}>
                <Text>({formatCurrency(payment.amount)})</Text>
              </View>
            </View>
          </View>
          <View style={styles.totalField}>
            <View style={{ marginLeft: '30px' }}>
              <View style={styles.totalHeader}>
                <Text>Escrow Reserves Released</Text>
              </View>
              <View style={styles.totalValue}>
                <Text>{formatCurrency(escrowReserves)}</Text>
              </View>
            </View>
          </View>
          <View style={styles.totalField}>
            <View style={{ marginLeft: '30px' }}>
              <View style={styles.totalHeader}>
                <Text>Client Reserves</Text>
              </View>
              <View style={styles.totalValue}>
                <Text>{formatCurrency(escrowReserves - fees)}</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}
