import React, { useMemo } from 'react'
import { GridContext } from '../../GridContext'
import PropTypes from 'prop-types'
import { S_Grid } from './Grid.styles'

export const Grid = ({ children, ...props }) => {
  const gridContextData = useMemo(() => ({ ...props }), [props.columns, props.gutters])

  return (
    <GridContext.Provider value={gridContextData}>
      <S_Grid {...props}>{children}</S_Grid>
    </GridContext.Provider>
  )
}

Grid.propTypes = {
  /**
   * Number of individual columns that the grid contains
   */
  columns: PropTypes.number,
  /**
   * Grid column gutter width. The value false collapses the gutters
   */
  gutters: PropTypes.number,
  /**
   * Grid column gutter width for breakpoint
   */
  guttersXs: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([false])]),
  guttersSm: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([false])]),
  guttersMd: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([false])]),
  guttersLg: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([false])]),
  guttersXl: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([false])]),
  /**
   * Additional ClassName for customization
   */
  className: PropTypes.string,
  /**
   * Applies 'align-items' for container.
   */
  alignItems: PropTypes.oneOf([
    'flex-start',
    'flex-end',
    'center',
    'space-between',
    'space-around',
    'space-evenly',
    'start',
    'end',
    'left',
    'right',
    'stretch',
  ]),
  /**
   * Applies 'justify-content' for container.
   */
  justifyContent: PropTypes.oneOf([
    'flex-start',
    'flex-end',
    'center',
    'space-between',
    'space-around',
    'space-evenly',
    'start',
    'end',
    'left',
    'right',
  ]),
}

Grid.defaultProps = {
  columns: 12,
  gutters: 15,
  guttersXs: false,
  guttersSm: false,
  guttersMd: false,
  guttersLg: false,
  guttersXl: false,
  className: '',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
}
