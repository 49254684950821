import * as XLSX from 'xlsx'
import { formatCurrency } from '../../../_helpers'
import DateFormatter from '../../../_helpers/date-formatter'

export const completedPaymentXlsx = (payment, escrowReserves, fees, columns) => {
  const calculateAdjustment = (invoice) => {
    const { action, invoice_amount, amount } = invoice
    const isShortPay = action === 'Short Pay'
    const isOverPay = action === 'Over Pay'
    if (isShortPay || isOverPay) {
      const adjust_value = amount - invoice_amount
      const adjust_amount = Math.abs(adjust_value)
      return Number(adjust_amount)
    }
    return ''
  }

  const calculateEscrowAmount = (invoice) => {
    const { advance_rate, invoice_amount, action } = invoice
    const reserve_rate = Number(advance_rate) ? 1 - Number(advance_rate) : 1

    if (action === 'Pay Closed' || action === 'Partial Pay' || action === 'Non Factored Payment') {
      return Number(0.0)
    }

    return Number(reserve_rate * invoice_amount)
  }

  const invoiceLine = (invoice) => {
    const timeFormatter = new DateFormatter(invoice.purchase_date)

    return [
      invoice.business_name,
      invoice.invoice_number,
      formatCurrency(invoice.invoice_amount),
      formatCurrency(invoice.amount),
      invoice.action,
      formatCurrency(invoice.invoice_balance),
      formatCurrency(calculateAdjustment(invoice)),
      timeFormatter.getDate('YYYY-MM-DD'),
      invoice.invoice_schedule_id,
      invoice.age,
      formatCurrency(calculateEscrowAmount(invoice)),
      formatCurrency(invoice.fees_earned),
    ]
  }

  const xlsxData = []
  xlsxData.push(['Debtor', 'Check/Transaction #', 'Payment Amount'])
  xlsxData.push([payment.debtor_name, payment.check_number, formatCurrency(payment.amount)])
  xlsxData.push([])
  xlsxData.push(columns.map((column) => column.headingText))
  payment.invoices.forEach((invoice) => xlsxData.push(invoiceLine(invoice)))
  xlsxData.push([])
  xlsxData.push(['Total Amount Paid', 'Applied to AR', 'Escrow Reserves Released', 'Client Reserves'])
  xlsxData.push([
    formatCurrency(payment.amount),
    formatCurrency(payment.amount),
    formatCurrency(escrowReserves),
    formatCurrency(escrowReserves - fees),
  ])

  let wb = XLSX.utils.book_new()
  let workSheet = XLSX.utils.aoa_to_sheet(xlsxData)
  XLSX.utils.book_append_sheet(wb, workSheet, 'Payment')
  XLSX.writeFile(wb, 'payment.xlsx')
}
