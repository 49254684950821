import styled, { css } from 'styled-components'

// Components
import { S_FileViewerInner } from '../../../FileViewer'
import { S_ControlsButton } from '../../../ControlsButton'
import { ButtonComponent } from '../../../Button'
const MAX_PREVIEW_WIDTH = 800

export const S_GalleryView = styled.div`
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 5px;

  ${(props) =>
    props.className &&
    props.className === 'slim-gallery-view' &&
    css`
      max-height: 100vh;
      height: 100%;
      overflow-y: scroll;
    `}

  .file-header,
    .left {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  h3 {
    display: flex;
    align-items: center;
    font-size: 17px;
    font-weight: normal;
    margin-right: 10px;
    svg {
      margin-right: 5px;
      width: 15px;
      height: 15px;
    }
  }

  .file-container {
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    padding: 10px;
  }

  ${S_FileViewerInner} {
    box-shadow: 0px 0px 6px #ddd;
    width: 200px;
  }

  .file-actions {
    display: flex;
    align-items: center;
  }

  ${S_ControlsButton}, ${ButtonComponent} {
    margin: 5px;

    height: 30px; /* TODO: make a convention to match pagination prev/next button sizes */
  }

  ${S_ControlsButton} {
    width: 30px;
  }

  .pdf-page {
    max-width: 100%;
    margin-top: 8px;
    border-style: solid;
    border-width: 1px;
    border-color: #ddd;
  }

  .overall-container {
    &.overall-container-expanded {
      ${S_FileViewerInner} {
        max-width: ${MAX_PREVIEW_WIDTH}px;
        width: 100%;
      }
    }
  }
`
