import React from 'react'
import {
  S_PrintAppDisclaimerText,
  S_PrintAppDisclaimer,
  S_PrintAppDisclaimerBody,
  S_PrintAppSubmissionData,
} from './PrintAppDisclaimer.styles'
import { FormField } from '../../Forms/FormField'
import { dateUTCAmPm } from '../../../_helpers'

export const PrintAppDisclaimer = ({ disclaimerHtml, deal }) => {
  if (!['submitted', 'active'].includes(deal.state)) return null

  const contact = deal?.business_contacts?.[0]
  const prospectName = `${contact?.name} ${contact?.last_name}`
  const date = new Date(deal.submission_date || deal.updated_at)
  const submissionDate = dateUTCAmPm(date)

  return (
    <S_PrintAppDisclaimer>
      <S_PrintAppDisclaimerBody>
        <FormField checked={true} disabled={true} type={'checkbox'} />
        <S_PrintAppDisclaimerText dangerouslySetInnerHTML={{ __html: disclaimerHtml }} data-testid="disclaimer-html" />
      </S_PrintAppDisclaimerBody>

      <S_PrintAppSubmissionData>
        Submitted and Consented to by: {prospectName} ({contact.email})
      </S_PrintAppSubmissionData>
      <S_PrintAppSubmissionData>Submitted: {submissionDate}</S_PrintAppSubmissionData>
    </S_PrintAppDisclaimer>
  )
}
