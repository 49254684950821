import { jsx as _jsx } from "react/jsx-runtime";
import { useMediaQuery } from 'xen-ui';
import { useAppShellNavBarContext, useXenTheme } from 'xen/providers';
import { NavItem } from '../../link';
export const NavBarLink = ({ ...props }) => {
    const theme = useXenTheme();
    const isMobileXS = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
    const { toggleNavBarState } = useAppShellNavBarContext();
    const toggleNavForMobile = () => {
        if (isMobileXS) {
            toggleNavBarState();
        }
    };
    return _jsx(NavItem.Link, { onClick: toggleNavForMobile, ...props });
};
