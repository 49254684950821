import { useTenant } from '../../_reduxHooks/tenant.hook'

export const UnderwritingChecklistSummary = ({ checklist, checklistAnswers, deal }) => {
  const { tenantObj } = useTenant()

  const styles = {
    page: {
      paddingBottom: '20px',
      fontSize: '16px',
    },
    lenderSection: {
      display: 'flex',
      justifyContent: 'center',
    },
    borrowerSection: {
      display: 'flex',
      justifyContent: 'center',
    },
    termsSection: {
      marginTop: '15px',
      minHeight: '80px',
      fontSize: '14px',
    },
    signatureField: {
      borderTop: '1px solid #000',
      width: '20%',
    },
    signaturesContainer: {
      marginTop: '20px',
    },
    signaturesSection: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: '15px',
      width: '100%',
    },
    smallerSignature: {
      display: 'flex',
      flexDirection: 'column',
      width: '15%',
    },
    leftSignatureSection: {
      display: 'flex',
      flexDirection: 'column',
    },
    debtorSection: {
      labels: {
        display: 'flex',
        flexDirection: 'row',
        color: '#9EAEB5',
      },
      values: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '5px',
      },
    },
  }

  const elements = checklist.map((checklistItem) => {
    const selectedAnswer = checklistAnswers?.[checklistItem.question]
    return {
      item: checklistItem.label,
      status: selectedAnswer,
    }
  })

  let terms = setTerms()

  function setTerms() {
    const cacheKey = `${deal.id}_terms`
    const savedTerms = localStorage.getItem(cacheKey)

    let terms = []

    if (savedTerms) {
      const parsedTerms = JSON.parse(savedTerms)

      terms.push({ key: 'Fee Alpha', value: parsedTerms.fee_alpha })
      terms.push({ key: 'Advance Rate', value: parsedTerms.advance_rate })
      terms.push({ key: 'Tier', value: parsedTerms.tier })
      terms.push({ key: 'Increment Period', value: parsedTerms.increment_period })
      terms.push({ key: 'Service Charge Date', value: parsedTerms.service_charge_date })
    }
    return terms
  }

  return (
    <div>
      <div style={styles.lenderSection}>
        <h1>{tenantObj.organization_name}</h1>
      </div>
      <div style={styles.borrowerSection}>
        <h2>{deal.business_name}</h2>
      </div>
      <div style={styles.page}>
        <div style={{ marginTop: '15px' }}>
          <Table data={elements} />
          <div style={styles.termsSection}>
            <Terms terms={terms} />
          </div>
          <div style={styles.signaturesContainer}>
            <div style={styles.signaturesSection}>
              <Signature styles={styles.signatureField} text="Monthly Volume" />
              <Signature styles={styles.signatureField} text="Salesperson" />
              <Signature styles={styles.signatureField} text="Brokers Name" />
              <Signature styles={styles.signatureField} text="Buyout" />
            </div>

            <div style={styles.signaturesSection}>
              <Signature styles={styles.signatureField} text="Credit Signature" />
              <Signature styles={styles.signatureField} text="Credit Signature" />
              <Signature styles={styles.signatureField} text="Credit Limit" />
            </div>

            <div style={styles.signaturesSection}>
              <Signature styles={styles.signatureField} text="Operations Signature" />
              <Signature styles={styles.signatureField} text="Operations Signature" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Term = ({ id, value }) => {
  return (
    <div>
      {id}: {value}
    </div>
  )
}

const Terms = ({ terms }) => {
  return (
    <div>
      {terms.map((term) => (
        <Term id={term.key} key={term.key} value={term.value} />
      ))}
    </div>
  )
}

const Signature = ({ text, styles }) => {
  return <div style={styles}>{text}</div>
}

const Table = ({ data }) => {
  const styles = {
    table: {
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
    },
    tableRow: {
      display: 'flex',
      width: '100%',
      margin: 'auto',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    tableCell: {
      width: '50%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      borderRightWidth: 0,
      borderColor: '#9EAEB5',
    },
  }
  return (
    <div style={styles.table}>
      {data.map((row) => (
        <div key={row.item} style={styles.tableRow}>
          <div style={styles.tableCell}>{row.item}</div>
          <div style={styles.tableCell}>{row.status}</div>
        </div>
      ))}
    </div>
  )
}
