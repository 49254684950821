import React from 'react'
import styled from 'styled-components'
// Components
import Table from '../../../_components/Table'
import { SplitButton, S_SplitButton } from '../../../_components/SplitButton'

// Helpers
import { formatPhoneNumber } from '../../../_helpers/formatting'
import { history } from '../../../_helpers/history'

// Constants
import { LENDER_ROUTES } from '../../../_constants/routes'

// Services
import { archiveBusiness, unArchiveBusiness } from '../../../_services/businesses.service'

const S_ProspectsTable = styled.div`
  ${S_SplitButton} {
    .split-button {
      margin-top: unset;
      .btn {
        line-height: 20px;
      }
      .btn-main {
        min-width: 75px;
      }
    }
    .dropdown-menu {
      top: 23px;
    }
  }
`

/**
 * DEPRECATED:
 * ListOverview will replace it.
 */
export const prospectColumns = [
  {
    accessor: 'business_name',
    headingText: 'Name',
  },
  {
    id: 'created_at',
    headingText: 'Date Created',
    accessor: (d) => {
      return d.created_at
    },
    Cell: (props) => <div className={`align-left`}>{new Date(props.value).toLocaleDateString()}</div>,
    type: 'date',
  },
  {
    id: 'business_phone_number',
    headingText: 'Business Phone',
    accessor: (d) => {
      return formatPhoneNumber(d.business_phone_number)
    },
  },
]

export const ProspectsTable = ({
  prospects,
  sorted = [],
  handleSort,
  pageSize,
  columns = prospectColumns,
  defaultSort = [{ id: 'business_name', desc: false, disableNoSort: true }],
  refreshData = () => {},
}) => {
  if (!prospects || prospects.length === 0) {
    return null
  }

  const lastCol = {
    accessor: `id`,
    headingText: '',
    disableSortBy: true,
    maxWidth: 120,
    Cell: (props) => (
      <div className={`align-right`}>
        <SplitButton
          buttonType="link"
          className="compact"
          dropdownButtons={[
            {
              label: props.row.original.archived ? 'Un-Archive' : 'Archive',
              function: async (e) => {
                let businessId = props.value
                await (props.row.original.archived ? unArchiveBusiness(businessId) : archiveBusiness(businessId))
                refreshData()
              },
            },
          ]}
          mainButton={{
            label: 'View',
            function: () => {
              history.push(`${LENDER_ROUTES.prospect_details}/${props.value}`)
            },
          }}
          text="View"
          to={`${LENDER_ROUTES.prospect_details}/${props.value}`}
        />
      </div>
    ),
  }

  return (
    <S_ProspectsTable>
      <Table
        className="with-border"
        columns={[...columns, lastCol]}
        data={prospects}
        defaultSort={defaultSort}
        onSortedChange={handleSort}
        pageSize={pageSize}
        sorted={sorted}
      />
    </S_ProspectsTable>
  )
}
