import React from 'react'
import { connect } from 'react-redux'

// Constants
import { metadataConfig } from '../../_configs'

// Components
import { S_Subtitle } from '../Typography/Titles.styles'

// Utilities
import { formatField } from '../../_helpers/formatters/index'

//Actions
import { modalActions } from '../../_actions'

// Styles
import { S_FormSummary, FieldGroup, S_PrintSummaryHeader, S_FormSectionFields } from './FormSummary.styles'
import { H4 } from '../Typography/Components/Headings/H4'
import { H3 } from '../Typography/Components/Headings/H3'
import colors from '../../_constants/colors'
import { isFeatureEnabled } from '../../_helpers/flags'
import { PrintAppDisclaimer } from './NewPrintAppDisclaimer/PrintAppDisclaimer'
import { dateUTCAmPm } from '../../_helpers'

const MetaFormDetailsSection = ({ metaForm }) => {
  return (
    <div className={`form-section`}>
      <p className="summary-paragraph">{metaForm.name}</p>
    </div>
  )
}

const Section = ({ section, groups, printVersion }) => {
  const groupNames = Object.keys(groups)

  const hasElements = groupNames.length > 0
  const hasMultipleGroups = groupNames.length > 1
  return (
    <div className={`form-section`}>
      <div className={`section-header`}>
        <H3 alignItems={'center'} display={'flex'} mb={'10px'} textColor={colors.BLACK} weight={'700'}>
          {section}
        </H3>
      </div>

      {!hasElements && <p className="summary-paragraph">You do not have any {section} set up.</p>}
      {hasElements &&
        groupNames.map((group) => {
          return (
            <Group
              fields={groups[group]}
              group={group}
              key={group}
              printVersion={printVersion}
              showGroupName={hasMultipleGroups}
            />
          )
        })}
    </div>
  )
}

const Group = ({ group, fields, printVersion, showGroupName }) => {
  return (
    <>
      {showGroupName && (
        <div className={`group-header`}>
          <H4 alignItems={'center'} display={'flex'} mb={'10px'} textColor={colors.BLACK} weight={'700'}>
            {group}
          </H4>
        </div>
      )}
      <div className={`form-group`}>
        <S_FormSectionFields>
          {fields.map((field) => {
            return (
              <Field
                field={field}
                key={`${field.label}${group}`}
                label={field.label}
                printVersion={printVersion}
                value={field.value}
              />
            )
          })}
        </S_FormSectionFields>
      </div>
    </>
  )
}

const isQuestion = (label) => label.charAt(label.length - 1) === '?'

const Field = ({ label, value, field, printVersion }) => {
  const NoValueString = 'N/A'

  const fieldValue = field.value ? formatField(field, value) : NoValueString
  const fieldLabel = isQuestion(label) ? label + ' ' : label + ': '

  return (
    <FieldGroup className={`field-group ${printVersion ? 'print-version' : ''}`}>
      <div className={`field`} key={0}>
        <label className={`label`}>{fieldLabel}</label>
        <span className={`value`}>
          <span>{fieldValue}</span>
        </span>
      </div>
    </FieldGroup>
  )
}

// TODO: Rethink this when working on custom signin form fields: https://finvoice-inc.atlassian.net/browse/FP-6033
const TENANTS_WITH_META_FORM_DETAILS = ['acsfactors']

const FormSummary = ({ tenant, deal, values, showTitle = true, printVersion = false, metaForm = null }) => {
  const metadata = metadataConfig[tenant]
  const sections = Object.keys(values)
  const disclaimerHtml = metaForm?.disclaimer?.body_html
  const showPrintDisclaimer = printVersion && isFeatureEnabled('print_application_disclaimer') && disclaimerHtml
  const showMetaFormDetails = TENANTS_WITH_META_FORM_DETAILS.includes(tenant)
  const submissionDate = deal?.submission_date ? dateUTCAmPm(new Date(deal.submission_date)) : '-'

  return (
    <S_FormSummary className={`section review-section ${printVersion ? 'print-version' : ''}`}>
      <div className={`review-section`}>
        {showTitle && (
          <S_Subtitle align={'center'} weight={'700'}>
            Review
          </S_Subtitle>
        )}
        {printVersion && (
          <S_PrintSummaryHeader>
            <S_Subtitle align={'center'} weight={'700'}>
              {metadata.tenant_name}
            </S_Subtitle>
            <S_Subtitle align={'center'} className={'section'} size={'md'} weight={700}>
              SELLER&apos;S APPLICATION
            </S_Subtitle>
            <p>The following background information was filled out online using Xen.</p>
            <p>Submitted date: {submissionDate}</p>
          </S_PrintSummaryHeader>
        )}
        <div className="sections" data-testid={'prospect-application-sections'}>
          {showMetaFormDetails && <MetaFormDetailsSection metaForm={metaForm} />}
          {sections.map((section, i) => {
            return <Section groups={values[section]} key={i + 1} printVersion={printVersion} section={section} />
          })}
        </div>

        {showPrintDisclaimer && <PrintAppDisclaimer deal={deal} disclaimerHtml={disclaimerHtml} />}
      </div>
    </S_FormSummary>
  )
}

function mapStateToProps(state) {
  const { env } = state
  return {
    tenant: env.tenant,
    state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (content) => {
      dispatch(modalActions.openModal(content))
    },
  }
}

const connectedFormSummary = connect(mapStateToProps, mapDispatchToProps)(FormSummary)

export { connectedFormSummary as NewProspectFormSummary }
