import styled, { css } from 'styled-components'

// Components
import { S_ButtonRow } from '../layout/ButtonRow'
import { S_ControlsButton } from '../ControlsButton'
import colors from '../../_constants/colors'
import { cssBreakpoints } from '../../_constants/styles'

export const S_FilesManager = styled.div`
  &.open {
    > header {
      margin-bottom: 15px;
    }
  }
  > header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 10px;
    .title {
      margin-right: 10px;
      font-size: 20px;
      color: ${colors.TEXT_DARK};
      font-weight: 400;
      @media screen and (max-width: ${cssBreakpoints.mobileLg}) {
        font-size: 16px;
      }
    }
    .left {
      display: flex;
      align-items: center;
    }
    .right {
      display: flex;
      align-items: center;
    }
    ${S_ControlsButton} {
      height: 25px;
      width: 25px;
    }
    ${S_ButtonRow} {
      margin-left: 25px;
    }
  }

  .dropzone,
  .inner-dropzone {
    border-radius: 8px;
  }

  .dropzone {
    display: none;
    box-shadow: 0px 0px 5px #e6e6e6;
    cursor: pointer;
    text-align: center;
    transition: background 0.3s;
    padding: 15px;

    @media screen and (max-width: ${cssBreakpoints.mobileLg}) {
      padding: 10px;
    }

    &.disabled {
      opacity: 0.5;
      cursor: default;
    }

    &.active {
      display: block;
    }

    &:hover:not(.disabled) {
      background: #f9f9f9;
    }
  }

  &.no-shadow {
    .dropzone {
      box-shadow: 0px 0px 0px;
      padding: 0px;
    }
  }

  .inner-dropzone {
    border: 2px dashed #ddd;
    display: flex;
    justify-content: center;
    padding: 30px 10px;
    height: 100%;
    align-items: center;

    @media screen and (max-width: ${cssBreakpoints.mobileLg}) {
      border-width: 1px;
      padding: 10px;
      font-size: 12px;
    }
  }

  .inner-dropzone.hidden {
    display: none;
  }
`

export const S_FilesManagerBrowser = styled.div`
  display: flex;

  .dropzone {
    flex: 0 0 auto;
    width: ${(props) => (props.files.length && props.viewType !== 'dropgallery' ? '50%' : '100%')};

    ${(props) =>
      props.viewType === 'list' &&
      css`
        @media screen and (max-width: ${cssBreakpoints.mobileLg}) {
          width: 30%;
        }
      `}
  }

  .view-container {
    width: 100%;
    padding: 0;

    ${(props) =>
      props.viewType === 'list' &&
      css`
        width: 50%;
        padding: 10px 20px;
        @media screen and (max-width: ${cssBreakpoints.mobileLg}) {
          width: 70%;
        }
      `}
  }
`
