import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, useForm } from 'react-hook-form';
import { z } from 'zod';
import { Button, Stack, Text } from 'xen-ui';
import { useCreateInvoiceVerification } from 'xen/api/use-invoice/use-create-invoice-verification';
import { TextLink } from 'xen/components';
import { FormDebtorContactSelect, FormInvoiceVerificationSelect, FormTextArea } from 'xen/components/form';
const invoiceVerificationFormSchema = z.object({
    verificationTypeId: FormInvoiceVerificationSelect.schema,
    contactId: FormDebtorContactSelect.schema.optional(),
    body: z.string(),
});
export const InvoiceVerificationForm = ({ invoice, onFormSubmit }) => {
    const { debtorId, debtorName, id: invoiceId } = invoice;
    const { mutate: mutateInvoiceVerification, isPending: isVerificationPending } = useCreateInvoiceVerification({
        urlParams: { invoiceId },
    });
    const defaultValues = {
        verificationTypeId: '',
        contactId: '',
        body: '',
    };
    const { control, handleSubmit } = useForm({
        resolver: zodResolver(invoiceVerificationFormSchema),
        mode: 'onBlur',
        values: defaultValues,
    });
    const onSubmit = async (data) => {
        mutateInvoiceVerification({
            payload: {
                body: data.body,
                contactId: data.contactId ? data.contactId : null,
                verificationTypeId: data.verificationTypeId,
            },
        });
        onFormSubmit();
    };
    const handleFormSubmit = () => {
        handleSubmit(onSubmit)();
    };
    return (_jsx(Form, { control: control, onSubmit: handleFormSubmit, children: _jsxs(Stack, { children: [_jsx(FormInvoiceVerificationSelect, { control: control, description: _jsxs(_Fragment, { children: ["View or edit ", _jsx(TextLink, { to: "/configurations/verification-types", children: "invoice verification types" })] }), name: "verificationTypeId", required: true }), debtorId && debtorName ? (_jsx(FormDebtorContactSelect, { control: control, debtorId: debtorId, description: _jsxs(_Fragment, { children: ["View or edit", ' ', _jsxs(TextLink, { to: "/debtors/:debtorId/contacts", urlParams: [debtorId], children: ["contacts for ", debtorName] })] }), name: "contactId" })) : (_jsx(Text, { children: "No debtor assigned to invoice" })), _jsx(FormTextArea, { control: control, label: "Notes", name: "body" }), _jsx(Button, { loading: isVerificationPending, onClick: handleFormSubmit, children: "Submit" })] }) }));
};
