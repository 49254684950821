import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

// Services
import { getInvoiceSchedules, getInvoiceSchedulesCount } from '../../../_services/lender.service'

// Components
import { PageContents } from '../../../_components/layout/PageContents'
import { ContentColumn } from '../../../_components/layout/ContentColumn'
import { ISTable, ISTableColumns } from '../ISTable'
import { S_SearchBar } from '../../../_components/SearchBar'
import { S_ItemsOverview } from '../../../_components/ItemsOverview'
import { SearchableOverview } from '../../../_components/SearchableOverview'
import { UploadInvoicesInline } from '../UploadInvoicesInline'

// Util
import { logError } from '../../../_helpers/errors'

// Constants
import { AppendParams } from '../../../_helpers/util'
import { INVOICE_SCHEDULES_FILTER_COLUMNS } from './constants'

// Styled Component
const S_NewCollateralPage = styled.div`
  .page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ${S_SearchBar}, ${S_ItemsOverview} {
    margin-bottom: 35px;
  }

  .button-row {
    display: flex;
    margin: 25px 0 0;
    justify-content: center;
  }
  .upload-btn-internal {
    display: flex;
    align-items: center;
  }
  .upload-btn-text {
    margin-left: 5px;
  }
`

class NewCollateralPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      updatedDate: new Date().toISOString(),
    }
  }

  componentDidMount() {
    this.getSchedules()
  }

  getSchedules() {
    // Get all schedules
    getInvoiceSchedules().then((response) => {
      this.setState({
        invoiceSchedules: response,
        updatedDate: new Date().toISOString(),
      })
    }, logError)
  }

  render() {
    const getFilter = (pathName) => {
      switch (pathName) {
        case '/schedules/pending':
          return '&state[]=0'
        case '/schedules/all':
          //all the other states
          return '&state[]=1&state[]=2&state[]=3&state[]=4'
        case '/schedules/approved':
          return '&state[]=6'
        case '/schedules/incomplete':
          return '&state[]=5'
        default:
          return ''
      }
    }

    const getPageTitle = (pathName) => {
      switch (pathName) {
        case '/schedules/pending':
          return 'Pending Schedules'
        case '/schedules/all':
          return 'Completed Schedules'
        case '/schedules/approved':
          return 'Approved Schedules'
        case '/schedules/incomplete':
          return 'Incomplete Schedules'
        default:
          return 'Invoice Schedules'
      }
    }

    const pendingOnly = this.props.location.pathname == '/schedules'
    const pageTitle = getPageTitle(this.props.location.pathname)
    const { updatedDate } = this.state
    return (
      <PageContents>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>

        <ContentColumn>
          <S_NewCollateralPage>
            <header className="page-header">
              <h1>{pageTitle}</h1>
              <UploadInvoicesInline
                onUploadComplete={() => {
                  this.getSchedules()
                }}
              />
            </header>

            <SearchableOverview
              change={pendingOnly + updatedDate}
              filterColumns={INVOICE_SCHEDULES_FILTER_COLUMNS}
              searchPlaceholder="Search Client Name"
              showSearchBar={true}
              showSearchFilter={true}
              views={[
                {
                  title: 'Schedules',
                  endpointCall: (searchParams) => {
                    const alreadySorted = searchParams.has('sort_by')
                    if (!alreadySorted) {
                      searchParams.set('sort_by', 'updated_at')
                      searchParams.set('sort_asc', false)
                    }
                    AppendParams(searchParams, getFilter(this.props.location.pathname))

                    const finalParams = searchParams.toString()
                    return Promise.all([getInvoiceSchedules(finalParams), getInvoiceSchedulesCount(finalParams)]).then(
                      (values) => ({
                        results: values[0],
                        total_results: values[1].count,
                      })
                    )
                  },
                  requestMethod: 'GET',
                  columns: ISTableColumns,
                  Component: ({ results, sort, handleSortChange, pageSize, columns }) => {
                    return (
                      <ISTable
                        columns={columns}
                        handleSortChange={handleSortChange}
                        invoiceSchedules={results}
                        pageSize={pageSize}
                        sorted={sort}
                      />
                    )
                  },
                },
              ]}
            />
          </S_NewCollateralPage>
        </ContentColumn>
      </PageContents>
    )
  }
}

function mapStateToProps(state) {
  const { user } = state
  return {
    user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (content) => {
      dispatch(modalActions.openModal(content))
    },
  }
}

const connectedNewCollateralPage = connect(mapStateToProps, mapDispatchToProps)(NewCollateralPage)
export { connectedNewCollateralPage as NewCollateralPage }
