import styled, { css } from 'styled-components'

// Components
import colors from '../../../_constants/colors'
import { cssBreakpoints } from '../../../_constants/styles'

export const S_ListRow = styled.div`
  background-color: ${colors.GREY_LIGHT_FILLS};
  border-radius: 3.6px;
  border: solid 1.5px ${colors.GREY};
  box-sizing: border-box;
  color: ${colors.TEXT_DARK};
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 16px;
  justify-content: space-between;
  letter-spacing: 0.4px;
  margin-bottom: 7px;
  padding: 16px 24px;
  position: relative;
  transition: background-color 0.2s ease-in-out;

  .title-badges {
    display: flex;
    flex-basis: 100%;
  }

  &:hover {
    background-color: ${colors.INNER_GREY_BOX};

    span {
      visibility: visible;
    }
  }

  svg {
    color: ${colors.TEXT_PRIMARY};
  }

  ${(props) =>
    props.isArchive &&
    css`
      color: ${colors.TEXT_PRIMARY};
    `};

  ${(props) =>
    props.isLoading &&
    css`
      justify-content: center;
      max-height: 60px;
    `};

  ${(props) =>
    !props.isSectionEnable &&
    css`
      cursor: default;
    `};

  @media screen and (max-width: ${cssBreakpoints.desktopSm}) {
    display: block;
  }
`

export const S_Actions = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  min-width: 350px;
  text-align: right;
  width: auto;
  margin-right: 20px;
  border-right: 1px solid ${colors.TEXT_PRIMARY};

  & > * {
    padding-right: 20px;
  }

  .icons {
    &:hover {
      svg {
        color: #00acdf;
      }
    }
  }

  @media screen and (max-width: ${cssBreakpoints.desktopSm}) {
    min-width: auto;
    margin: 10px 0;
    justify-content: flex-start;
    flex-wrap: wrap;
    border-right: none;
  }
`

export const S_Date = styled.span`
  color: ${colors.TEXT_PRIMARY};
  display: inline-block;
  font-size: 16px;
  letter-spacing: 0.5px;
  margin: 0 20px 0 0;
  width: 90px;
  @media screen and (max-width: ${cssBreakpoints.mobileLg}) {
    width: 100%;
    display: block;
    text-align: left;
    margin: 0;
    margin-bottom: 10px;
    border: none;
  }
`

export const S_Date_Separator = styled.div`
  color: ${colors.TEXT_PRIMARY};
  border-right: 1px solid ${colors.TEXT_PRIMARY};
  margin-right: 20px;
  @media screen and (max-width: ${cssBreakpoints.mobileLg}) {
    width: 100%;
    display: block;
    text-align: left;
    margin: 0;
    margin-bottom: 10px;
    border: none;
  }
`

export const S_Credit = styled.div`
  border-right: 1px solid ${colors.TEXT_PRIMARY};
  margin-right: 20px;
  @media screen and (max-width: ${cssBreakpoints.mobileLg}) {
    width: 100%;
    display: block;
    text-align: left;
    margin: 0;
    margin-bottom: 10px;
    border: none;
  }
`

export const S_BusinessName = styled.p`
  @media screen and (max-width: ${cssBreakpoints.mobileLg}) {
  }
`

export const S_CreatedDate = styled.div`
  color: gray;
  font-size: small;
  align-items: right;
`

export const S_Badges = styled.p`
  padding-left: 16px;
  padding-top: 2px;
  .small-badge {
    font-size: 10px;
    font-weight: bold;
  }
  @media screen and (max-width: ${cssBreakpoints.mobileLg}) {
  }
`

export const S_SalesPerson = styled.p`
  color: ${colors.TEXT_PRIMARY};
  font-size: 14px;
  letter-spacing: 0.5px;
  min-width: 5em;
`
