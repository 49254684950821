import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import env from 'env';
import { ActionIcon, Box, Center, Image, useMediaQuery, XenIcon } from 'xen-ui';
import { useFeatureFlags } from 'xen/hooks';
import { useAppShellNavBarContext, useConfigurationContext, useTenantContext, useXenTheme } from 'xen/providers';
const environment = env.name ?? 'development';
export const LenderNavBarHeader = () => {
    const tenant = useTenantContext();
    const configuration = useConfigurationContext();
    const { newConfigurationsAndSettingsUi: settingsUIEnabled } = useFeatureFlags();
    const theme = useXenTheme();
    const isMobileXS = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
    const { navBarOpened, toggleNavBarState } = useAppShellNavBarContext();
    const openClosePosition = () => {
        if (isMobileXS) {
            return navBarOpened ? '0rem' : '-2rem';
        }
        return navBarOpened ? '-.75rem' : '-2rem';
    };
    return (_jsx(_Fragment, { children: _jsxs(Center, { mih: "5rem", pos: "relative", children: [_jsx(Image, { alt: tenant.organizationName, flex: "none", h: "auto", mah: "100%", maw: "100%", p: "md", src: settingsUIEnabled
                        ? configuration.tenant.branding.images.logo
                        : `https://s3.amazonaws.com/finvoice-tenants/finvoice-app/${environment}/${tenant.token}/email_header_logo.png`, w: "auto" }), _jsx(Box, { bg: navBarOpened ? 'transparent' : 'var(--app-shell-color-bg)', bottom: "-.75rem", pl: navBarOpened ? 0 : 'xs', pos: "absolute", right: openClosePosition(), style: { borderRadius: '0 1rem 1rem 0' }, children: _jsx(ActionIcon, { "aria-label": "close payment", bd: "1px solid currentColor", bg: "var(--app-shell-color-bg)", c: "primary", onClick: toggleNavBarState, pos: "relative", radius: "xl", right: "0", style: { zIndex: 102 }, variant: "outline", children: navBarOpened ? _jsx(XenIcon.ChevronLeft, { size: "1em" }) : _jsx(XenIcon.ChevronRight, { size: "1em" }) }) })] }) }));
};
