import { tenantColors } from '../_constants/colors'
const badgeConfig = {
  republicbc: {
    business: [
      {
        title: 'Customer Of',
        fieldName: 'alias_tenant.organization_name',
        options: [
          {
            value: 'Republic Business Credit',
            display: 'Republic BC',
            color: 'white',
            backgroundColor: tenantColors['republicbc'].primary,
          },
          {
            value: 'Fast AR Funding',
            display: 'Fast AR',
            color: tenantColors['fastarfunding'].primary,
            backgroundColor: '#11B5EA',
          },
        ],
      },
    ],
  },
}

const badgeHandler = {
  get: (target, prop, receiver) => {
    if (target[prop]) {
      return target[prop]
    }
    return undefined // can return defaults here if we have them
  },
}

export default new Proxy(badgeConfig, badgeHandler)
