import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { PieChart } from '../../../../components/Chart/PieChart/PieChart'

// Action Hooks
import { modalActionHooks } from '../../../../_actionHooks'

// Selectors
import { modalSelectors } from '../../../../_selectors/modal.selectors'

// Services
import { getDebtors, getDebtorsCount, getTenantAccountBalance } from '../../../../_services/lender.service'

// Components
import { PageContents } from '../../../../_components/layout/PageContents'
import { ContentColumn } from '../../../../_components/layout/ContentColumn'
import { DebtorsTable, DebtorTableColumns } from '../../../LenderViews/DebtorsTable'
import { SearchableOverview } from '../../../../_components/SearchableOverview'
import { Button } from '../../../../_components/Button'
import { CreateDebtorModal } from './CreateDebtorModal'
import { LenderOverviewTiles } from '../../../../_components/LenderOverviewTiles'
import { TopClientsSlider } from '../../../LenderViews/ClientDetails/views/TopClientsSlider/TopClientsSlider'

// Styles
import { S_DebtorsOverview } from './DebtorsOverview.styles'
import { S_TileTitle } from '../../../../_components/TableTile/TileTitle.styles'

// Helpers
import { formatCurrency, formatPercentage } from '../../../../_helpers/formatting'

const Summary = () => {
  const [openInvoiceTotalBalance, setOpenInvoiceTotalBalance] = useState(0)
  const [debtorCount, setDebtorCount] = useState(0)
  const [topTenDebtors, setTopTenDebtors] = useState([])
  const [selectedDebtor, setSelectedDebtor] = useState()
  useEffect(() => {
    getTenantAccountBalance('accounts_receivable').then((balance) => setOpenInvoiceTotalBalance(balance.balance))
    getDebtorsCount('count=10&start_index=0&type=tenant').then((resp) => {
      setDebtorCount(resp.count)
    })
    getDebtors('count=10&start_index=0&type=tenant&sort_by=ar_balance&sort_asc=false').then((debtors) => {
      setTopTenDebtors(
        debtors.map((d) => {
          d.balance = Number(d.ar_balance)
          d.ar_balance = Number(d.ar_balance)
          return d
        })
      )
      if (!selectedDebtor) {
        setSelectedDebtor(debtors[0])
      }
    })
  }, [])

  const chart = () => {
    const topFiveDebtors = topTenDebtors.slice(0, 5)

    return (
      <>
        <S_TileTitle>Concentration</S_TileTitle>
        <div className={'chart-text-container'}>
          <div className={'text-container'}>
            <div className={'text-label'}>Debtor</div>
            <div className={'text-value'}>{selectedDebtor.display_name || selectedDebtor.debtor_name}</div>
            <hr />
            <div className={'text-label'}>Balance</div>
            <div className={'text-value'}>{formatCurrency(selectedDebtor.ar_balance || 0)}</div>
            <hr />
            <div className={'text-label'}>Percent of Total</div>
            <div className={'text-value'}>{formatPercentage(selectedDebtor.concentration || 0)}</div>
          </div>

          <PieChart
            data={Object.keys(topFiveDebtors).map((key) => topFiveDebtors[key])}
            dataKey="ar_balance"
            nameKey="debtor_name"
            onClick={(e1) => {
              setSelectedDebtor(e1)
            }}
            outerRadius="95%"
            toolTipFormatter={(value, name) => [`${formatCurrency(value)}`, name]}
          />
        </div>
      </>
    )
  }

  return selectedDebtor ? (
    <LenderOverviewTiles
      largeTiles={true}
      overviewTiles={[
        {
          type: 'multiple',
          linkText: null,
          noClick: true,
          tileClass: 'lg-33',
          content: [
            {
              label: 'Total Outstanding',
              type: 'currency',
              value: formatCurrency(openInvoiceTotalBalance),
            },
            {
              label: 'Total Number of Debtors',
              type: 'number',
              value: debtorCount,
            },
          ],
        },
        {
          type: 'custom',
          tileClass: 'lg-33',
          component: chart,
          disableHover: true,
          layout: 'chart-tile',
        },
        {
          type: 'custom',
          tileClass: 'lg-33',
          layout: 'no-flex',
          noClick: true,
          component: () => <TopClientsSlider topTenDebtors={topTenDebtors} />,
        },
      ]}
    />
  ) : null
}
export const DebtorsOverview = () => {
  const openModal = modalActionHooks.useOpenModal()
  const modal = modalSelectors.useModal()
  return (
    <PageContents>
      <Helmet>
        <title>Debtors Overview</title>
      </Helmet>

      <ContentColumn>
        <S_DebtorsOverview>
          <header>
            <h1>Debtors Overview</h1>
            <Button onClick={() => openModal(CreateDebtorModal)}>Create Debtor</Button>
          </header>
          <Summary />
          {/* Debtor Table */}
          <SearchableOverview
            key={modal.is_open} // reload after closing modal
            searchParam={`debtor_name`}
            searchPlaceholder="Filter by Debtor Name"
            showSearchBar={true}
            views={[
              {
                title: 'All Debtors',
                exactMatch: true,
                endpointCall: (searchParams) => {
                  searchParams.set('type', 'tenant')
                  return Promise.all([
                    getDebtorsCount(searchParams.toString()),
                    getDebtors(searchParams.toString()),
                  ]).then((values) => ({
                    total_results: values[0].count,
                    results: values[1],
                  }))
                },
                columns: DebtorTableColumns,
                Component: ({ results, handleSortChange, pageSize, columns }) => (
                  <DebtorsTable
                    columns={columns}
                    debtors={results}
                    handleSortChange={handleSortChange}
                    pageSize={pageSize}
                  />
                ),
              },
            ]}
          />
        </S_DebtorsOverview>
      </ContentColumn>
    </PageContents>
  )
}
