import request from '../axios_service'

export function getHelloSignTemplates(context_type = null) {
  const queryParam = context_type ? `?context_type=${context_type}` : ''
  return request({
    url: `/v2/documents/templates${queryParam}`,
    method: 'GET',
  })
}

export function createSignatureRequest(data) {
  return request({
    url: `/v2/signature_requests`,
    method: 'POST',
    data: data,
  })
}

export function previewSignatureRequest(data) {
  return request({
    url: `/v2/signature_requests/preview`,
    method: 'POST',
    data: data,
    responseType: 'arraybuffer',
  })
}

export function createFactoringAgreement(data) {
  return request({
    url: `/v2/factoring_agreements`,
    method: 'POST',
    data: data,
  })
}

export function previewFactoringAgreement(data) {
  return request({
    url: `/v2/factoring_agreements/preview`,
    method: 'POST',
    data: data,
    responseType: 'arraybuffer',
  })
}

export function getSignatureRequest(searchParams) {
  return request(
    {
      url: `/v2/signature_requests?${searchParams}`,
      method: 'GET',
    },
    null,
    true
  )
}

export function getSignatureRequestUrl(global_key) {
  return request({
    url: `/v2/signature_requests/signature_url/${global_key}`,
    method: 'GET',
  })
}

export function getSignatureRequestsStatuses(invoice_schedule_id) {
  return request({
    url: `/v2/signature_requests/invoice_schedules/${invoice_schedule_id}`,
    method: 'GET',
  })
}

export function sendSignatureRequestReminder(signature_request_id, signature_request_signer_id) {
  return request({
    url: `/v2/signature_requests/${signature_request_id}/signature_request_signers/${signature_request_signer_id}/send_reminder`,
    method: 'POST',
    data: {},
  })
}

export async function getESigningRequests(searchParams = '') {
  const response = await request(
    {
      url: `/v2/signature_requests/pending?${searchParams}`,
      method: 'GET',
    },
    null,
    true
  ).then((resp) => {
    // Make the new response the same shape as the old one
    return {
      count: Number(resp.headers['total-count']),
      signature_requests: resp.data,
    }
  })
  return new Promise((resolve) => resolve(response))
}
