import styled from 'styled-components'

import { Button } from '../../../Button'
import colors from '../../../../_constants/colors'
import { cssBreakpoints } from '../../../../_constants/styles'

export const S_Button = styled(Button)`
  margin-top: 24px;
`

export const S_ListItemText = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.DEFAULT_TEXT};
  svg {
    margin-right: 2px;
  }
`

export const S_ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 3px;
  border-bottom: 1px solid ${colors.INNER_GREY_BOX};
  &:last-child {
    border-bottom: 0;
  }

  @media screen and (max-width: ${cssBreakpoints.mobileLg}) {
    font-size: 14px;
  }

  .secondary {
    margin-right: 8px;
  }
  .download-button {
    margin-right: 8px;
  }
  .delete-button {
    margin-right: 8px;
  }
  .delete-button:last-child {
    margin-right: 0;
  }
`
export const S_FileActions = styled.div`
  min-width: 78px;
  @media screen and (max-width: ${cssBreakpoints.mobileLg}) {
    min-width: auto;
  }
`
