import { STD_TERMS_OF_SALE_OPTIONS } from '../form.constants'

export const printAppConfig = {
  sevenoaks: {
    sections: [
      {
        title: 'Business Information',
        fields: [
          {
            type: 'text',
            name: 'business_name',
            table: 'business',
            label: 'Name (as refered to in Legal Documents)',
          },
          {
            type: 'text',
            name: 'business_entity',
            table: 'business',
            label: 'Form',
          },
          {
            type: 'text',
            name: 'dba',
            table: 'business',
            label: 'DBA',
          },
          {
            type: 'text',
            name: 'full_business_address', // Need to add config for that
            table: 'business',
            label: 'Address',
          },
          {
            type: 'text',
            name: 'state_of_incorporation',
            table: 'business',
            label: 'Incorporation State',
          },
          {
            type: 'text',
            name: 'business_phone_number',
            table: 'business',
            label: 'Phone #',
          },
          {
            type: 'business_ein',
            name: 'business_ein_last4',
            table: 'business',
            label: 'Federal I.D Number',
          },
          {
            type: 'text',
            name: 'business_industry',
            table: 'business',
            label: 'Industry',
          },
        ],
      },
      {
        title: 'User Information',
        fields: [
          {
            type: 'text',
            name: 'first_name',
            table: 'user',
            label: 'First Name',
          },
          {
            type: 'text',
            name: 'last_name',
            table: 'user',
            label: 'Last Name',
          },
          {
            type: 'text',
            name: 'title',
            table: 'user',
            label: 'Title',
          },
          {
            type: 'tel',
            name: 'email_address',
            table: 'user',
            label: 'Email Address',
          },
          {
            type: 'tel',
            name: 'phone_number',
            table: 'user',
            label: 'Phone Number',
          },
          {
            type: 'date',
            name: 'created_at',
            table: 'business',
            label: 'Sign Up Date',
          },
          {
            type: 'text',
            name: 'deal_status',
            table: 'deal',
            label: 'Borrower Status',
          },
        ],
      },
      {
        title: 'Business Officers',
        type: 'business_officers',
        label: 'Business Officer',
        fields: [
          {
            type: 'text',
            name: 'first_name',
            table: 'business_officers',
            label: 'First Name',
            placeholder: 'First Name',
          },
          {
            type: 'text',
            name: 'last_name',
            table: 'business_officers',
            label: 'Last Name',
            placeholder: 'Last Name',
          },
          {
            type: 'text',
            name: 'title',
            table: 'business_officers',
            label: 'Title',
            placeholder: 'Title',
          },
          {
            type: 'percentage',
            name: 'pct_ownership',
            table: 'business_officers',
            label: 'Percent Ownership',
            placeholder: 'Percent Ownership',
          },
          {
            type: 'tel',
            name: 'phone_number',
            table: 'business_officers',
            label: 'Phone',
            placeholder: 'Phone',
          },
          {
            type: 'ssn',
            name: 'ssn_last4',
            table: 'business_officers',
            label: 'Social Security Number',
          },
          {
            type: 'email',
            name: 'email_address',
            table: 'business_officers',
            label: 'Email',
          },
          {
            type: 'date',
            name: 'dob',
            table: 'business_officers',
            label: 'Date of Birth',
          },
          {
            type: 'text',
            name: 'drivers_license',
            table: 'business_officers',
            label: 'Drivers License',
          },
          {
            type: 'text',
            name: 'address',
            table: 'business_officers',
            label: 'Address',
          },
          {
            type: 'text',
            name: 'city',
            table: 'business_officers',
            label: 'City',
          },
          {
            type: 'text',
            name: 'state',
            table: 'business_officers',
            label: 'State',
          },
          {
            type: 'text',
            name: 'zip',
            table: 'business_officers',
            label: 'ZIP Code',
          },
        ],
      },
      {
        title: 'Business Profile',
        fields: [
          {
            type: 'text',
            name: 'business_name',
            table: 'business',
            label: 'Business Name',
          },
          {
            type: 'tel',
            name: 'fax_number',
            table: 'business',
            label: 'Fax Number',
          },
          {
            type: 'text',
            name: 'business_website',
            table: 'business',
            label: 'Website',
          },
          {
            type: 'text',
            name: 'referral_source',
            table: 'business',
            label: 'Referral Source',
          },
          {
            type: 'business_ein',
            name: 'business_ein_last4',
            table: 'business',
            label: 'Federal I.D Number',
          },
          {
            type: 'text',
            name: 'monthly_sales_range',
            table: 'business',
            label: 'Monthly Sales Range',
          },
          {
            type: 'currency',
            name: 'present_acct_receivables',
            table: 'business',
            label: 'Present Account Receivables Value',
          },
          {
            type: 'currency',
            name: 'present_acct_receivables',
            table: 'business',
            label: 'Present Account Receivables Value',
          },
          {
            type: 'date',
            name: 'date_business_started',
            table: 'business',
            label: 'Date Business Started',
          },
          {
            type: 'text',
            name: 'mc',
            table: 'business',
            label: 'MC',
          },
          {
            type: 'text',
            name: 'trucking_entity_type',
            table: 'business',
            label: 'Carrier/Broker',
          },
          {
            type: 'std_terms_sale',
            name: 'std_terms_of_sale',
            table: 'business',
            label: 'Standard Terms of Sale',
            options: STD_TERMS_OF_SALE_OPTIONS,
          },
          {
            type: 'radio',
            name: 'outstanding_judgements_liens',
            table: 'business',
            label: 'Has Outstanding Judgements Liens',
            options: [
              {
                label: 'Yes',
                value: true,
              },
              {
                label: 'No',
                value: false,
              },
            ],
            following_question: {
              condition: true,
              type: 'textarea',
              name: 'judgement_lien_explain',
              table: 'additional_business_data',
              label: 'Please Explain',
            },
          },
          {
            type: 'radio',
            name: 'has_previously_factored',
            table: 'business',
            label: 'Has Previously Factored',
            options: [
              {
                label: 'Yes',
                value: true,
              },
              {
                label: 'No',
                value: false,
              },
            ],
            following_question: {
              condition: true,
              type: 'textarea',
              name: 'previously_factored_explain',
              table: 'business',
              label: 'What company/companies have you factored with?',
            },
          },
        ],
      },
      {
        title: 'Document Details',
        type: 'upload_documents',
        fields: [
          {
            type: 'upload_document',
            name: 'aged_receivables',
            table: 'deal',
            label: 'Aged Receivables',
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'void_check',
            table: 'deal',
            label: 'Void Company Check',
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'drivers_license',
            table: 'deal',
            label: `Copy of Driver's License`,
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'customer_list',
            table: 'deal',
            label: 'Customer List',
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'articles_of_incorporation',
            table: 'deal',
            label: 'Articles of Incorporation',
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'proof_of_insurance',
            table: 'deal',
            label: 'Proof of Insurance (SevenOaks Capital as Certificate Holder)',
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'mc_number',
            table: 'deal',
            label: 'MC Number Certification',
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'tax_certification',
            table: 'deal',
            label: 'Tax ID Number & Certification',
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
          {
            type: 'upload_document',
            name: 'w9',
            table: 'deal',
            label: 'W-9',
            options: [
              {
                label: 'Upload file',
                value: 'upload',
              },
              {
                label: 'I will do it later',
                value: 'later',
              },
            ],
          },
        ],
      },
    ],
  },
}
