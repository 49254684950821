import React from 'react'
import styled from 'styled-components'

// Components
import Table from '../../../_components/Table'
import { S_EmptyMessage } from '../../../_components/EmptyMessage'
import { Button } from '../../../_components/Button'
import { SigningStatus } from '../../../_components/SigningStatus'
import { EsigningDocument } from '../../../_components/modals/esigning/EsigningDocument'

// Utils
import { formatValueByType } from '../../../_helpers'

// Services
import { downloadDocument } from '../../../_services'

const getSignerName = (document, type) => `${document[type]['first_name'] || ''} ${document[type]['last_name'] || ''}`

const S_EsigningTable = styled.div`
  .page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const statusMap = (signer) => {
  if (!signer) return ''
  if (signer.signed_at) return 'signed'
  if (signer.viewed_at) return 'viewed'
  return 'sent'
}

const formatData = (documents) =>
  documents.map((document) => {
    const lender = document.signers.find((signer) => signer.role === 'Tenant')
    const borrower = document.signers.find((signer) => signer.role === 'ExternalContact')

    return {
      ...document,
      file_title: document.document.template.label,
      business_name: document.business_name,
      created_at: formatValueByType(Date.parse(document.created_at), 'date'),
      borrower_status: {
        status: statusMap(borrower),
        signer: borrower?.name ?? '',
      },
      lender_status: {
        status: statusMap(lender),
        signer: lender?.name ?? '',
      },
      isCompleted: document.state !== 'pending',
      sign_url: lender?.sign_url,
    }
  })

function handleDownloadDocument(document, extra) {
  downloadDocument(document['document_id'])
}

function handleViewDocument(document, extra) {
  const { switchModal, openEsigningDocument, user, env, lastDoc } = extra
  switchModal(EsigningDocument, { className: 'mobile-full-screen-modal' })
  openEsigningDocument(document, user, env, lastDoc)
}

const tableTypes = {
  business: { hideBusiness: true, hideStatus: false, action: handleDownloadDocument, actionText: 'Download' },
  user: { hideBusiness: false, hideStatus: false, action: handleDownloadDocument, actionText: 'Download' },
  lender_pending: { hideBusiness: false, hideStatus: true, action: handleViewDocument, actionText: 'View' },
  borrower_pending: { hideBusiness: true, hideStatus: true, action: handleViewDocument, actionText: 'View' },
}

const statusValues = {
  sent: '0',
  viewed: '1',
  signed: '2',
}

const sortStatusMethod = (a, b) => {
  let labelA = statusValues[a.status] + a.signer
  let labelB = statusValues[b.status] + b.signer

  return labelA.localeCompare(labelB)
}

export const EsigningTable = ({
  documents,
  tableType,
  isUserLender = true,
  sorted = [],
  handleSort = () => {},
  pageSize,
  extra,
  ...rest
}) => {
  if (!documents || documents.length === 0) {
    return null
  }
  const pending = tableType === 'lender_pending' || tableType === 'borrower_pending'

  return (
    <S_EsigningTable>
      {documents.length > 0 ? (
        <Table
          className="with-border"
          columns={[
            {
              accessor: 'file_title',
              headingText: 'Title',
            },
            {
              accessor: 'business_name',
              headingText: 'Business',
              hide: tableTypes[tableType].hideBusiness,
            },
            {
              accessor: 'created_at',
              headingText: 'Created',
              sortMethod: (a, b) => {
                return Date.parse(a) - Date.parse(b)
              },
            },
            {
              accessor: 'borrower_status',
              headingText: 'Borrower status',
              hide: tableTypes[tableType].hideStatus,
              Cell: (props) => {
                const status = props.value.status
                const signer = props.value.signer
                return <SigningStatus name={signer} status={status} />
              },
              sortMethod: sortStatusMethod,
            },
            {
              accessor: 'lender_status',
              headingText: 'Lender status',
              hide: tableTypes[tableType].hideStatus,
              Cell: (props) => {
                const status = props.value.status
                const signer = props.value.signer
                return <SigningStatus name={signer} status={status} />
              },
              sortMethod: sortStatusMethod,
            },
            {
              accessor: `document_id`,
              headingText: '',
              disableSortBy: true,
              maxWidth: 120,
              Cell: (props) => {
                const document = props.row.original
                return (
                  <div className={`align-right`}>
                    <Button
                      className="compact"
                      disabled={!pending && (!document.isCompleted || !document['document_id'])}
                      onClick={() => tableTypes[tableType].action(document, extra)}
                      text={tableTypes[tableType].actionText}
                    />
                  </div>
                )
              },
            },
          ]}
          data={formatData(documents)}
          onSortedChange={handleSort}
          pageSize={pageSize}
          sorted={sorted}
          {...rest}
        />
      ) : (
        <S_EmptyMessage>No documents found </S_EmptyMessage>
      )}
    </S_EsigningTable>
  )
}
