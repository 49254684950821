import styled from 'styled-components'
import { S_Checkbox } from '../../Forms/FormField/CustomFields/Checkbox/Checkbox.styles'

export const S_PrintAppDisclaimerText = styled.div`
  font-size: 14px;
  padding-left: 5px;
`

export const S_PrintAppDisclaimerBody = styled.div`
  display: flex;

  ${S_Checkbox} {
    filter: brightness(0);
    opacity: 1;
  }
`

export const S_PrintAppSubmissionData = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
`

export const S_PrintAppDisclaimer = styled.div`
  padding: 15px 0;
`
