import React from 'react'
import { connect } from 'react-redux'
import { FaEdit } from 'react-icons/fa'

// Constants
import { formConfig, metadataConfig } from '../../_configs'

// Components
import Field from '../FieldNew'
import { DisclaimerFull } from '../modals/disclaimer/FullDisclaimer'
import { S_Subtitle } from '../Typography/Titles.styles'
import { Button } from '../Button'

// Utilities
import { formatCurrency, formatValueByType } from '../../_helpers/formatting'
import { getFormikName } from '../../_helpers/forms'

//Actions
import { modalActions } from '../../_actions'

// Styles
import { S_FormSummary, FieldGroup, S_PrintSummaryHeader, S_FormSectionFields } from './FormSummary.styles'
import { useFlags } from '../../_reduxHooks/flags.hook'
import { isFeatureEnabled } from '../../_helpers/flags'
import { PrintAppDisclaimer } from './PrintAppDisclaimer/PrintAppDisclaimer'
import { H4 } from '../Typography/Components/Headings/H4'
import { printAppConfig } from '../../_configs/formConfig/printApp.config'
import { getBusinessFullAddress } from '../../_helpers/business'
import { H3 } from '../Typography/Components/Headings/H3'
import colors from '../../_constants/colors'
const NoValueString = 'N/A'

const SummaryField = ({
  field,
  values,
  index,
  state,
  showPii = false,
  setShowPii = () => {},
  officer_index = -1,
  printVersion = false,
  className = '',
  isFollowingQuestion = false,
}) => {
  let fieldForShow = { ...field }
  const fieldMap = {
    ssn_last4: `officer${officer_index}_ssn`,
    business_ein_last4: `business_ein`,
    aba_routing_ach_last3: 'account_number',
    aba_routing_wire_last3: 'aba_routing_ach',
    account_number_last4: 'aba_routing_wire',
  }
  const encryptedFieldTypes = ['account_number', 'ssn', 'business_ein', 'aba_routing_ach', 'aba_routing_wire']
  const isEncrypted = encryptedFieldTypes.includes(field.type)
  const showStars = isEncrypted && !showPii
  const isLender = state?.user?.user_type === 'lender'

  if (isEncrypted && showPii) {
    fieldForShow.name = fieldMap[fieldForShow.name]
  }

  if ((field.type === 'business_ein' || field.type === 'ssn') && showPii) {
    fieldForShow.table = 'business'
  }

  if (field.type === 'ssn' && isLender && !printVersion) {
    field.displayButton = true
  }

  let value = getFormikValue(values, fieldForShow, index, showStars, printVersion)
  const noValue = value === NoValueString

  if (noValue && printVersion && isFollowingQuestion) {
    return null
  }

  if (noValue) {
    value = <span className={`no-value`}>N/A</span>
  }

  return (
    <FieldGroup
      className={`field-group ${className} ${printVersion ? 'print-version' : ''}`}
      hasFollowingQuestion={field.following_question}
    >
      <div className={`field ${field.table}`} key={0}>
        <label className={`label`}>
          {field.label}
          {printVersion ? ': ' : ''}
        </label>
        <span className={`value`} data-testid={getFormikName(field, index)}>
          <span>{value}</span>
          {field.displayButton && <ShowHidePiiButton setShowPii={setShowPii} showPii={showPii} />}
        </span>
      </div>
      {field.following_question && (
        <SummaryField
          className={'following_question'}
          field={field.following_question}
          isFollowingQuestion={true}
          key={1}
          printVersion={printVersion}
          values={values}
        />
      )}
    </FieldGroup>
  )
}

const getFormikValue = (values, field, index = null, showStars, printVersion = false) => {
  const { table, type } = field
  let { name } = field
  let tableValues = values[table]
  if (index !== null) {
    tableValues = values[table][index] || tableValues
  }
  const noValue = NoValueString
  const encryptedPart = showStars ? '***-***-' : ''

  if (type === `upload_document`) {
    name = `have_${name}`
  }

  if (name === 'full_business_address') {
    return getBusinessFullAddress(tableValues)
  }

  if (name === 'deal_status') {
    const status = tableValues[name]
    const hasSubmitted = status === 'funded' || status === 'submitted'
    return hasSubmitted ? 'Submitted an application' : 'Not submitted an application yet'
  }

  if (!tableValues || tableValues[name] === null || tableValues[name] === undefined || tableValues[name] === '') {
    return type === 'upload_document' ? 'Not Uploaded' : noValue
  } else {
    // Format specific form values
    const tableValue = tableValues[name].toString()

    if (type === 'radio') {
      return tableValue == 'true' ? 'Yes' : 'No'
    }
    if (type === 'upload_document') {
      if (tableValue == 'true') {
        return 'Document uploaded'
      }
      if (!tableValue || tableValue === '0' || tableValue === 'later') {
        return 'Not Uploaded'
      }
    }
    if (type === 'date') {
      if (tableValue) {
        let value = tableValue.includes('T') ? tableValue.split('T')[0] : tableValue
        const [year, month, day] = value.split('-')
        return new Date(year, month - 1, day).toLocaleDateString() || noValue
      }
      return noValue
    }
    if (type === 'currency') {
      if (tableValue === '') {
        return noValue
      } else {
        return formatCurrency(parseFloat(tableValue.toString().replace(/[^\d\.]/g, '')))
      }
    }
    if (type === 'multiselect') {
      return field.options
        .map((opt) => {
          if (values[field.table][opt.field_name]) {
            return opt.label
          } else {
            return ''
          }
        })
        .filter((str) => str != '')
        .join(', ')
    }
    if (type === 'select') {
      const value = values[field.table][field.name]
      if (!value) {
        return ''
      }
      const option = field.options.find((option) => option.value == value)
      if (!option || !option.label) {
        return ''
      }
      return option.label
    }
    if ((printVersion && type === 'percent') || type === 'percentage') {
      return `${tableValues[name]}%`
    }
    return `${encryptedPart} ${tableValues[name].toString()}`
  }
}

const FormSummary = ({
  tenant,
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  handleEditSection,
  state,
  openModal,
  showTitle = true,
  showEdit = true,
  showPii = false,
  setShowPii = () => {},
  printVersion = false,
}) => {
  let formConfiguration = formConfig[tenant]
  const tenantPrintConfig = printAppConfig[tenant]
  const tenantFormConfig = printVersion && tenantPrintConfig ? tenantPrintConfig : formConfiguration
  const metadata = metadataConfig[tenant]
  const { featureFlags } = useFlags()
  const showPrintDisclaimer = printVersion && isFeatureEnabled('print_application_disclaimer', featureFlags)

  const submittedAt = formatValueByType(values.deal?.submitted_at, 'truncatedDate')

  return (
    <S_FormSummary className={`section review-section ${printVersion ? 'print-version' : ''}`}>
      <div className={`review-section`}>
        {showTitle && (
          <S_Subtitle align={'center'} weight={'700'}>
            Review
          </S_Subtitle>
        )}
        {printVersion && (
          <S_PrintSummaryHeader>
            <S_Subtitle align={'center'} weight={'700'}>
              {metadata.tenant_name}
            </S_Subtitle>
            <S_Subtitle align={'center'} className={'section'} size={'md'} weight={700}>
              SELLER&apos;S APPLICATION
            </S_Subtitle>
            <p>The following background information was filled out online using Xen.</p>
            <p>Submitted date: {submittedAt}</p>
          </S_PrintSummaryHeader>
        )}
        <div className="sections" data-testid={'prospect-application-sections'}>
          {tenantFormConfig.sections.map((section, i) => (
            <div className={`form-section`} key={i}>
              <div className={`section-header`}>
                <H3
                  alignItems={'center'}
                  display={'flex'}
                  mb={'10px'}
                  textColor={printVersion ? colors.BLACK : colors.TEXT_DARK}
                  weight={'700'}
                >
                  {section.title}
                  {showEdit && <FaEdit className={`edit-section-icon`} onClick={() => handleEditSection(i)} />}
                </H3>
              </div>
              {section.type === `business_officers` ? (
                values.business_officers.length === 0 ? (
                  <p className="summary-paragraph">You do not have any {section.label}'s set up.</p>
                ) : (
                  values.business_officers.map((businesssOfficer, i) => (
                    <div className={`business-officer`} key={i}>
                      <H4 mb={'5px'} mt={'10px'} textColor={printVersion ? colors.BLACK : colors.TEXT_DARK}>
                        {`${section.label} ${i + 1}`}
                      </H4>
                      <S_FormSectionFields className={'business-officers'}>
                        {section.fields.map((field, ii) => {
                          const isDocument = field?.type?.includes('upload_document')
                          return (
                            <SummaryField
                              field={{
                                ...field,
                                name: isDocument ? `bo_${i + 1}_${field.name}` : field.name,
                              }}
                              index={i}
                              key={ii}
                              officer_index={businesssOfficer?.ordinal || 0}
                              printVersion={printVersion}
                              setShowPii={setShowPii}
                              showPii={showPii}
                              values={values}
                            />
                          )
                        })}
                      </S_FormSectionFields>
                    </div>
                  ))
                )
              ) : section.type === `largest_customers` ? (
                values.top_business_debtors.length === 0 ? (
                  <p className="summary-paragraph">You do not have any {section.label}'s set up.</p>
                ) : (
                  values.top_business_debtors.map((businessDebtor, i) => (
                    <div className={`business-debtor`} key={i}>
                      <H4 pl={'12px'}>{`${section.label} ${i + 1}`}</H4>
                      <S_FormSectionFields>
                        {section.fields.map((field, ii) => (
                          <SummaryField
                            field={field}
                            index={i}
                            key={ii}
                            printVersion={printVersion}
                            showPii={showPii}
                            values={values}
                          />
                        ))}
                      </S_FormSectionFields>
                    </div>
                  ))
                )
              ) : (
                <S_FormSectionFields>
                  {section.fields.map((field, ii) =>
                    !Array.isArray(field) ? (
                      <SummaryField
                        field={field}
                        key={ii}
                        printVersion={printVersion}
                        showPii={showPii}
                        state={state}
                        values={values}
                      />
                    ) : (
                      field.map((nestedField, iii) => (
                        <SummaryField
                          field={nestedField}
                          key={iii}
                          printVersion={printVersion}
                          showPii={showPii}
                          state={state}
                          values={values}
                        />
                      ))
                    )
                  )}
                </S_FormSectionFields>
              )}
            </div>
          ))}
        </div>

        {showPrintDisclaimer ? (
          <PrintAppDisclaimer disclaimerText={tenantFormConfig?.disclaimer} prospectData={values} />
        ) : null}

        {tenantFormConfig.disclaimer && state.user.user_type !== 'lender' && (
          <p className={`disclaimer-text`}>{tenantFormConfig.disclaimer}</p>
        )}
        {tenantFormConfig.disclaimer_more && state.user.user_type !== 'lender' && (
          <div className={`full-disclaimer`}>
            <div
              onClick={() => {
                openModal(DisclaimerFull)
              }}
            >
              {tenantFormConfig.disclaimer_more.btn_txt}
            </div>
          </div>
        )}
        {tenantFormConfig.agree_btn && state.user.user_type !== 'lender' && (
          <Field
            errors={errors}
            field={{
              type: `radio`,
              name: `agree`,
              options: [
                { value: true, label: `I Agree` },
                { value: false, label: `I Disagree` },
              ],
              required: true,
            }}
            handleBlur={handleBlur}
            handleChange={handleChange}
            touched={touched}
            values={values}
          />
        )}
      </div>
    </S_FormSummary>
  )
}

const ShowHidePiiButton = ({ showPii, setShowPii }) => {
  return (
    <div className="label-btn-container">
      <Button
        className={'text-button'}
        onClick={(e) => {
          setShowPii(!showPii)
        }}
        text={showPii ? 'Hide Full' : 'Show Full'}
      />
    </div>
  )
}

function mapStateToProps(state) {
  const { env } = state
  return {
    tenant: env.tenant,
    state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (content) => {
      dispatch(modalActions.openModal(content))
    },
  }
}

const connectedFormSummary = connect(mapStateToProps, mapDispatchToProps)(FormSummary)
export { connectedFormSummary as FormSummary }
