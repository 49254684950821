import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tabs } from 'xen-ui';
import { Box } from 'xen-ui';
import { Page, PageTitle } from 'xen/components';
import { useXenHistory } from 'xen/hooks';
import { CONFIGURATIONS_PATHS } from '../routes';
export const tabTitles = {
    root: 'Branding',
    users: 'Users',
    factoring: 'Factoring',
    mainGlAccounts: 'Main GL Accounts',
    documentTemplates: 'Document Templates',
    notifications: 'Notifications',
    verificationTypes: 'Verification Types',
};
export const ConfigurationsWrapper = ({ children, activeTab }) => {
    const { push } = useXenHistory();
    return (_jsxs(Page, { children: [_jsx(PageTitle, { "data-testid": "page-title", title: "Settings" }), _jsx(Tabs, { value: activeTab, children: _jsx(Tabs.List, { children: Object.entries(CONFIGURATIONS_PATHS).map(([pathName, path]) => (_jsx(Tabs.Tab, { "data-testid": `tab-${pathName}`, onClick: () => push({ to: path }), value: pathName, children: tabTitles[pathName] }, pathName))) }) }), _jsx(Box, { px: "1rem", children: children })] }));
};
