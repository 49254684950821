import styled from 'styled-components'
import { S_FormRow } from '../../../_components/Forms/FormRow'
import colors from '../../../_constants/colors'
import { S_FormInput, S_FormSelect, S_TextAreaInput } from '../../../_components/Forms/FormInput/FormInput.styles'
export const S_EditBusinessForm = styled.div`
  ${S_FormRow} {
    display: flex;
    padding: 5px 12px;
    margin: 0;

    .radio-group {
      margin-right: auto;
      margin-left: 0;
      width: 100%;
    }

    .upload-section {
      margin-bottom: 0;
    }

    &.add-background {
      background: ${colors.INNER_GREY_BOX};
    }

    .form-row-label {
      color: ${colors.TEXT_LIGHT};
      padding-left: 0;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      .required-indicator {
        display: none;
      }
    }

    .field-and-error-wrapper {
      color: ${colors.TEXT_DARK};
      display: flex;
      align-items: center;
      margin-left: auto;

      ${S_FormInput},
      ${S_FormSelect},
            ${S_TextAreaInput} {
        font-size: 14px;
        width: 100%;
      }

      ${S_TextAreaInput} {
        resize: none;
        min-height: 90px;
      }

      ${S_FormSelect} {
        select {
          font-size: 14px;
        }
      }
    }

    .form-row-label,
    .field-and-error-wrapper {
      width: 50%;
    }

    &.hide {
      display: none;
    }
  }

  .user-fields {
    margin-bottom: 20px;
    margin-right: 20px;
  }

  .user-field {
    margin-bottom: 15px;
  }

  .back-link {
    color: #006ba8;
  }

  .errors {
    margin-top: 25px;
  }

  .header {
    display: flex;
    align-items: end;
    justify-content: space-between;
  }

  .form-actions {
    margin-top: 25px;
  }
`
