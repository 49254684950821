import React from 'react'
import { Box } from '../Box/Box'
import colors from '../../_constants/colors'

export const Divider = (props) => {
  return <Box {...props} />
}

Divider.defaultProps = {
  borderWidth: '0px',
  bwt: '1px',
  boxHeight: '1px',
  boxWidth: '100%',
  maxWidth: '100%',
  borderColor: colors.BORDER_GREY,
}
