import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from 'xen-ui';
import { useCreatePayment, useCreatePaymentAssociation } from 'xen/_modules/invoice-settlements/api';
export const CreatePaymentButton = ({ children, invoiceSettlementId, ...props }) => {
    const { mutateAsync: createPayment, isPending: isPaymentPending, isError: isPaymentError } = useCreatePayment();
    const { mutateAsync: createPaymentAssociation, isPending: isPaymentAssociationPending, isError: isPaymentAssociationError, } = useCreatePaymentAssociation();
    const isPaymentBeingCreated = isPaymentPending || isPaymentAssociationPending;
    const handleCreatePayment = async () => {
        const payment = await createPayment({ source: 'ach' });
        if (!payment?.id)
            return;
        createPaymentAssociation({
            batchNumber: invoiceSettlementId,
            checkNumber: String(payment.id),
        });
        if (isPaymentError)
            console.error('Error creating payment');
        if (isPaymentAssociationError)
            console.error('Error creating payment association');
    };
    return (_jsx(Button, { loading: isPaymentBeingCreated, onClick: handleCreatePayment, ...props, children: children }));
};
