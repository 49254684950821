import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import { FaRegFile } from 'react-icons/fa'
import config from 'config'

// Styles
import { S_SliderView, S_SliderViewFile, S_SliderViewItem } from './S_SliderView.styles'
import { buildSlides } from './SliderView.helpers'
import { S_Button } from '../ListView'
import { sessionSelectors } from '../../../../_selectors/session.selectors'
import { useWorker } from '../ListView/hooks'

export const SliderViewFile = ({ file, allowDownload }) => {
  const hasLink = file?.downloadLink && allowDownload
  let downLoadLink = ''

  if (hasLink) {
    downLoadLink = file.downloadLink?.url ? file.downloadLink.url : file.downloadLink
  }

  return (
    <S_SliderViewFile>
      <a className={`slider-file ${hasLink ? '' : 'disabled'}`} href={downLoadLink} target="blank">
        <FaRegFile />
        <span className="slider-file-text">{file.document_name}</span>
      </a>
    </S_SliderViewFile>
  )
}

export const SliderView = ({
  parentId,
  files,
  allowDelete,
  allowDownload,
  hideDownloadAll = false,
  requestsManaged = false,
  onDelete = () => {},
  filesPerSlide = 8,
  customDownloadAll = null,
}) => {
  const { sessionToken, accessToken } = sessionSelectors.useAuthentication()
  const { tenant } = sessionSelectors.useEnv()
  let [proccesingWorker, setProcessingWorker] = useState(false)
  let worker = useWorker(setProcessingWorker)

  function startZipDownload() {
    if (!customDownloadAll) {
      setProcessingWorker(true)
      worker.postMessage({
        event: 'start',
        data: { sessionToken, accessToken, tenant, parentId, baseUrl: config.apiUrl },
      })
    } else {
      setProcessingWorker(true)
      customDownloadAll().then(() => {
        setProcessingWorker(false)
      })
    }
  }

  const [slides, setSlides] = useState({})
  const [hasNav, setHasNav] = useState(false)

  const settings = {
    dots: true,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  useEffect(() => {
    const filesSlides = buildSlides(files, filesPerSlide)
    setSlides(filesSlides)
    setHasNav(filesSlides && Object.keys(filesSlides).length > 1)
  }, [])

  return (
    <S_SliderView className={hasNav ? 'nav-enabled' : 'nav-disabled'}>
      <Slider {...settings}>
        {Object.keys(slides).map((slide, index) => {
          return (
            <div key={`slider-wrapper-${index}`}>
              <S_SliderViewItem>
                {slides[index].map((file, index) => {
                  return (
                    <SliderViewFile
                      allowDelete={allowDelete}
                      allowDownload={allowDownload}
                      file={file}
                      key={`slider-item-file-${file.id}-${index}`}
                      onDelete={onDelete}
                      requestsManaged={requestsManaged}
                    />
                  )
                })}
              </S_SliderViewItem>
            </div>
          )
        })}
      </Slider>
      {allowDownload && !hideDownloadAll && files.length > 1 && (
        <S_Button
          className="compact download-all-files-btn"
          disabled={proccesingWorker}
          key="download-all-files-btn"
          onClick={startZipDownload}
        >
          {proccesingWorker ? 'Creating File' : 'Download All Files'}
        </S_Button>
      )}
    </S_SliderView>
  )
}
