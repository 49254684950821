import React, { useRef, useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import { printElement } from '../../_helpers'
import { Button } from '../Button'

/**
 * @typedef {Object} PrinterProps
 * @property {(printFn: () => void) => void} [getPrintFunction] - Function to pass the print function.
 * @property {React.FC<{ onClick: (ev: React.MouseEvent<HTMLButtonElement>) => void }>} [Trigger] - A component that triggers the print.
 * @property {React.FC<{ onLoaded: () => void }>} [Content] - The content to be printed, with an `onLoaded` prop.
 * @property {boolean} [asyncContent] - Whether the content is asynchronous.
 */

/**
 * PrinterComponent responsible for rendering the print trigger and content.
 *
 * @param {PrinterProps} props
 */
const PrinterComponent = ({
  getPrintFunction = () => {},
  Trigger = () => null,
  Content = () => null,
  asyncContent = false,
}) => {
  const mount = document.getElementById('print-portal-root')
  const el = document.createElement('div')
  const isLoading = useRef(asyncContent && true)
  const [showComponent, setShowComponent] = useState(false)

  if (!mount || !el) return null

  const printFunction = () => {
    if (!isLoading.current) {
      el.style.display = 'block'
      printElement(el, "SELLER'S APPLICATION")
      el.style.display = 'none'
    }
    setShowComponent(true)
  }

  const onLoaded = () => {
    if (asyncContent) getPrintFunction(printFunction)
    isLoading.current = false
    printFunction()
  }

  useEffect(() => {
    mount.appendChild(el)
    el.style.display = 'none'
    if (!asyncContent) getPrintFunction(printFunction)
    return () => mount.removeChild(el)
  }, [el, mount])

  return (
    <>
      {Trigger() ? (
        <Trigger
          onClick={(ev) => {
            ev.stopPropagation()
            printFunction()
          }}
        />
      ) : (
        <Button onClick={printFunction} text="print" />
      )}
      {showComponent ? createPortal(<Content onLoaded={onLoaded} />, el) : null}
    </>
  )
}

// Memoize and export the component
export const Printer = memo(PrinterComponent)

PrinterComponent.propTypes = {
  Content: PropTypes.func,
  Trigger: PropTypes.func,
  asyncContent: PropTypes.bool,
  getPrintFunction: PropTypes.func,
}
