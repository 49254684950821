import React, { useState } from 'react'
import get from 'lodash/get'

// Config
import badgeConfig from '../../../_configs/badges.config'

// Components
import ToolTip from '../../Tooltip/Tooltip'
import { FaArchive, FaFileAlt, FaPrint, FaBell } from 'react-icons/fa'
import {
  S_ListRow,
  S_Actions,
  S_Date,
  S_Credit,
  S_SalesPerson,
  S_BusinessName,
  S_Badges,
  S_Date_Separator,
} from './ListRow.styles'
import { useLocation } from 'react-router-dom'

//Helpers
import DateFormatter from '../../../_helpers/date-formatter'
import { capitalizeString } from '../../../_helpers/formatting'
import { formatValueByType } from '../../../_helpers'
import { isProductEnabled } from '../../../_helpers/flags'
import { SectionLoader } from '../../SectionLoader'
import { Printer } from '../../Printer'
import { ApplicationPrintWrapper } from '../../../_views/LenderViews/ProspectDetails/ProspectSlideout/ProspectSlideOut.style'
import { ProspectDetailsApplication as NewProspectDetailsApplication } from '../../../_views/LenderViews/NewProspectDetails/ProspectDetailsApplication'
import { useTenant } from '../../../_reduxHooks/tenant.hook'
import { WarningModal } from '../../modals/Warning/WarningModal'
import { unArchiveDeal, archiveDeal } from '../../../_services/deals.service'
import { useModal } from '../../../_reduxHooks/modal.hook'
import { useFlags } from '../../../_reduxHooks/flags.hook'
import { TenantBadge } from '../../../_views/LenderViews/NewProspectDetails/TenantBadge'

async function stopClickPropagation(event, fn, props) {
  event.stopPropagation()
  await fn(props)
}

const ProspectDetailsWrapper = ({ onLoaded, businessId }) => {
  return (
    <NewProspectDetailsApplication dealId={businessId} defaultShowPII={false} onLoaded={onLoaded} printVersion={true} />
  )
}
export const ListRow = (props) => {
  const { isSectionEnable, prospect, redirection, salesPerson } = props
  const {
    id,
    business_id: prospect_business_id,
    deal_id,
    name,
    business_name,
    email_address: prospect_email_address,
    created_at,
    updated_at,
    total_face_value,
    archived = false,
    business_contacts = [],
    state = null,
    sales_person: newSalesPerson,
    is_client,
  } = prospect

  const business_id = deal_id || prospect_business_id || id
  const email_address = prospect_email_address || business_contacts[0]?.email
  const { openModal } = useModal()

  const { tenant, tenantObj } = useTenant()
  const [isLoading, setIsLoading] = useState(false)
  const { featureFlags } = useFlags()
  const [isArchive, setIsArchive] = useState(state ? state === 'archived' : archived)

  const createdTimeFormatter = new DateFormatter(created_at)
  const createdFormattedDate = createdTimeFormatter.getDate()

  const timeFormatter = new DateFormatter(updated_at)
  const formattedDate = timeFormatter.getDate()

  const location = useLocation()
  const isProspectsPage = location.pathname.includes('prospects')
  const handleRedirection = () => {
    isSectionEnable && redirection({ value: business_id })
  }

  const onArchiveClick = async (e) => {
    if (isSectionEnable) {
      setIsLoading(true)
      e.stopPropagation()

      openModal(
        WarningModal,
        {
          title: `${isArchive ? 'Unarchive' : 'Archive'} this deal?`,
          acceptLabel: `${isArchive ? 'Unarchive' : 'Archive'}`,
          onDecline: () => {
            setIsLoading(false)
          },
          onAccept: async () => {
            const isOperateEnabled = isProductEnabled('Operate', featureFlags)

            if (isArchive) {
              await unArchiveDeal(business_id)
            } else {
              if (!isOperateEnabled || !is_client) {
                await archiveDeal(business_id)
              }
            }
            setIsLoading(false)
            setIsArchive((prev) => !prev)
          },
        },
        {
          onClose: () => {
            setIsLoading(false)
          },
        }
      )
    }
  }

  const handleDocsRedirection = async (e) => {
    isSectionEnable && stopClickPropagation(e, redirection, { value: business_id, type: 'uploaded_docs' })
  }

  if (isLoading) {
    return (
      <S_ListRow isLoading={isLoading}>
        <SectionLoader />
      </S_ListRow>
    )
  }
  const badgeConfiguration = badgeConfig[tenant]?.business

  const viewedSinceLastAction = prospect.viewed_since_last_action == true
  const { prospect_overview_value_field = '', prospect_overview_value_field_v2 = '' } = tenantObj
  // ZOMBIE FP-5877 Clean this up once everyone is migrated to Underwrite V2
  const valueField = prospect_overview_value_field_v2 || prospect_overview_value_field
  const prospectValue = valueField
    ? get(
        prospect,
        prospect_overview_value_field_v2.split('.'), // Underwrite V2
        get(prospect, ['additional_data', prospect_overview_value_field], 0) // Legacy Underwrite
      )
    : 0
  const creditValue = prospectValue ? prospectValue : total_face_value || 0

  const label = business_name || name || email_address
  const salesPersonName = salesPerson
    ? salesPerson
    : newSalesPerson && `${newSalesPerson.first_name} ${newSalesPerson.last_name}`

  return (
    <S_ListRow isArchive={isArchive} isSectionEnable={isSectionEnable} onClick={handleRedirection}>
      <div className="title-badges">
        <S_BusinessName>{label}</S_BusinessName>

        <S_Badges>
          <TenantBadge className="small-badge" prospect={prospect} />
        </S_Badges>
      </div>

      <S_Actions>
        <S_Credit>{formatValueByType(creditValue, 'currency')}</S_Credit>
        <S_Date_Separator>{createdFormattedDate}</S_Date_Separator>
        <S_Date>{formattedDate}</S_Date>
        <a className="icons" onClick={handleDocsRedirection}>
          <ToolTip content={`View or add uploaded documents`}>
            <span>
              <FaFileAlt />
            </span>
          </ToolTip>
        </a>

        <Printer
          Content={({ onLoaded }) => (
            <ApplicationPrintWrapper>
              <ProspectDetailsWrapper businessId={business_id} onLoaded={onLoaded} />
            </ApplicationPrintWrapper>
          )}
          Trigger={(props) => (
            <ToolTip content="Print application">
              <a {...props} className="icons">
                <FaPrint />
              </a>
            </ToolTip>
          )}
          asyncContent
        />
        <a className="icons" onClick={onArchiveClick}>
          <ToolTip content={isArchive ? `Unarchive` : `Archive`}>
            <span>
              <FaArchive />
            </span>
          </ToolTip>
        </a>
        {isProspectsPage && (
          <ToolTip content={viewedSinceLastAction ? 'No new updates' : 'New updates'}>
            <span>
              <FaBell color={viewedSinceLastAction == false ? 'green' : ''} />
            </span>
          </ToolTip>
        )}
      </S_Actions>
      <S_SalesPerson>{salesPersonName || 'Unassigned'}</S_SalesPerson>
    </S_ListRow>
  )
}

const listRowDefaultProps = {
  archive: () => {},
  docsRedirection: () => {},
  print: () => {},
  redirection: () => {},
  refreshData: () => {},
  sorted: [],
  usersRedirection: () => {},
}

ListRow.defaultProps = listRowDefaultProps
