import React from 'react'
import { S_SimpleTable, S_SimpleTableRow, S_SimpleTableCell } from './SimpleTable.styles'
import { Text } from '../../_components/Typography/Components/Text/Text'
import colors from '../../_constants/colors'

const SimpleTableCell = ({ cellContent }) => {
  return (
    <S_SimpleTableCell>
      <Text fs={'16px'} textColor={colors.DEFAULT_TEXT}>
        {cellContent}
      </Text>
    </S_SimpleTableCell>
  )
}

const SimpleTableRow = ({ row, highlight, hasHighLight }) => {
  return (
    <S_SimpleTableRow hasHighLight={hasHighLight} highlight={highlight}>
      {Object.keys(row).map((rowKey, key) => (
        <SimpleTableCell cellContent={row[rowKey]} key={key} />
      ))}
    </S_SimpleTableRow>
  )
}

SimpleTableRow.defaultProps = {
  row: {},
  highlight: false,
  hasHighLight: false,
}

export const SimpleTable = ({ data, highLightIndex }) => {
  if (!data.length) {
    return null
  }

  return (
    <S_SimpleTable>
      {data.map((row, index) => (
        <SimpleTableRow
          hasHighLight={highLightIndex > -1}
          highlight={index === highLightIndex}
          index={index}
          key={index}
          row={row}
        />
      ))}
    </S_SimpleTable>
  )
}

SimpleTable.defaultProps = {
  data: [],
  barActiveIndex: -1,
}
