import { getArFields, getEquipmentFields, getInventoryFields } from './BBS.fields'

export const getFields = (type) => {
  switch (type) {
    case 'equipment':
      return getEquipmentFields()
    case 'accounts_receivable':
      return getArFields()
    case 'inventory':
      return getInventoryFields()
    default:
      break
  }
}

export const getIneligiblesFields = (ineligibles) => {
  const fields = []
  for (const ineligible of ineligibles) {
    fields.push({
      type: 'currency',
      id: ineligible.id.toString(),
      label: ineligible.ineligible.name,
      defaultValue: ineligible.value,
      icon: '-',
    })
  }

  return fields
}

export const getBalance = (key, total, balance) => {
  switch (key) {
    case 'beginning':
      return total + Number(balance)
    case 'sales':
    case 'new_inventory':
    case 'new_equipment':
      return total + Number(balance)
    case 'credits':
    case 'removed_inventory':
    case 'removed_equipment':
      return total - Number(balance)
    case 'positive_adjustments':
      return total + Number(balance)
    case 'negative_adjustments':
      return total - Number(balance)
    case 'new_collections':
      return total - Number(balance)
    case 'overpayment':
      return total + Number(balance)
    default:
      return total
  }
}

export const calculateCollateralBalance = (collateral) => {
  let balance = 0
  for (const key in collateral) {
    if (key === 'collateral_line' || key === 'ineligibles' || key === 'id' || key === 'documents') {
      continue
    }
    balance = getBalance(key, balance, collateral[key])
  }
  return balance
}

export const calculateTotalIneligibles = (ineligibles) => {
  return Object.values(ineligibles).reduce((prev, curr) => prev + curr, 0)
}

export const arrToHash = (arr) => {
  return arr?.reduce((prev, curr) => ({ ...prev, ...curr }), {}) || {}
}

export const hashIneligibles = (prev, curr) => ({ ...prev, [curr.id]: curr.value })
export const hashPreviousIneligibles = (prev, curr) => ({ ...prev, [curr.ineligible.id]: curr.value })

export const getCollateralTotals = (collateral) => {
  const collateralBalance = calculateCollateralBalance({ ...collateral })
  const totalIneligible = calculateTotalIneligibles({
    ...collateral.ineligibles.reduce(hashIneligibles, {}),
  })
  const totalEligible = collateralBalance - totalIneligible
  const totalAvailable = totalEligible * (typeof collateral.advance_rate === 'number' ? collateral.advance_rate : 1)

  return {
    collateral: collateralBalance,
    ineligible: totalIneligible,
    eligible: totalEligible,
    available: totalAvailable,
  }
}

export const addSummaryToBBC = (bbc) => {
  bbc.total_collateral = 0
  bbc.total_ineligible = 0
  bbc.total_eligible = 0
  bbc.total_available = 0

  bbc.collaterals = bbc.collaterals.map((collateral) => {
    const collateralTotals = getCollateralTotals(collateral)
    bbc.total_collateral += +collateralTotals['collateral']
    bbc.total_ineligible += +collateralTotals['ineligible']
    bbc.total_eligible += +collateralTotals['eligible']
    bbc.total_available += +collateralTotals['available']

    return {
      ...collateral,
      ...collateralTotals,
    }
  })

  return bbc
}
