import React from 'react'
import { Router } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'
import axios from 'axios'
import { ClipLoader } from 'react-spinners'
import { AppLayout } from 'xen/components'
// Actions
import {
  authenticationActions,
  envActions,
  esigningActions,
  modalActions,
  dragActions,
  tenantActions,
  cancelTokenActions,
} from '../_actions'
import { getFeatureFlags } from '../_actions/featureFlags.actions'
import { setTimezone } from '../_actions/getTimezone.actions'

// Components
import { Header } from '../_components/header/Header'
import { Footer } from '../_components/Footer/Footer'
import { AppOverlay } from './AppOverlay'

// Constants
import TENANTS from '../_constants/tenants'
import urls from '../_constants/urls'

// Helpers
import { addTenantToMonitoringContext, addUserToMonitoringContext, getCurrentEnv, history } from '../_helpers'
import { isProductEnabled, isFeatureEnabled } from '../_helpers/flags'

import { tenantService } from '../_services/tenant.service'
import { subscribe } from '../_services/subscription.service'
import { getTenantCustomization } from '../_services/customization.service'

const getTenant = (token = '') => tenantService.getTenant(token)

// Global Styles
import './App.css'
import { NewRelic } from '../HeadScripts/NewRelic/NewRelic'
import { PageWrapper } from './PageWrapper'
import { IdleTimerWrapper } from './IdleTimerWrapper'
import { AppRoutes } from './AppRoutes'
import { Box } from '../components/Box/Box'
import { ConfigurationContext, FeaturesContext } from 'xen/providers'

class App extends React.Component {
  static contextType = FeaturesContext

  constructor(props) {
    super(props)

    this.state = {
      cancelToken: axios.CancelToken.source(),
      isTenantMonitored: false,
      isUserMonitored: false,
    }

    const { loggedIn, tenant, user, closeModal, featureFlags } = this.props
    closeModal()
    if (!tenant) {
      this.getTenantToken()
    }

    ReactGA.initialize(`UA-160191326-1`, {
      debug: false,
    })
    if (loggedIn && !user.reset_password_required) {
      if (isProductEnabled('Operate', featureFlags)) {
        this.getEsigningRequests()
      }
      ReactGA.set({
        userId: user.id,
        tenant: tenant,
      })
    }
  }

  getTenantToken = () => {
    const { setEnv } = this.props
    let { env, tenantToken } = getCurrentEnv()

    if (TENANTS.includes(tenantToken)) {
      setEnv(tenantToken, env)
    } else {
      tenantToken = 'finvoice'
      setEnv(tenantToken, env)
      history.push('/404')
    }
  }

  getEsigningRequests = () => {
    const { user, getEsigningRequests } = this.props

    if (!user.reset_password_required) {
      getEsigningRequests(user.id)
    }
  }

  componentDidMount() {
    const { loggedIn, setTimezoneData, setTenant, setCancelToken, user, getEsigningRequests, getFlags } = this.props
    const flags = this.context

    getFlags(flags)

    // Cancel axios pending requests when change route
    setCancelToken(axios.CancelToken.source())
    history.listen(() => {
      this.state.cancelToken.cancel()
      var newCancelToken = axios.CancelToken.source() // Prevent canceling new requests
      this.setState({
        cancelToken: newCancelToken,
      })
      setCancelToken(newCancelToken)
    })

    // Get tenant theme
    let { tenantToken } = getCurrentEnv()
    getTenantCustomization(tenantToken).then((customization) => {
      setTenant({ customization })
    })

    // Get tenant data
    getTenant(tenantToken).then((data) => {
      const tenant = data
      setTimezoneData(tenant.tenant_timezone)
      setTenant(tenant)
      addTenantToMonitoringContext(tenant)
      this.setState({
        isTenantMonitored: true,
      })
    })

    // Only make user-related requests if user is logged in
    if (loggedIn) {
      addUserToMonitoringContext(user)
      this.setState({
        isUserMonitored: true,
      })
      getEsigningRequests(user.id)
    }
  }

  render() {
    const { loggedIn, env, user, tenant, featureFlags, sessionToken } = this.props
    const navDrawer = isFeatureEnabled('drawer_navigation', featureFlags)
    const { isTenantMonitored, isUserMonitored } = this.state

    // Always validate tenant is being monitored...
    let isMonitoringReady = isTenantMonitored
    if (loggedIn) {
      // ...but only validate user is being monitored when user is logged in
      isMonitoringReady = isTenantMonitored && isUserMonitored
      // Subscription will only update if necessary
      subscribe(sessionToken)
    }

    // Do not render app until feature flags and monitoring context are ready
    if (!featureFlags || !isMonitoringReady) {
      return (
        <Box alignItems="center" boxHeight="100vh" d="flex" justifyContent="center">
          <ClipLoader color="gray" size={64} />
        </Box>
      )
    }

    return (
      <Router history={history}>
        <PageWrapper>
          <IdleTimerWrapper />
          <ConfigurationContext.Consumer>
            {(configuration) => (
              <Helmet>
                <link
                  href={
                    configuration?.tenant?.branding?.images?.favicon || `${urls.IMAGES}/${env}/${tenant}/favicon.ico`
                  }
                  rel="icon"
                  type="image/x-icon"
                />
              </Helmet>
            )}
          </ConfigurationContext.Consumer>
          <NewRelic />

          <AppOverlay />
          {navDrawer && loggedIn ? (
            <AppLayout>
              <AppRoutes />
            </AppLayout>
          ) : (
            <>
              <Header />
              <AppRoutes />
              <Footer />
            </>
          )}
        </PageWrapper>
      </Router>
    )
  }
}

function mapStateToProps(state) {
  const { authentication, env, user, featureFlags } = state
  return {
    loggedIn: authentication.loggedIn,
    sessionToken: authentication.sessionToken,
    env: env.env,
    tenant: env.tenant,
    user,
    featureFlags,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setEnv: (tenantToken, env) => {
      dispatch(envActions.setEnv(tenantToken, env))
    },
    getEsigningRequests: (userId) => {
      dispatch(esigningActions.getEsigningRequests(userId, true))
    },
    getFlags: (flags) => {
      dispatch(getFeatureFlags(flags))
    },
    setTimezoneData: (timezone) => {
      dispatch(setTimezone(timezone))
    },
    setTenant: (tenant) => {
      dispatch(tenantActions.setTenant(tenant))
    },
    setCancelToken: (source) => {
      dispatch(cancelTokenActions.setCancelToken(source))
    },
    setDragOverTarget: (target) => {
      dispatch(dragActions.setDragOverTarget(target))
    },
    closeModal: () => {
      dispatch(modalActions.closeModal())
    },
    signOut: (sessionToken, _noRedirect, isIdleTimeout, idleTimeoutMessage) => {
      dispatch(authenticationActions.signOut(sessionToken, _noRedirect, isIdleTimeout, idleTimeoutMessage))
    },
  }
}

const connectedApp = connect(mapStateToProps, mapDispatchToProps)(App)
export { connectedApp as App }
