import React from 'react'
const validations = {
  percent: {
    valid: (value) => {
      if (+value > 1.0) return 'Should be less than 100 %'
      return true
    },
  },
  currency: {
    length: (value) => {
      if (value >= 10 ** 10) return 'Should be less than $10,000,000,000.00'
      return true
    },
  },
  tel: {
    length: (value) => {
      if (typeof value !== 'string') return true
      let valueSize = value.replace(/\D/g, '').length
      if (valueSize < 10 && valueSize > 0) return 'Should have at least 10 digits'
      if (valueSize > 13) return 'Should have less than 13 digits'
      return true
    },
  },
}

export const initialDebtorConfig = {
  entity: {
    debtor_name: '',
    debtor_address_street: '',
    debtor_address_unit: '',
    debtor_city: '',
    debtor_state: '',
    debtor_zip: '',
    debtor_phone_number: '',
    debtor_fax_number: '',
    overall_concentration_limit: '',
    overall_credit_limit: '',
  },
  fields: [
    {
      type: 'text',
      id: 'debtor_name',
      layout: '',
      label: 'Name',
      required: 'Required',
    },
    {
      type: 'text',
      id: 'debtor_address_street',
      layout: '',
      label: 'Address Street',
      required: false,
    },
    {
      type: 'text',
      id: 'debtor_address_unit',
      layout: '',
      label: 'Address Unit',
      required: false,
    },
    {
      type: 'text',
      id: 'debtor_city',
      layout: '',
      label: 'City',
      required: false,
    },
    {
      type: 'text',
      id: 'debtor_state',
      layout: '',
      label: 'State',
      required: false,
    },
    {
      type: 'text',
      id: 'debtor_zip',
      layout: '',
      label: 'Zip Code',
      required: false,
    },
    {
      type: 'tel',
      id: 'debtor_phone_number',
      layout: '',
      label: 'Phone',
      required: false,
      validate: validations.tel,
    },
    {
      type: 'tel',
      id: 'debtor_fax_number',
      layout: '',
      label: 'Fax',
      required: false,
      validate: validations.tel,
    },
    {
      label: 'Credit',
      isSectionTitle: true,
    },
    {
      type: 'currency',
      id: 'overall_credit_limit',
      layout: '',
      label: 'Portfolio Credit Limit',
      required: false,
      validate: validations.currency,
    },
    {
      type: 'percent',
      id: 'overall_concentration_limit',
      layout: '',
      label: 'Portfolio Concentration Limit',
      required: false,
      validate: validations.percent,
    },
  ],
}

export const initialAllowDebtorConfig = {
  entity: {
    debtor_address_street: '',
    debtor_address_unit: '',
    debtor_city: '',
    debtor_state: '',
    debtor_zip: '',
    debtor_phone_number: '',
    debtor_fax_number: '',
    overall_concentration_limit: '',
    overall_credit_limit: '',
  },
  fields: [
    {
      type: 'text',
      id: 'debtor_address_street',
      layout: '',
      label: 'Address Street',
      required: false,
    },
    {
      type: 'text',
      id: 'debtor_address_unit',
      layout: '',
      label: 'Address Unit',
      required: false,
    },
    {
      type: 'text',
      id: 'debtor_city',
      layout: '',
      label: 'City',
      required: false,
    },
    {
      type: 'text',
      id: 'debtor_state',
      layout: '',
      label: 'State',
      required: false,
    },
    {
      type: 'text',
      id: 'debtor_zip',
      layout: '',
      label: 'Zip Code',
      required: false,
    },
    {
      type: 'tel',
      id: 'debtor_phone_number',
      layout: '',
      label: 'Phone',
      required: false,
      validate: validations.tel,
    },
    {
      type: 'tel',
      id: 'debtor_fax_number',
      layout: '',
      label: 'Fax',
      required: false,
      validate: validations.tel,
    },
    {
      label: 'Credit',
      isSectionTitle: true,
      after: <div className={`help-text-bottom`}>You can set Client and Debtor specific limits here.</div>,
    },
    {
      type: 'currency',
      id: 'credit_limit',
      layout: '',
      label: 'Credit Limit',
      required: false,
      validate: validations.currency,
    },
    {
      type: 'percent',
      id: 'concentration_limit',
      layout: '',
      label: 'Concentration Limit',
      required: false,
      validate: validations.percent,
    },
  ],
}
