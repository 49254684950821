import styled from 'styled-components'

export const S_CompletedPayment = styled.div`
  .summary-title {
    border-bottom: 2px solid ${(props) => props.theme.colors.secondary};
  }
  .download-container {
    margin-top: 10px;
    display: flex;
    flex-direction: row-reverse;
    .download {
      cursor: pointer;
      display: flex;
      color: ${(props) => props.theme.colors.secondary};
      .download-icon {
        margin-right: 5px;
      }
    }
  }
`
