import React from 'react'
import styled from 'styled-components'

// Components
import { SectionLoader } from '../SectionLoader'
import { PDFViewer } from '../../_views/LenderViews/PDFViewer'

export const S_FileViewer = styled.div``

export const S_FileViewerInner = styled.div`
  position: relative;

  .unable-to-preview {
    padding: 40px;
    text-align: center;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
`

// Start with single file, expand to others
export const FileViewer = ({ pdf, img, downloadLink, isLoadingFile, draggable = false, document_name = null }) => {
  return (
    <S_FileViewer>
      {isLoadingFile ? (
        <SectionLoader />
      ) : (
        <FileViewerInner
          document_name={document_name}
          downloadLink={downloadLink}
          draggable={draggable}
          img={img}
          pdf={pdf}
        />
      )}
    </S_FileViewer>
  )
}

const FileViewerInner = ({ pdf, img, downloadLink, draggable = false, document_name }) => {
  return (
    <S_FileViewerInner>
      {/* PDF */}
      {pdf && <PDFViewer document_name={document_name} draggable={draggable} pdf={pdf} scale={1.0} />}

      {/* Image */}
      {img && (
        <img
          draggable={draggable}
          onDragStart={(e) => {
            e.dataTransfer.setData('base64', img)
            e.dataTransfer.setData('imageName', document_name)
          }}
          src={img}
        />
      )}

      {/* Unable to preview - show link */}
      {!pdf && !img && downloadLink && (
        <div className="unable-to-preview">
          <div>Unable to preview file</div>
        </div>
      )}

      {/* No document found */}
      {!pdf && !img && !downloadLink && <div>Unable to retrieve document</div>}
    </S_FileViewerInner>
  )
}
