import styled from 'styled-components'
import { S_SearchableOverview } from '../../../../_components/SearchableOverview'

import { colors } from '../../../../_constants/colors'

export const S_DebtorsOverview = styled.div`
  header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .search-field-wrapper {
    max-width: 300px;
  }
  .chart-text-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    max-width: 495px;
    margin-right: auto;

    @media screen and (max-width: 768px) {
      min-width: auto;
    }

    hr {
      border-top: 1px solid ${colors.TEXT_LIGHT};
      border-bottom: none;
      max-width: 60px;
      margin: 15px 0;
    }
    .recharts-pie-sector {
      cursor: pointer;
    }
    .text-container {
      max-width: 120px;
      text-align: left;
      .text-label {
        color: ${colors.TEXT_LIGHT};
      }
      .text-value {
        color: ${colors.TEXT_DARK};
        margin-top: 5px;
      }
    }
  }
  .one-third {
    width: 250px;
  }

  .recharts-responsive-container {
    margin-top: -40px;
    max-width: 240px;
    margin-left: auto;
  }
  .debtor-name,
  .debtor-balance {
    color: ${colors.TEXT_DARK};
    font-size: 16px;
  }
  .debtor-name {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
    min-width: 100px;
  }
  .debtor-balance {
    padding-left: 10px;
    text-align: right;
    white-space: nowrap;
  }

  ${S_SearchableOverview} {
    margin-bottom: 35px;
  }
`
