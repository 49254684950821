import React, { useState } from 'react'

// Components
import { FileViewer } from '../../../FileViewer'
import { ControlsButton } from '../../../ControlsButton'
import { Button } from '../../../Button'
import { S_DropGallery } from './DropGallery.styles'

// Utils
import { useFile } from '../../../../_helpers/useFile'

// Services
import { deleteDocument } from '../../../../_services/documents.service'

//Breaking out children as it's own component to avoid hooks issues that come up within loops
const SingleFile = ({ file, allowDelete, onDelete = () => {}, draggable = false }) => {
  const { document_name, id } = file

  const [pdf, img, downloadLink, isLoadingFile] = useFile(id)
  const [isConfirmDelete, setIsConfirmDelete] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  return (
    <div
      className={`overall-container ${isExpanded ? 'overall-container-expanded' : ''}`}
      onClick={(e) => {
        // So it doesn't trigger ReactDropzone
        e.stopPropagation()
      }}
    >
      <header className="file-header">
        <div className="left">
          <h6>{document_name}</h6>
          {/* File Actions */}
          <div className="file-actions">
            <ControlsButton
              className="download-button"
              handleClick={() => setIsExpanded(!isExpanded)}
              icon={isExpanded ? 'zoom_out' : 'zoom_in'}
            />
            <ControlsButton
              className="download-button"
              disabled={isLoadingFile}
              handleClick={() => setIsConfirmDelete(false)}
              href={downloadLink ? downloadLink.url : null}
              icon="download"
              rel="noopener noreferrer"
              target="_blank"
            />
            {allowDelete && (
              <ControlsButton
                className="delete-button"
                disabled={isLoadingFile}
                handleClick={() => {
                  setIsConfirmDelete(!isConfirmDelete)
                }}
                icon="delete"
              />
            )}
            {/* Show Confirm Button if delete */}
            {isConfirmDelete && (
              <>
                <Button className="compact secondary" onClick={() => setIsConfirmDelete(false)} text="Cancel" />
                <Button
                  className="compact destroy"
                  onClick={() => {
                    deleteDocument(id).then(() => {
                      onDelete(id)
                      setIsConfirmDelete(!isConfirmDelete)
                    })
                  }}
                  text="Confirm"
                />
              </>
            )}
          </div>
        </div>
      </header>
      <div className="file-container">
        <FileViewer
          document_name={document_name}
          downloadLink={downloadLink}
          draggable={draggable}
          img={img}
          isLoadingFile={isLoadingFile}
          pdf={pdf}
        />
      </div>
    </div>
  )
}

export const DropGallery = ({ files, allowDelete, onDelete = () => {}, draggable = false }) => {
  if (!files || !files.length) {
    return null
  }
  return (
    <S_DropGallery>
      {files.map((file) => {
        return <SingleFile allowDelete={allowDelete} draggable={draggable} file={file} onDelete={onDelete} />
      })}
    </S_DropGallery>
  )
}
