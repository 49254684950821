import { BROKERS_PATHS, BrokersRoutes } from 'xen/_modules/brokers';
import { CLIENTS_PATHS, ClientsRoutes } from 'xen/_modules/clients';
import { CONFIGURATIONS_PATHS, ConfigurationsRoutes } from 'xen/_modules/configurations';
import { DEBTORS_PATHS, DebtorsRoutes } from 'xen/_modules/debtors';
import { FUNDING_REQUESTS_PATHS, FundingRequestsRoutes } from 'xen/_modules/funding-requests';
import { INVOICE_SCHEDULES_PATHS, InvoiceSchedulesRoutes } from 'xen/_modules/invoice-schedules';
import { INVOICE_SETTLEMENTS_PATHS, InvoiceSettlementsRoutes } from 'xen/_modules/invoice-settlements';
import { INVOICES_PATHS, InvoicesRoutes } from 'xen/_modules/invoices';
import { LINKED_EXTERNAL_ACCOUNTS_PATHS, LinkedExternalAccountsRoutes } from 'xen/_modules/linked-external-accounts';
import { NOTIFICATIONS_PATHS, NotificationsRoutes } from 'xen/_modules/notifications';
import { OVERVIEW_PATHS, OverviewRoutes } from 'xen/_modules/overview';
import { PROSPECTS_PATHS, ProspectsRoutes } from 'xen/_modules/prospects';
import { SIGNING_DOCUMENTS_PATHS, SigningDocumentsRoutes } from 'xen/_modules/signing-documents';
import { UNAPPLIED_CASH_PATHS, UnappliedCashRoutes } from 'xen/_modules/unapplied-cash';
import { USERS_PATHS, UsersRoutes } from 'xen/_modules/users';
export { BROKERS_PATHS, CLIENTS_PATHS, CONFIGURATIONS_PATHS, DEBTORS_PATHS, FUNDING_REQUESTS_PATHS, INVOICE_SCHEDULES_PATHS, INVOICE_SETTLEMENTS_PATHS, INVOICES_PATHS, LINKED_EXTERNAL_ACCOUNTS_PATHS, NOTIFICATIONS_PATHS, OVERVIEW_PATHS, PROSPECTS_PATHS, SIGNING_DOCUMENTS_PATHS, UNAPPLIED_CASH_PATHS, USERS_PATHS, };
export function AllXenRoutes() {
    return [
        BrokersRoutes(),
        ClientsRoutes(),
        ConfigurationsRoutes(),
        DebtorsRoutes(),
        FundingRequestsRoutes(),
        InvoicesRoutes(),
        InvoiceSchedulesRoutes(),
        InvoiceSettlementsRoutes(),
        LinkedExternalAccountsRoutes(),
        NotificationsRoutes(),
        OverviewRoutes(),
        ProspectsRoutes(),
        SigningDocumentsRoutes(),
        UnappliedCashRoutes(),
        UsersRoutes(),
    ];
}
export function XenRoutes() {
    return AllXenRoutes();
}
