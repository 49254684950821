import { UTCDateToSystemString, dateToUTCString } from '../dates'

export const receiptDatesToSystemTime = (receipt) => {
  receipt.created_at = UTCDateToSystemString(receipt.created_at)
  receipt.updated_at = UTCDateToSystemString(receipt.updated_at)
  receipt.posting_date = UTCDateToSystemString(receipt.posting_date)
  return receipt
}

export const receiptDatesToUTC = (receipt) => {
  if (receipt.posting_date) {
    receipt.posting_date = dateToUTCString(receipt.posting_date)
  }
  return receipt
}
