import styled from 'styled-components'
import { S_EditBusinessFormV2 } from '../../../../_views/LenderViews/EditBusiness/EditBusinessV2.style'
import { S_ProspectDetailsApplication } from '../../../../_views/LenderViews/ProspectDetails/ProspectDetailsApplication'
import colors from '../../../../_constants/colors'
import { S_PageHeaderRow } from '../../../../_components/layout/PageHeader/PageHeader.styles'
import { addMediaQuery } from '../../../../_constants/stylesMediaBreakpoints'

export const S_ProspectSlideOut = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 10;

  .slideout-content {
    width: 950px;
    padding: 30px 40px;
    box-sizing: border-box;
    height: 100vh;
    max-height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    ${addMediaQuery.md`
            width: 100%;
        `}
    ${addMediaQuery.sm`
            padding: 10px;
        `}
  }

  .container {
    display: flex;
    justify-content: space-between;
    ${addMediaQuery.sm`
            display: block;
            max-height: calc(100vh - 90px);
            height: 100%;
            overflow-y: auto;
        `}
  }

  .navigation {
    max-width: 280px;
    max-height: calc(100vh - 90px);
    height: 100%;
    overflow-y: auto;
    ${addMediaQuery.sm`
            max-width: 100%;
            max-height: none;
            height: auto;
            overflow-y: initial;
            margin-bottom: 15px;
        `}

    .nav-item {
      white-space: nowrap;
      width: 100%;
      display: block;
      border: 1.5px solid ${colors.INNER_GREY_BOX};
      margin-bottom: 10px;
      border-radius: 2px;
      box-sizing: border-box;
      padding: 14px 20px 14px 24px;
      position: relative;
      color: ${colors.TEXT_DARK};
      font-size: 21px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      ${addMediaQuery.sm`
                font-size: 16px;
                padding: 8px 10px;
            `}

      &:before {
        position: absolute;
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-left: 12px solid ${(props) => props.theme.colors.secondary};
        top: 0px;
        right: 5px;
        transform: rotate(-45deg);
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
        ${addMediaQuery.sm`
                    border-top-width: 4px;
                    border-bottom-width: 4px;
                    border-left-width: 4px;
                    right: 2px;
                `}
      }

      &:hover,
      &._active {
        background-color: ${colors.INNER_GREY_BOX};
      }

      &._active {
        &:before {
          opacity: 1;
        }
      }
    }

    .small-nav-item {
      color: ${colors.TEXT_LIGHT};
      display: inline-block;
      text-transform: uppercase;
      font-size: 12px;
      cursor: pointer;
      transition: color 0.2s ease-in-out;
      margin-top: 12px;
      &:hover {
        color: ${colors.TEXT_DARK};
      }
    }
  }

  .component-container {
    max-width: calc(100% - 295px);
    width: 100%;
    max-height: calc(100vh - 90px);
    height: 100%;
    overflow-y: auto;
    padding: 15px;
    border: 1.5px solid #e7eaee;
    border-radius: 3.6px;
    ${addMediaQuery.sm`
            max-width: 100%;
            max-height: none;
            height: auto;
            overflow-y: initial;
        `}
  }

  ${S_ProspectDetailsApplication} {
    .sub-actions {
      display: none;
    }
    .edit-btn {
      display: none;
    }
    .summary {
      display: none;
    }
  }

  ${S_EditBusinessFormV2} {
    ${S_PageHeaderRow} {
      padding-left: 12px;
    }
    .form-header {
      padding-left: 12px;
      margin-bottom: 0;
      color: ${colors.TEXT_DARK};
    }

    .form-header {
      button {
        margin-top: 0;
        margin-bottom: 10px;
      }
    }

    .field-and-error-wrapper {
      flex-direction: column;
      .form-row-error {
        align-self: flex-start;
      }
    }
  }
`

export const ApplicationPrintWrapper = styled.div`
  padding: 20px 50px;
  ${S_ProspectDetailsApplication} {
    .page-header {
      display: none;
    }
    .actions {
      display: none;
    }
    .summary {
      display: none;
    }
  }
`
