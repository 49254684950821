import React, { useEffect, useState } from 'react'
import { StandardPage } from '../../../../_components/layout/StandardPage'
import { H1 } from '../../../../_components/Typography/Components/Headings/H1'
import { InvoicesOverviewSummary } from './InvoicesOverviewSummary'
import { createSearchParams } from '../../../../_helpers/search'
import { useFlags } from '../../../../_reduxHooks/flags.hook'
import { generateFilters, viewMap } from './index'
import { SearchableOverview } from '../../../../_components/SearchableOverview'
import styled from 'styled-components'

const S_Spacer = styled.div`
  height: 3rem;
`

const InvoicesOverviewContent = ({ match, businessId, isClient }) => {
  const view = isClient ? 'business_view' : match.path.split('invoices/')[1]

  const [loading, setLoading] = useState(true)
  const [setFilters, setSetFilters] = useState()
  const [searchFilters, setSearchFilters] = useState([])
  const [searchTerm, setSearchTerm] = useState()
  const [tableQuery, setTableQuery] = useState('')

  const { featureFlags } = useFlags()

  useEffect(() => {
    const searchQuery = createSearchParams({
      searchTerm,
      pageSize: null,
      pageNum: null,
      sort: null,
      requestMethod: 'GET',
      searchParam: 'invoice_number',
      searchFilters,
    })

    setTableQuery(searchQuery.toString())
    setLoading(false)
  }, [JSON.stringify(searchFilters), searchTerm])

  let views = viewMap(featureFlags, view, businessId)
  views = [views]

  if (loading) {
    return null
  }

  return (
    <>
      <InvoicesOverviewSummary businessId={businessId} setFilters={setFilters} tableQuery={tableQuery} view={view} />
      <S_Spacer />
      <SearchableOverview
        filterColumns={generateFilters(view)}
        getSetSearchFilter={(fn) => setSetFilters(() => fn)}
        key={view}
        searchFilterCallback={(searchFilters) => setSearchFilters(searchFilters)}
        searchParam={`invoice_number`}
        searchParamCallback={(newSearchTerm) => setSearchTerm(newSearchTerm)}
        searchPlaceholder="Search By Invoice No."
        showSearchBar={true}
        showSearchFilter={true}
        views={views}
      />
    </>
  )
}

export const InvoicesOverview = ({ match, businessId, isClient }) => {
  if (isClient) {
    return <InvoicesOverviewContent businessId={businessId} isClient={isClient} match={match} />
  }

  return (
    <StandardPage title={'Invoices'}>
      <H1>Invoices</H1>
      <InvoicesOverviewContent businessId={businessId} isClient={isClient} match={match} />
    </StandardPage>
  )
}

InvoicesOverview.defaultProps = {
  businessId: null,
  isClient: false,
}
