import request from './axios_service'
import config from 'config'
import { downloadFile } from './documents.service'
import { formHeader } from '../_helpers'

const getTenantId = () => {
  // TODO: get tenant id from store
  return 1
}

const setPaginationParams = (searchParams) => {
  searchParams.set('items', searchParams.get('count'))

  const page = searchParams.get('start_index') / searchParams.get('count') + 1
  searchParams.set('page', page)

  return searchParams
}

export const getProspectsSubmissions = (searchParams, tenantId) => {
  const state = 'submitted'

  return getProspectsWithState(state, searchParams, tenantId)
}

export const getProspectsPending = (searchParams, tenantId) => {
  const state = 'pending'

  return getProspectsWithState(state, searchParams, tenantId)
}

export const getProspectsArchived = (searchParams, tenantId) => {
  const state = 'archived'

  return getProspectsWithState(state, searchParams, tenantId)
}

export const getABLProspects = (searchParams, tenantId) => {
  return getProspectsWithState(null, searchParams, tenantId)
}

export const getProspectsWithState = (state, searchParams, tenantId = getTenantId()) => {
  setPaginationParams(searchParams)

  let url
  if (!state) {
    url = `/underwrite/api/v1/tenant/${tenantId}/deals?${searchParams.toString()}`
  } else {
    url = `/underwrite/api/v1/tenant/${tenantId}/deals?state=${state}&${searchParams.toString()}`
  }

  return request(
    {
      url: url,
      baseURL: config.underwriteApiUrl,
      data: null,
      method: 'get',
    },
    null,
    true
  ).then((response) => {
    return { results: response.data.results, total_results: response.headers['total-count'] }
  })
}

export const getProspectsLeads = (searchParams, tenantId) => {
  const state = 'lead'

  return getProspectsWithState(state, searchParams, tenantId)
}

export const archiveDeal = (deal_id) => {
  return request({
    baseURL: config.underwriteApiUrl,
    url: `/underwrite/api/v1/deals/${deal_id}/archive`,
    data: null,
    method: 'PUT',
  })
}

export const unArchiveDeal = (deal_id) => {
  return request({
    baseURL: config.underwriteApiUrl,
    url: `/underwrite/api/v1/deals/${deal_id}/unarchive`,
    data: null,
    method: 'PUT',
  })
}

export const closeDeal = (deal_id) => {
  return request({
    baseURL: config.underwriteApiUrl,
    url: `/underwrite/api/v1/deals/${deal_id}/close`,
    data: null,
    method: 'PUT',
  })
}

export const updateDealState = (deal_id, state) => {
  return request({
    baseURL: config.underwriteApiUrl,
    url: `/underwrite/api/v1/deals/${deal_id}/update_state`,
    data: { state: state },
    method: 'PUT',
  })
}

export const assignSalesPerson = (deal_id, sales_person_id) => {
  return request({
    baseURL: config.underwriteApiUrl,
    url: `/underwrite/api/v1/deals/${deal_id}/assign_sales_person`,
    data: {
      sales_person_id: sales_person_id,
    },
    method: 'PUT',
  })
}

export const showDeal = (deal_id) => {
  return request({
    baseURL: config.underwriteApiUrl,
    url: `/underwrite/api/v1/deals/${deal_id}`,
    data: null,
    method: 'GET',
  })
}

export const downloadDocument = (documentId, documentName, due_diligence_document = false) => {
  return request({
    baseURL: config.underwriteApiUrl,
    url: `/underwrite/api/v1/documents/${documentId}?due_diligence_document=${due_diligence_document}`,
    data: null,
    method: 'GET',
  }).then((resp) => {
    downloadFile(resp.url, documentName)
  })
}

export const showDealDocuments = (deal_id, searchParams) => {
  return request(
    {
      baseURL: config.underwriteApiUrl,
      url: `/underwrite/api/v1/deals/${deal_id}/documents`,
      data: null,
      params: searchParams,
      method: 'GET',
    },
    null,
    true
  )
}

export const uploadDealDocuments = (deal_id, payload) => {
  const data = new FormData()
  data.append('documentable_id', payload.documentable_id)
  data.append('documentable_type', payload.documentable_type)
  data.append('field_name', payload.field_name)
  data.append('file_data', payload.file_data)
  data.append('filename', payload.filename)

  return request(
    {
      baseURL: config.underwriteApiUrl,
      url: `/underwrite/api/v1/deals/${deal_id}/documents/upload`,
      headers: [formHeader()],
      data: data,
      method: 'PATCH',
    },
    null,
    true
  )
}

export const deleteDocument = (documentId, dueDiligenceDocument = false) => {
  return request({
    baseURL: config.underwriteApiUrl,
    url: `/underwrite/api/v1/documents/${documentId}?due_diligence_document=${dueDiligenceDocument}`,
    method: `DELETE`,
  })
}

export const showDealFormValues = (dealId, redactValues = true) => {
  return request({
    baseURL: config.underwriteApiUrl,
    url: `/underwrite/api/v1/form_values/${dealId}?redact_values=${redactValues}`,
    data: null,
    method: 'GET',
  })
}

export const getNewDeal = (tenant_id, formId) => {
  let url = `/underwrite/api/v1/tenants/${tenant_id}/deals/new_deal`

  if (formId) {
    url += `?form_id=${formId}`
  }
  return request({
    baseURL: config.underwriteApiUrl,
    url: url,
    data: null,
    method: 'GET',
  })
}

export const getDealMetaForm = (dealId, userType, includeRequiredFieldAttributes = false) => {
  // NOTE:
  // ?userType affects what the `required` attr returns (the value of borrower_required or lender_required)
  // ?includeRequiredFieldAttributes adds both borrower_required and lender_required to the response
  return request({
    baseURL: config.underwriteApiUrl,
    url: `/underwrite/api/v1/deals/${dealId}/forms?user_type=${userType}&include_required_field_attributes=${includeRequiredFieldAttributes}`,
    data: null,
    method: 'GET',
  })
}

export const getTenantMetaForms = (tenant_id, user_type) => {
  return request({
    baseURL: config.underwriteApiUrl,
    url: `/underwrite/api/v1/tenants/${tenant_id}/forms?user_type=${user_type}`,
    data: null,
    method: 'GET',
  })
}

export const createNewProspect = (deal, prospect_email) => {
  return request({
    baseURL: config.underwriteApiUrl,
    url: `/underwrite/api/v1/deals/lead?prospect_email=${prospect_email}`,
    data: deal,
    method: 'POST',
  })
}

export const downloadDealDocuments = (dealId) => {
  return request({
    baseURL: config.underwriteApiUrl,
    url: `/underwrite/api/v1/deals/${dealId}/documents/download_all`,
    data: null,
    method: 'GET',
  })
}

export const getDueDiligence = (dealId) => {
  return request({
    baseURL: config.underwriteApiUrl,
    url: `/underwrite/api/v1/deals/${dealId}/due_diligence`,
    data: null,
    method: 'GET',
  })
}

export const updateDueDiligence = (dealId, dueDiligenceId, payload) => {
  const data = new FormData()
  if (payload.filedata) {
    data.append('file_data', payload.filedata)
    data.append('file_name', payload.filename)
  }
  if (payload.review_status) {
    data.append('review_status', payload.review_status)
  }

  return request({
    baseURL: config.underwriteApiUrl,
    url: `/underwrite/api/v1/deals/${dealId}/due_diligence/${dueDiligenceId}`,
    headers: [formHeader()],
    data: data,
    method: 'PUT',
  })
}

export const updateDeal = (data) => {
  return request({
    baseURL: config.underwriteApiUrl,
    url: `/underwrite/api/v1/deals/${data.deal.id}`,
    data: data,
    method: 'PUT',
  })
}

export const markDealAsViewed = (dealId) => {
  return request({
    baseURL: config.underwriteApiUrl,
    url: `/underwrite/api/v1/deals/${dealId}/mark_as_viewed`,
    data: null,
    method: 'PUT',
  })
}

export const showDealEvents = (dealId, tenantId, params) => {
  const searchParams = setPaginationParams(params)
  return request(
    {
      baseURL: config.underwriteApiUrl,
      url: `underwrite/api/v1/tenants/${tenantId}/deals/${dealId}/events?${searchParams}`,
      method: 'GET',
    },
    null,
    true
  ).then((response) => {
    return { results: response.data.results, total_results: response.headers['total-count'] }
  })
}
