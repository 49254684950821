import { jsx as _jsx } from "react/jsx-runtime";
import { Button, modals } from 'xen-ui';
import { useXenHistory } from 'xen/hooks';
import { useDeleteInvoiceSettlement } from 'xen/_modules/invoice-settlements/api';
export const DeleteInvoiceSettlementButton = ({ children = 'Delete Invoice Settlement', invoiceSettlement, ...props }) => {
    const { push } = useXenHistory();
    const { mutateAsync: deleteInvoiceSettlement, isPending: isInvoiceSettlementDeletePending } = useDeleteInvoiceSettlement();
    const handleDeleteInvoiceSettlement = async () => {
        await deleteInvoiceSettlement({ id: invoiceSettlement.id });
        push({ to: '/invoice-settlements' });
    };
    const confirmDeleteInvoiceSettlement = () => {
        modals.openConfirmModal({
            title: 'Confirm',
            children: 'Are you sure you want to delete this invoice settlement and all of its payments? This action cannot be undone.',
            confirmProps: { color: 'red' },
            labels: { cancel: 'Cancel', confirm: 'Delete Invoice Settlement' },
            onConfirm: handleDeleteInvoiceSettlement,
        });
    };
    return (_jsx(Button, { disabled: !invoiceSettlement.deletable, loading: isInvoiceSettlementDeletePending, onClick: confirmDeleteInvoiceSettlement, ...props, children: children }));
};
