import React, { useRef, useLayoutEffect } from 'react'
import styled from 'styled-components'

// Components
import Table from '../../../_components/Table'
import { createColumn } from '../../../_components/TableColumn'

//Constants
import { LENDER_ROUTES } from '../../../_constants/routes'
import { formatCurrency } from '../../../_helpers'
import isObject from 'lodash/isObject'

const S_Table = styled.div`
  overflow: scroll;
  cursor: default;
  &.fade-right {
    -webkit-mask-image: linear-gradient(to left, transparent, black 100px);
    mask-image: linear-gradient(to left, transparent, black 100px);
  }

  &.fade-left {
    -webkit-mask-image: linear-gradient(to right, transparent, black 100px);
    mask-image: linear-gradient(to right, transparent, black 100px);
  }

  &.fade-right.fade-left {
    -webkit-mask-image: linear-gradient(
      to right,
      transparent 0%,
      black 100px,
      black calc(100% - 100px),
      transparent 100%
    );
    mask-image: linear-gradient(to right, transparent 0%, black 100px, black calc(100% - 100px), transparent 100%);
  }

  .verification.negative {
    color: #d50000;
  }
`

export const defaultColumns = (advance_rate = 1, advancedAmountFeature = false) => {
  let advancedAmount = false
  if (advancedAmountFeature) {
    advancedAmount = {
      accessor: `advance_amount`,
      headingText: 'Advanced Amount',
      disableSortBy: true,
      maxWidth: 120,
      Cell: (props) => {
        const rate = advance_rate === null ? 1 : advance_rate
        const invoiceAmount = props.row.original.face_value
        const advancedAmount = props.row.original.advance_amount
          ? props.row.original.advance_amount
          : +invoiceAmount * +rate
        return formatCurrency(advancedAmount)
      },
    }
  }

  const columns = [
    {
      accessor: 'business_name',
      headingText: 'Client',
      minWidth: 80,
    },
    {
      accessor: 'debtor_name',
      headingText: 'Debtor',
      minWidth: 85,
    },
    createColumn({
      type: 'link',
      id: 'invoice_number',
      headingText: 'Invoice #',
      overflow: true,
      minWidth: 100,
      getRoute: ({ id }) => {
        return `${LENDER_ROUTES.invoice}/${id}`
      },
    }),
    {
      accessor: 'po_number',
      headingText: 'PO',
      title: 'Purchase Order',
      disableSortBy: true,
      minWidth: 50,
      Header: () => (
        <div className="rt-custom-header align-left" title="Purchase Order">
          PO
        </div>
      ),
    },
    createColumn({
      type: 'date',
      id: 'debtor_due_date',
      headingText: 'Due Date',
      minWidth: 80,
    }),
    createColumn({
      type: 'date',
      id: 'invoice_date',
      headingText: 'Invoice Date',
      minWidth: 80,
    }),
    createColumn({
      type: 'count',
      id: 'age',
      headingText: 'Age',
      accessor: 'age',
    }),
    {
      id: 'batch_number',
      accessor: 'batch_number',
      headingText: 'Batch #',
      minWidth: 50,
    },
    createColumn({
      type: 'date',
      id: 'purchase_date',
      headingText: 'Purchased',
      minWidth: 110,
    }),
    createColumn({
      type: 'currency',
      id: 'face_value',
      headingText: 'Amount',
      minWidth: 80,
    }),
    createColumn({
      id: 'current_balance',
      type: 'currency',
      accessor: 'current_balance',
      headingText: 'Balance',
      minWidth: 80,
    }),
    advancedAmount,
    createColumn({
      type: 'sentiment',
      accessor: 'sentiment',
      headingText: 'Verification',
      textAlignment: 'left',
      minWidth: 100,
    }),
    createColumn({
      type: 'currency',
      accessor: 'accrued_fees',
      headingText: 'Fees Accrued',
      minWidth: 80,
    }),
    createColumn({
      type: 'currency',
      accessor: 'earned_fees',
      headingText: 'Fees Earned',
      minWidth: 80,
    }),
  ]

  return columns.filter((col) => isObject(col))
}

export const numberOfDaysBetweenDates = (startDate, endDate) => {
  const start = new Date(startDate)
  const end = new Date(endDate)
  let dayCount = 0

  if (end > start) {
    while (end > start) {
      dayCount++
      start.setDate(start.getDate() + 1)
    }

    return dayCount
  } else if (end < start) {
    while (start > end) {
      dayCount++
      end.setDate(end.getDate() + 1)
    }
    return dayCount * -1
  } else {
    return dayCount
  }
}

export const InvoicesTable = ({
  invoices,
  columns = defaultColumns,
  sorted = [],
  onSortedChange = () => {},
  pageSize,
  selectedInvoices = [],
  handleRowClick = undefined,
  onFilesAccepted = async () => {},
  resizable = false,
}) => {
  const tableStyleRef = useRef(null)
  useLayoutEffect(() => {
    const container = tableStyleRef.current
    if (!container) {
      return
    }

    function handleOverflowFade() {
      if (container.scrollLeft > 0) {
        container.classList.add('fade-left')
      } else {
        container.classList.remove('fade-left')
      }

      if (container.scrollLeft + container.offsetWidth < container.scrollWidth) {
        container.classList.add('fade-right')
      } else {
        container.classList.remove('fade-right')
      }
    }

    container.addEventListener('scroll', handleOverflowFade)
    handleOverflowFade()

    // Cleanup listener on unmount
    return () => container.removeEventListener('scroll', handleOverflowFade)
  }, [])

  invoices = invoices?.map((invoice) => {
    invoice.closed_text = invoice.closed ? 'Yes' : 'No'

    return invoice
  })
  const isInvoiceInList = (newInvoice, invoiceList) => {
    let isInList = false
    invoiceList.forEach((invoice) => {
      if (invoice.id === newInvoice.id) {
        isInList = true
      }
    })
    return isInList
  }
  const trProps = (state, rowInfo) => {
    if (rowInfo && rowInfo.original && handleRowClick !== undefined) {
      const invoice = rowInfo.original
      const isSelected = isInvoiceInList(invoice, selectedInvoices)
      return {
        onClick: (e) => {
          if (typeof handleRowClick === 'function') {
            handleRowClick(rowInfo.original)
          } else if (e.target.getAttribute('handlesevents') != 'true') {
            // leaving it in case there is a need to use openModal
            //openModal(InvoiceDetails, { invoice: rowInfo.row._original, setViews: setViews })
          }
        },
        className: `invoice-table-row hovercolor ${isSelected ? 'active' : ''}`,
      }
    } else {
      return {}
    }
  }

  if (!invoices || invoices.length === 0) {
    return null
  }
  return (
    <S_Table data-testid="invoices-table" ref={tableStyleRef}>
      <Table
        className="with-border selectable"
        columns={columns}
        data={invoices}
        defaultPageSize={25}
        getTrProps={trProps}
        onFilesAccepted={onFilesAccepted}
        onSortedChange={onSortedChange}
        pageSize={pageSize}
        resizable={resizable}
        sorted={sorted}
      />
    </S_Table>
  )
}
