import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Button } from '../../Button'

// Actions
import { modalActions } from '../../../_actions'

import { formConfig } from '../../../_configs/formConfig/form.config'

// Styles
import { addMediaQuery } from '../../../_constants/stylesMediaBreakpoints'

const StyledFullDisclaimer = styled.div`
  max-width: 1200px;

  ${addMediaQuery.xl`
        max-width: 950px;
    `}

  ${addMediaQuery.lg`
        max-width: 850px;
    `}

    ${addMediaQuery.xs`
        max-width: 500px;
    `}
    
    .apply-btn {
    margin-top: 16px;
    display: flex;
    justify-content: space-evenly;
  }
  .title {
    color: #383f44;
    font-weight: 400;
    font-size: 25px;
    letter-spacing: 0.56px;
  }
  .body {
    margin-top: 8px;
    color: #9eaeb6;
    font-size: 14px;
    letter-spacing: 0.76px;
    p {
      padding: 5px 0;
    }
  }
`

const DisclaimerFull = ({ tenant, closeModal }) => {
  const DisclaimerContent = () => {
    return formConfig[tenant].disclaimer_more.taggedBody
      ? formConfig[tenant].disclaimer_more.taggedBody()
      : formConfig[tenant].disclaimer_more.body
  }
  return [
    <StyledFullDisclaimer>
      <div className={`title`}>{formConfig[tenant].disclaimer_more.title}</div>
      <div className={`body`}>
        <DisclaimerContent />
      </div>
      <div className={'apply-btn'}>
        <Button onClick={closeModal} text={`Accept`} type={`button`} />
      </div>
    </StyledFullDisclaimer>,
  ]
}

function mapStateToProps(state) {
  const { business, env } = state
  return {
    business,
    tenant: env.tenant,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => {
      dispatch(modalActions.closeModal())
    },
  }
}

const connectedDisclaimer = connect(mapStateToProps, mapDispatchToProps)(DisclaimerFull)
export { connectedDisclaimer as DisclaimerFull }
