import styled, { css } from 'styled-components'
import { math } from 'polished'
import { addMediaStyles } from '../../../../_constants/stylesMediaBreakpoints'

const addFlexStyles = (size, columns, gutters, minWidth, flexGrow, margin) => {
  return css`
    ${size ? `width: ${getColWidth(size, columns)};` : ''}
    ${gutters ? `padding: ${`${'0px'} ${gutters ? gutters + 'px' : '0px'}`}` : ''};
    ${minWidth ? `min-width: ${minWidth};` : ''};
    ${flexGrow ? `flex-grow: ${flexGrow};` : ''};
    ${margin ? `margin: ${margin};` : ''};
  `
}

const getColWidth = (size, columns) => {
  if (size === 'auto') {
    return size
  }
  return `${math(`${size} / ${columns} * 100`)}%;`
}

const addGridMediaStyles = (breakpoint, size, columns, gutters) => {
  const styles = addFlexStyles(size, columns, gutters)
  return addMediaStyles(breakpoint, styles)
}

export const S_GridCol = styled.div`
  width: 100%;
  ${(props) => {
    const { size, columns, gutters, minWidth, flexGrow, margin } = props
    return addFlexStyles(size, columns, gutters, minWidth, flexGrow, margin)
  }}

  ${(props) => addGridMediaStyles('xs', props.xs, props.columns, props.guttersXs)};

  ${(props) => addGridMediaStyles('sm', props.sm, props.columns, props.guttersSm)};

  ${(props) => addGridMediaStyles('md', props.md, props.columns, props.guttersMd)};

  ${(props) => addGridMediaStyles('lg', props.lg, props.columns, props.guttersLg)};

  ${(props) => addGridMediaStyles('xl', props.xl, props.columns, props.guttersXl)};
`
