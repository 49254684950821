import React from 'react'

// Components
import Table from '../../../_components/Table'
import { createColumn } from '../../../_components/TableColumn'

// Helpers
import { history, dateUTCAmPm, invoiceSchedulePath } from '../../../_helpers'

export const ISTableColumns = [
  {
    accessor: 'business_name',
    headingText: 'Client',
  },
  createColumn({
    type: 'date',
    id: 'created_at',
    headingText: 'Created Date',
    toolTipContent: (props) => {
      return dateUTCAmPm(new Date(props.created_at))
    },
  }),
  {
    id: 'batch_number',
    headingText: 'batch number',
    accessor: 'batch_number',
  },
  // TODO: Add funded total column to invoice schedules table when there is backend support for it
  // createColumn({
  //     type: 'currency',
  //     id: 'total_advance',
  //     headingText: 'Funded Total',
  // }),
  createColumn({
    type: 'currency',
    id: 'total_value',
    headingText: 'Invoice Total',
  }),
  {
    id: 'invoices_count',
    headingText: 'Invoice Count',
    accessor: 'invoices_count',
  },
  {
    id: 'state',
    headingText: 'Status',
    accessor: 'state',
  },
  createColumn({
    type: 'date',
    id: 'updated_at',
    headingText: 'Updated',
    toolTipContent: (props) => {
      return dateUTCAmPm(new Date(props.updated_at))
    },
  }),
]

export const ISTable = ({
  invoiceSchedules,
  handleSortChange,
  pageSize,
  scheduleBaseRoute = '/schedules',
  columns = ISTableColumns,
  mobileScroll = false,
  defaultSort = [],
}) => {
  if (!invoiceSchedules || invoiceSchedules.length === 0) {
    return null
  }
  return (
    <Table
      className="with-border"
      columns={columns}
      data={invoiceSchedules}
      defaultSort={defaultSort}
      handleRowClick={(row) => {
        // Conditionally redirect only lender invoice schedule routes, not client routes
        if (scheduleBaseRoute === '/schedules') {
          history.push(invoiceSchedulePath(row.id))
          return
        }

        history.push(`${scheduleBaseRoute}/${row.id}`)
      }}
      mobileScroll={mobileScroll}
      onSortedChange={handleSortChange}
      pageSize={pageSize}
    />
  )
}
