import styled from 'styled-components'
import Slider from 'react-slick'

export const S_TopClientsSlider = styled.div`
  height: 100%;
  min-height: 200px;
  margin-bottom: auto;
  width: 100%;
  table {
    border-collapse: collapse;
    width: 100%;
  }
  tr {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #eee;
    color: #666;
  }
  tr:last-child {
    border-bottom-style: none;
  }
  td {
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: start;
  }
`

export const StyledSlider = styled(Slider)`
  .slick-dots {
    li {
      margin: 0;
    }
  }

  .slick-dots li button:before {
    font-size: 20px !important;
  }

  .slick-slide {
    padding-left: 2px;
    padding-right: 2px;
    transition: opacity 0.2s ease-in-out;
    opacity: 0;

    &.slick-active {
      opacity: 1;
    }
  }
`
